import React, { Component } from 'react';
import { Input, Tag } from 'antd';

import { getUrlSearch } from "utils";

const Value = ({ id }) => {
    return (
        <span>{id}</span>
    );
};

const getFilters = (filterFields, params) => {
    let filters = [];
    //console.log(params);
    //{name: "prices.in_hour", label: "Апартаменты час", values: Array(2)}
    filterFields && filterFields.list.forEach(fieldName => {
        const field = filterFields.byName[fieldName];
        const ValueComponent = field.valueComponent || Value;
        let parentName = fieldName;
        let childName = '';
        if (fieldName.indexOf('.')) {
            parentName = fieldName.split('.')[0];
            childName = fieldName.split('.')[1];
        }
        let values = params[parentName];
        if (values) {
            if (childName) {
                values = values[childName];
            }
            if (values && !Array.isArray(values)) {
                values = [values];
            }
            //console.log(parentName, childName, values);
            if (values && values.length > 0) {
                filters.push({
                    name: fieldName,
                    label: field.label,
                    values: values.map(v => ({ Component: ValueComponent, value: v })),
                });
            }
        }
    });
    return filters;
};


export default class TableTop extends Component {
    state = {
        query: null,
        params: {},
        filters: [],
        searchQuery: '',
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { data, filterFields } = nextProps;
        if (data.filter.query === prevState.query) {
            return null;
        }

        return {
            query: data.filter.query,
            params: data.filter.params,
            filters: getFilters(filterFields, data.filter.params),
            searchQuery: data.filter.params.search || '',
        }
    }

    searchChange = (e) => {
        this.setState({
            searchQuery: e.target.value,
        });
        this.search(e.target.value);
    };

    search = (value) => {
        const { onLoadData, data } = this.props;
        let params = Object.assign({}, data.params);
        if ('offset' in params) {
            delete params['offset'];
        }
        params.search = value;
        const query = '?' + getUrlSearch(params);
        onLoadData({ query });
    };

    removeFilterParam = (e, fieldName, value) => {
        e.preventDefault();
        const { onLoadData, data } = this.props;
        let params = Object.assign({}, data.filter.params);
        if ('offset' in params) {
            delete params['offset'];
        }

        let parentName = fieldName;
        let childName = '';
        if (fieldName.indexOf('.') > -1) {
            parentName = fieldName.split('.')[0];
            childName = fieldName.split('.')[1];
            let values = params[parentName][childName];
            if (!Array.isArray(values)) {
                values = [values];
            }
            params[parentName][childName] = values.filter(v => v !== value);

        } else {

            if (Array.isArray(params[fieldName])) {
                params[fieldName] = params[fieldName].filter(v => v !== value);
            } else {
                delete params[fieldName];
            }

        }

        const query = '?' + getUrlSearch(params);
        onLoadData({ query, activeFilter: data.activeFilter });
    };

    render() {
        const { topActions } = this.props;
        const { searchQuery, filters } = this.state;

        return (
            <div className="table-top">
                <div className="table-top-main">
                    <div className="table-top-main-start">
                        <Input.Search
                            placeholder="Поиск"
                            value={searchQuery}
                            allowClear={true}
                            onSearch={this.search}
                            onChange={this.searchChange}
                        />
                    </div>

                    {topActions &&
                    <div className="table-top-main-end">
                        {topActions.map((item, index) => (
                            <div className="action" key={`action${index}`}>{item}</div>
                        ))}
                    </div>}
                </div>

                {filters.length > 0 &&
                <div className="table-filters">
                    {filters.map(f => (
                        <div key={`filter-${f.name}`} className="table-filters-item">
                            <div className="table-filters-item-label">{f.label}:</div>
                            <div className="table-filters-item-values">
                                {f.values.map(v => (
                                    <Tag
                                        key={`filter-${f.name}-${v.value}`}
                                        closable
                                        onClose={(e) => this.removeFilterParam(e, f.name, v.value)}
                                    >
                                        <v.Component id={v.value}/>
                                    </Tag>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>}
            </div>
        );
    }
}
