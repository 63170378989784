import { takeEvery, select } from 'redux-saga/effects';

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/profile';


export function* watch() {
  yield takeEvery(actions.PROFILE_LOAD.START, actionProfileLoad);
  yield takeEvery(actions.PROFILE_DETAIL_LOAD.START, actionProfileDetailLoad);
  yield takeEvery(actions.PROFILE_UPDATE.START, actionProfileUpdate);
  yield takeEvery(actions.PROFILE_DELETE.START, actionProfileDelete);
}

function* actionProfileLoad(action) {
  yield base.ListLoad(action, api.profileLoad,
    {
      request: actions.profileLoadRequest,
      complete: actions.profileLoadComplete,
      error: actions.profileLoadError
    },
  );
}

function* actionProfileDetailLoad(action) {
  yield base.DetailLoad(action, api.profileDetailLoad,
    {
      request: actions.profileDetailLoadRequest,
      complete: actions.profileDetailLoadComplete,
      error: actions.profileDetailLoadError
    },
  );
}

function* actionProfileUpdate(action) {
  const filter = yield select((state) => state.profile.filter);
  yield base.Update(action, api.profileUpdate,
    {
      request: actions.profileUpdateRequest,
      complete: actions.profileUpdateComplete,
      error: actions.profileUpdateError,
      showEditForm: actions.profileShowEditForm,
      detailLoad: actions.profileDetailLoad,
      listLoad: actions.profileLoad,
    },
    '/profile',
    filter,
  );
}

function* actionProfileDelete(action) {
  const filter = yield select((state) => state.profile.filter);
  yield base.Delete(action, api.profileDelete,
    {
      request: actions.profileDeleteRequest,
      complete: actions.profileDeleteComplete,
      error: actions.profileDeleteError,
      listLoad: actions.profileLoad,
    },
    '/profile',
    filter,
  );
}
