import React, { Component } from 'react';
import { Input, Select } from "antd";

import { FormItem } from 'utils';


export default class MainFieldSet extends Component {
    render() {
        const { form, user } = this.props;
        const { updateStatus, editObject } = user;
        const instance = editObject;

        const error = updateStatus.error || {};
        const errorData = error.data || {};

        return (
            <div className="form-block">
                <FormItem
                    form={form}
                    instance={instance}
                    name="email"
                    label="E-mail"
                    required={true}
                    errorData={errorData}
                >
                    <Input size="large"/>
                </FormItem>

                <FormItem
                    form={form}
                    instance={instance}
                    name="first_name"
                    label="Имя"
                    required={false}
                    errorData={errorData}
                >
                    <Input size="large"/>
                </FormItem>

                <FormItem
                    form={form}
                    instance={instance}
                    name="last_name"
                    label="Фамилия"
                    required={false}
                    errorData={errorData}
                >
                    <Input size="large"/>
                </FormItem>

                <FormItem
                    form={form}
                    instance={instance}
                    name="role"
                    label="Роль"
                    required={true}
                    errorData={errorData}
                >
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Выберите"
                    >
                        <Select.Option value={'member'}>Участник</Select.Option>
                        <Select.Option value={'girl'}>Индивидуалка</Select.Option>
                        <Select.Option value={'agency'}>Агентство</Select.Option>
                        <Select.Option value={'manager'}>Менеджер</Select.Option>
                    </Select>
                </FormItem>
            </div>
        );
    }
}
