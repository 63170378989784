import React, { PureComponent } from 'react';

import { getUrl } from 'utils';
import styles from './photo.module.css';


export default class Photo extends PureComponent {
  render() {
    const { url, width, height, style } = this.props;

    const _style = Object.assign({
      width: `${width}px`,
      height: `${height}px`,
      backgroundImage: url ? `url(${getUrl(url)})` : 'none',
    }, style || {});

    return (
      <div
        className={styles.photo}
        style={_style}
      />
    );
  }
}
