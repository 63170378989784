import React, { Component } from 'react';
import { Button, Modal, Form, Spin } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";

import MainFieldSet from './MainFieldSet';


class MessageForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { actions, message } = this.props;
        const instance = message.editObject;

        let data = Object.assign({}, values);
        if (instance && instance.id && !message.showReplyForm) {
          data.id = instance.id;
        }
        if (message.showReplyForm && instance) {
          data.parent_msg = instance.id;
        }
        console.log(data);
        actions.messageUpdate({ data });
      }
    });
  };

  close = () => {
    const { actions } = this.props;
    actions.messageShowEditForm({ show: false });
    actions.messageShowReplyForm({ show: false });
  };

  render() {
    const { form, message, currentUserId } = this.props;
    const { showEditForm, showReplyForm, updateStatus } = message;

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    const isLoading = updateStatus.isLoading;

    return (
      <Modal
        width={600}
        visible={showEditForm || showReplyForm}
        title="Сообщение"
        onCancel={this.close}
        maskClosable={false}
        destroyOnClose={true}
        footer={[
          <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
            Сохранить
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form onSubmit={this.handleSubmit}>
            {errorData.non_field_errors &&
            <div className="form-error">{errorData.non_field_errors}</div>}

            <MainFieldSet message={message} form={form} currentUserId={currentUserId}/>
          </Form>
        </Spin>
      </Modal>
    );
  }
}


const WrappedMessageForm = Form.create()(MessageForm);

function mapStateToProps(state) {
  return {
    message: state.message,
    currentUserId: state.user.fields.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      actions.message,
    ), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedMessageForm);

