import React, { Component } from 'react';
import { Input } from 'antd';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { getUrlSearch, ordering } from "utils";
import { TableList, DateTimeText } from 'components/common';


class TransactionList extends Component {
    state = {
        query: null,
        filters: [],
        searchQuery: '',
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { transaction } = nextProps;
        const { query, params } = transaction;
        if (query === prevState.query) {
            return null;
        }

        return {
            query: nextProps.transaction.query,
            searchQuery: params.search || '',
        }
    }

    load = ({ query }) => {
        const { balance_id, actions } = this.props;
        let filter = {};
        if (balance_id) {
            filter.user_balance = balance_id;
        }
        actions.transactionLoad({ query, filter });
    };

    search = (value) => {
        const { transaction, history } = this.props;
        let params = Object.assign({}, transaction.params);
        params.search = value;
        const query = '?' + getUrlSearch(params);
        this.load({ query });
        history.push(history.location.pathname + query);
    };

    searchChange = (e) => {
        this.setState({
            searchQuery: e.target.value,
        });
    };

    render() {
        const { transaction, history } = this.props;
        const { searchQuery } = this.state;
        const { params } = transaction;

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Дата',
                dataIndex: 'date',
                key: 'date',
                render: (text, item) => <DateTimeText date={text}/>,

                sorter: true,
                sortOrder: ordering('date', params.ordering),
            },
            {
                title: 'Пользователь',
                dataIndex: 'user_balance',
                key: 'user_balance',
                render: (user_balance, item) => (
                    <Link to={`/balance/${user_balance.id}`}>
                        {user_balance.user.email}
                    </Link>
                ),
            },
            {
                title: 'Баланс',
                dataIndex: 'balance',
                key: 'balance',
                render: (text, item) => <span>{item.user_balance.balance} €</span>,
            },
            {
                title: 'Сумма',
                dataIndex: 'amount',
                key: 'amount',
                render: (text, item) => <span>{text} €</span>,
            },
            {
                title: 'Комментарий',
                dataIndex: 'comment',
                key: 'comment',
                render: (text, item) => {
                    const { content_object } = item;
                    let obj_link = '';
                    if (content_object.id) {
                        obj_link = (
                            <Link to={`/${content_object.type}/${content_object.id}`}>
                                {content_object.name}
                            </Link>
                        );
                    }
                    let parts = text.split('[content_object]');
                    for (let i = 1; i < parts.length; i += 2) {
                        parts[i] = <span key={i}>{obj_link}</span>;
                    }
                    return (
                        <span>{parts}</span>
                    )
                },
            }
        ];

        return (
            <div>
                <div className="page-block-header">
                    <div className="page-block-header-start">
                        <div className="page-block-header-search">
                            <Input.Search
                                placeholder="Поиск"
                                value={searchQuery}
                                //allowClear={true}
                                onSearch={this.search}
                                onChange={this.searchChange}
                            />
                        </div>
                    </div>
                </div>

                <TableList
                    history={history}
                    data={transaction}
                    columns={columns}
                    onLoadData={this.load}
                    plural={['транзакция', 'транзакции', 'транзакций']}
                />
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        transaction: state.transaction,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.transaction), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);


