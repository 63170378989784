import React, { Component } from 'react';
import { Icon } from 'antd';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { ordering } from "utils";
import { ActionButton, TableList } from "components/common";
import { ProfileValue } from 'components/blocks/profile';
import { AgencyValue } from 'components/blocks/agency';
import { ServiceValue } from 'components/blocks/service';


class SitePageList extends Component {
    load = (filter) => {
        const { actions, page } = this.props;
        let newFilter = Object.assign({}, filter);
        if (newFilter.activeFilter === undefined) {
            newFilter.activeFilter = page.filter.activeFilter
        }
        actions.pageLoad(newFilter);
    };

    renderObjectValue = (type, id) => {
        switch (type) {
            case 'profile':
                return <ProfileValue id={id} mode="link"/>;
            case 'agency':
                return <AgencyValue id={id} mode="link"/>;
            case 'service':
                return <ServiceValue id={id}/>;
            default:
                return '';
        }
    };

    render() {
        const { history, actions, defaultFilter, activeFilter, topActions, page } = this.props;
        const params = page.filter.params;

        const columns = [
            {
                title: 'Путь',
                dataIndex: 'path',
                key: 'path',
                //width: 150,

                sorter: true,
                sortOrder: ordering('path', params.ordering),
            },
            {
                title: 'Тайтл',
                dataIndex: 'title_en',
                key: 'title_en',
                //width: 150,

                sorter: true,
                sortOrder: ordering('title_en', params.ordering),
            },
            {
                title: 'Тип',
                dataIndex: 'type',
                key: 'type',
                width: 150,

                sorter: true,
                sortOrder: ordering('type', params.ordering),
            },
            {
                title: 'Объект',
                dataIndex: 'object_id',
                key: 'object_id',
                width: 150,

                render: (val, item) => this.renderObjectValue(item.type, val),
            },
            {
                title: 'Не использ. шаблон',
                dataIndex: 'seo_template_off',
                key: 'seo_template_off',
                width: 100,
                render: (val, item) => val && <Icon type="check-circle" theme="filled" style={{ color: '#fff' }}/>,

                sorter: true,
                sortOrder: ordering('seo_template_off', params.ordering),
            },
            {
                title: 'Действия',
                dataIndex: 'actions',
                key: 'actions',
                width: 130,
                align: 'right',
                render: (val, item) => {
                    return (
                        <ActionButton
                            actions={[
                                {
                                    key: 'edit',
                                    icon: <Icon type="edit"/>,
                                    title: 'Редактировать',
                                    action: () => actions.pageShowEditForm({ show: true, object: item }),
                                },
                                {
                                    key: 'delete',
                                    icon: <Icon type="delete"/>,
                                    title: 'Удалить',
                                    action: () => actions.pageDelete({ id: item.id }),
                                    confirm: {
                                        title: 'Уверены, что хотите удалить?',
                                        okText: 'Удалить',
                                        cancelText: 'Отмена'
                                    }
                                },
                            ]}

                        />
                    )
                }
            }
        ];

        return (
            <TableList
                history={history}
                defaultFilter={defaultFilter}
                activeFilter={activeFilter}
                data={page}
                columns={columns}
                topActions={topActions}
                onLoadData={this.load}
                plural={['страница', 'страницы', 'страниц']}

                filterFields={{
                    list: ['seo_template_off', 'type'],
                    byName: {
                        'seo_template_off': {
                            label: 'Не использовать шаблон',
                            type: 'bool',
                        },
                        'type': {
                            label: 'Тип страницы',
                            type: 'list',
                            options: [
                                { label: 'Страница', value: 'page' },
                                { label: 'Страница с агенствами', value: 'agency_list' },
                                { label: 'Агентство', value: 'agency' },
                                { label: 'Страница с анкетами', value: 'profile_list' },
                                { label: 'Анкета', value: 'profile' },
                                { label: 'Услуга', value: 'service' },
                                { label: 'Страница c отзывами', value: 'review_list' },
                            ]
                        },
                    }
                }}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        page: state.page,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.page), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SitePageList);