import { takeEvery } from 'redux-saga/effects';

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/transaction';


export function* watch() {
  yield takeEvery(actions.TRANSACTION_LOAD.START, actionTransactionLoad);
  yield takeEvery(actions.TRANSACTION_DETAIL_LOAD.START, actionTransactionDetailLoad);
}


function* actionTransactionLoad(action) {
  yield base.ListLoad(action, api.transactionLoad,
    {
      request: actions.transactionLoadRequest,
      complete: actions.transactionLoadComplete,
      error: actions.transactionLoadError
    },
  );
}

function* actionTransactionDetailLoad(action) {
  yield base.DetailLoad(action, api.transactionDetailLoad,
    {
      request: actions.transactionDetailLoadRequest,
      complete: actions.transactionDetailLoadComplete,
      error: actions.transactionDetailLoadError
    },
  );
}
