import React, { Component } from 'react';

import { CountrySelect, CitySelect } from 'components/blocks/geo';
import { DomainSelect } from 'components/blocks/domain';


export default class MainFieldSet extends Component {
    state = {
        countryId: null,
    };

    handleCountryChange = (value) => {
        this.setState({
            countryId: value,
        })
    };

    render() {
        const { form, instance, errorData } = this.props;
        const countryId = this.state.countryId || (instance && instance.country);
        const cityActiveFilter = countryId ? { country: countryId } : null;

        return (
            <div className="form-block">
                <DomainSelect
                    form={form}
                    instance={instance}
                    name="domain"
                    label="Домен"
                    required={true}
                    errorData={errorData}
                />

                <CountrySelect
                    form={form}
                    instance={instance}
                    name="country"
                    label="Страна"
                    required={false}
                    errorData={errorData}
                    onChange={this.handleCountryChange}
                />

                <CitySelect
                    form={form}
                    instance={instance}
                    name="city"
                    label="Город"
                    required={false}
                    errorData={errorData}
                    activeFilter={cityActiveFilter}
                />
            </div>
        );
    }
}
