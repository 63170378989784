import React, { Component } from 'react';
import { Col, Row, Input } from "antd";

import { FormItem } from 'utils';
import PhotoUpload from "components/common/PhotoUpload";


export default class ConfigFieldSet extends Component {
    state = {
        logo: [],
        favicon: [],
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { instance } = nextProps;
        const config = (instance && instance.config) || {};

        const logo = config.logo || [];
        const favicon = config.favicon || [];

        let nextState = {};

        if (logo !== prevState.logo || favicon !== prevState.favicon) {
            nextState.logo = logo;
            nextState.favicon = favicon;
        }
        return nextState;
    }

    handlePhotoChange = (fieldName, photos) => {
        this.setState({
            [fieldName]: photos,
        });
    };

    render() {
        const { form, instance, errorData, auth_token } = this.props;
        const { favicon } = this.state;

        return (
            <div className="form-block">
                <Row gutter={32}>
                    <Col span={17}>
                        <FormItem
                            form={form}
                            instance={instance}
                            name="config.meta"
                            label="Meta"
                            required={false}
                            errorData={errorData}
                        >
                            <Input.TextArea autosize={{ minRows: 3 }}/>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="config.scripts_header"
                            label="Скрипты header"
                            required={false}
                            errorData={errorData}
                        >
                            <Input.TextArea autosize={{ minRows: 3 }}/>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="config.scripts_footer"
                            label="Скрипты footer"
                            required={false}
                            errorData={errorData}
                        >
                            <Input.TextArea autosize={{ minRows: 3 }}/>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="config.metrics"
                            label="Метрика"
                            required={false}
                            errorData={errorData}
                        >
                            <Input.TextArea autosize={{ minRows: 3 }}/>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="config.robots"
                            label="Robots"
                            required={false}
                            errorData={errorData}
                        >
                            <Input.TextArea autosize={{ minRows: 3 }}/>
                        </FormItem>
                    </Col>

                    <Col span={7}>
                        <FormItem
                            form={form}
                            instance={instance}
                            name="config.favicon"
                            label="favicon"
                            required={false}
                            errorData={errorData}
                        >
                            <PhotoUpload
                                mode="single" title="Загрузить favicon" photos={favicon}
                                auth_token={auth_token}
                                onChange={(photos) => this.handlePhotoChange('favicon', photos)}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </div>
        );
    }
}
