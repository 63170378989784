import { takeEvery, select } from 'redux-saga/effects';

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/review';


export function* watch() {
  yield takeEvery(actions.REVIEW_LOAD.START, actionReviewLoad);
  yield takeEvery(actions.REVIEW_DETAIL_LOAD.START, actionReviewDetailLoad);
  yield takeEvery(actions.REVIEW_UPDATE.START, actionReviewUpdate);
  yield takeEvery(actions.REVIEW_DELETE.START, actionReviewDelete);
}

function* actionReviewLoad(action) {
  yield base.ListLoad(action, api.reviewLoad,
    {
      request: actions.reviewLoadRequest,
      complete: actions.reviewLoadComplete,
      error: actions.reviewLoadError
    },
  );
}

function* actionReviewDetailLoad(action) {
  yield base.DetailLoad(action, api.reviewDetailLoad,
    {
      request: actions.reviewDetailLoadRequest,
      complete: actions.reviewDetailLoadComplete,
      error: actions.reviewDetailLoadError
    },
  );
}

function* actionReviewUpdate(action) {
  const filter = yield select((state) => state.review.filter);
  yield base.Update(action, api.reviewUpdate,
    {
      request: actions.reviewUpdateRequest,
      complete: actions.reviewUpdateComplete,
      error: actions.reviewUpdateError,
      showEditForm: actions.reviewShowEditForm,
      detailLoad: actions.reviewDetailLoad,
      listLoad: actions.reviewLoad,
    },
    '/review',
    filter,
  );
}

function* actionReviewDelete(action) {
  const filter = yield select((state) => state.review.filter);
  yield base.Delete(action, api.reviewDelete,
    {
      request: actions.reviewDeleteRequest,
      complete: actions.reviewDeleteComplete,
      error: actions.reviewDeleteError,
      listLoad: actions.reviewLoad,
    },
    '/review',
    filter,
  );
}
