import React from 'react';
import { Transfer, Spin } from 'antd';
import { connect } from 'react-redux';

import { serviceLoad } from "store/api/service";
import { FormItem } from "utils";
import { DataLoadComponent } from 'components/common';


class ServiceTransfer extends DataLoadComponent {
    apiListLoad = serviceLoad;

    render() {
        const { form, instance, name, label, required, errorData, convertValue, valuePropName } = this.props;
        const { items, loadStatus } = this.state;
        const initialValue = instance && instance.id ? null : this.props.initialValue;

        const dataSource = items.map(item => {
            item.key = item.id;
            return item
        });

        return (
            <Spin spinning={loadStatus.isLoading}>
                <FormItem
                    form={form}
                    instance={instance}
                    name={name}
                    label={label}
                    required={required}
                    errorData={errorData}
                    initialValue={initialValue}
                    convertValue={convertValue}
                    valuePropName={valuePropName}
                >
                    <Transfer
                        dataSource={dataSource}
                        showSearch
                        listStyle={{
                            width: 250,
                            height: 300,
                        }}
                        operations={['to right', 'to left']}
                        render={item => `${item.name_en}`}
                    />
                </FormItem>
            </Spin>
        );
    }
}


function mapStateToProps(state) {
    return {
        auth_token: state.auth.token,
    };
}


export default connect(mapStateToProps)(ServiceTransfer);
