import { handleActions } from 'redux-actions';
import { loadStates, loadStatus } from 'utils';

import * as actions from './actions';
import * as base from "../BaseReducers";

const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.siteShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.siteLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.siteLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.siteLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.siteDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.siteDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.siteDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.siteUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.siteUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.siteUpdateError]: (state, action) => base.updateError(state, action),

    [actions.siteDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.siteDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.siteDeleteError]: (state, action) => base.deleteError(state, action),

    [actions.siteGenerateRequest]: (state, action) => ({
      ...state,
      updateStatus: loadStatus(loadStates.loading),
    }),
    [actions.siteGenerateComplete]: (state, action) => {
      return {
        ...state,
        updateStatus: loadStatus(loadStates.loaded),
      }
    },
    [actions.siteGenerateError]: (state, action) => ({
      ...state,
      updateStatus: loadStatus(loadStates.failed, action.payload),
    }),
  },
  initialState
);
