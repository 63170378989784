const ENV = {
    'local': {
        base_url: "http://findescortgirls.test:8000",
        site_url: "http://localhost:3000",
    },
    'production': {
        base_url: "https://findescortgirls.com",
        site_url: "https://admin.findescortgirls.com",
    },
};

const urls = ENV['production'];

export const BASE_URL = urls.base_url;
export const SITE_URL = urls.site_url;


export const LANGUAGES = [
    ['en', 'English'],
    ['es', 'Spanish'],
    ['de', 'German'],
    ['fr', 'French'],
    ['it', 'Italian'],
    ['tr', 'Turkish'],
    ['ru', 'Russian'],
];
export const LANGUAGE_CODE = 'en';
