import React, { Component } from 'react';
import { Select } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { FormItem, getObject } from "utils";
import { DomainValue } from 'components/blocks/domain';


class SiteSelect extends Component {
    state = {
        value: 0,
    };

    componentDidMount() {
        const { actions, site } = this.props;
        if (site.loadStatus.notAsked || site.query !== '') {
            actions.siteLoad({ query: '' });
        } else if (site.loadStatus.isLoaded) {
            this.init();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { site } = this.props;
        if (site.loadStatus.isLoaded) {
            this.init();
        }
    }

    init() {
        const { form, name } = this.props;
        const value = form.getFieldValue(name);
        if (this.state.value === 0 || this.state.value !== value) {
            this.handleChange(value);
        }
    }

    handleChange = (value) => {
        const { site, onChange } = this.props;
        this.setState({
            value: value,
        });

        if (onChange) {
            const obj = getObject(site, value);
            onChange(obj);
        }
    };

    render() {
        const { site, form, instance, name, label, required, errorData, initialValue } = this.props;

        return (
            <FormItem
                form={form}
                instance={instance}
                name={name}
                label={label}
                required={required}
                errorData={errorData}
                initialValue={initialValue}
            >
                <Select
                    style={{ width: '100%' }}
                    loading={site.loadStatus.isLoading}
                    onChange={this.handleChange}
                    showSearch={true}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    <Select.Option value={null}>---</Select.Option>
                    {site.list.map(id => {
                        const obj = getObject(site, id);
                        return (
                            <Select.Option key={`site${id}`} value={id}>
                                <DomainValue id={obj.domain} withoutLink={true}/>
                            </Select.Option>
                        )
                    })}
                </Select>
            </FormItem>
        );
    }
}


function mapStateToProps(state) {
    return {
        site: state.site,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.site), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteSelect);
