import { takeEvery } from 'redux-saga/effects';

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/balance';


export function* watch() {
  yield takeEvery(actions.BALANCE_LOAD.START, actionBalanceLoad);
  yield takeEvery(actions.BALANCE_DETAIL_LOAD.START, actionBalanceDetailLoad);
}


function* actionBalanceLoad(action) {
  yield base.ListLoad(action, api.balanceLoad,
    {
      request: actions.balanceLoadRequest,
      complete: actions.balanceLoadComplete,
      error: actions.balanceLoadError
    },
  );
}

function* actionBalanceDetailLoad(action) {
  yield base.DetailLoad(action, api.balanceDetailLoad,
    {
      request: actions.balanceDetailLoadRequest,
      complete: actions.balanceDetailLoadComplete,
      error: actions.balanceDetailLoadError
    },
  );
}
