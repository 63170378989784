import React, { Component, Fragment } from 'react';
import { Button, Tabs } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import { actions } from "store";
import { getUrlParams } from "utils";
import { SeoTemplatePageList, SeoTemplatePageForm } from 'components/blocks/seo_template_page';


class PageListScreen extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { match, location } = this.props;
        if (!location.search) {
            const site_id = match.params.id;
            let url = `/site/${site_id}/seo_template_page?type=profile`;
            this.props.history.replace(url);
        }
    }

    onTabChange = (key) => {
        const { match } = this.props;
        const site_id = match.params.id;
        let url = `/site/${site_id}/seo_template_page?type=${key}`;
        this.props.history.push(url);
    };

    renderTab = (title, key) => {
        const info = this.props.seo_template_page.info || {};
        const count = info[key];
        return (<div>{title} {count && <span>{count}</span>}</div>)
    };

    showPageForm = () => {
        const { actions } = this.props;
        actions.seoTemplatePageShowEditForm({ show: true });
    };

    render() {
        const { history, location, match } = this.props;
        const siteId = parseInt(match.params.id);
        const params = getUrlParams(location.search);
        const tabKey = (params && params.type) || 'profile';

        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <div className="page-block page-block-full">
                        <Tabs className="page-block-tabs" activeKey={tabKey} onChange={this.onTabChange}>
                            <Tabs.TabPane tab={this.renderTab('Шаблоны анкет', 'profile')} key="profile"/>
                            <Tabs.TabPane tab={this.renderTab('Шаблоны услуг', 'service')} key="service"/>
                        </Tabs>

                        {siteId &&
                        <SeoTemplatePageList
                            basePath={`/site/${siteId}`}
                            history={history}
                            activeFilter={{ site: siteId, type: tabKey }}
                            topActions={[
                                <Button type="primary" className="" icon="plus"
                                        onClick={() => this.showPageForm()}>
                                    Добавить шаблон
                                </Button>
                            ]}
                        />
                        }
                    </div>

                    {siteId &&
                    <SeoTemplatePageForm site_id={siteId} type={tabKey}/>
                    }
                </div>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        site: state.site,
        seo_template_page: state.seo_template_page,
        seo_template_photo: state.seo_template_photo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.seo_template_page, actions.seo_template_photo), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PageListScreen);
