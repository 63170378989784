import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getObject } from 'utils';


class SiteLink extends PureComponent {
    render() {
        const { id, site, domain, withoutLink } = this.props;
        const obj = getObject(site, id);
        const domainObj = obj ? getObject(domain, obj.domain) : null;

        return (
            withoutLink ?
                <span>{domainObj ? domainObj.domain : id}</span>
                :
                <Link to={`/site/${id}`}>{domainObj ? domainObj.domain : id}</Link>
        );
    }
}

function mapStateToProps(state) {
    return {
        site: state.site,
        domain: state.domain,
    };
}

export default connect(mapStateToProps)(SiteLink);
