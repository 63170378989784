import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const TRANSACTION_LOAD = createRequestTypes('TRANSACTION_LOAD');
export const TRANSACTION_DETAIL_LOAD = createRequestTypes('TRANSACTION_DETAIL_LOAD');

export const transactionLoad = createAction(TRANSACTION_LOAD.START);
export const transactionLoadRequest = createAction(TRANSACTION_LOAD.REQUEST);
export const transactionLoadComplete = createAction(TRANSACTION_LOAD.COMPLETE);
export const transactionLoadError = createAction(TRANSACTION_LOAD.ERROR);

export const transactionDetailLoad = createAction(TRANSACTION_DETAIL_LOAD.START);
export const transactionDetailLoadRequest = createAction(TRANSACTION_DETAIL_LOAD.REQUEST);
export const transactionDetailLoadComplete = createAction(TRANSACTION_DETAIL_LOAD.COMPLETE);
export const transactionDetailLoadError = createAction(TRANSACTION_DETAIL_LOAD.ERROR);
