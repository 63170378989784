import { all } from 'redux-saga/effects'
import * as app from 'store/app';
import * as auth from 'store/auth';
import * as user from 'store/user';
import * as geo_country from 'store/geo_country';
import * as geo_city from 'store/geo_city';
import * as site from 'store/site';
import * as domain from 'store/domain';
import * as service_group from 'store/service_group';
import * as service from 'store/service';
import * as agency from 'store/agency';
import * as profile from 'store/profile';
import * as page from 'store/page';
import * as seo_template_page from 'store/seo_template_page';
import * as site_nav from 'store/site_nav';
import * as balance from 'store/balance';
import * as transaction from 'store/transaction';
import * as refill from 'store/refill';
import * as review from 'store/review';
import * as message from 'store/message';


const actions = {
  app: app.actions,
  auth: auth.actions,
  user: user.actions,
  geo_country: geo_country.actions,
  geo_city: geo_city.actions,
  site: site.actions,
  domain: domain.actions,
  service_group: service_group.actions,
  service: service.actions,
  agency: agency.actions,
  profile: profile.actions,
  page: page.actions,
  seo_template_page: seo_template_page.actions,
  site_nav: site_nav.actions,

  balance: balance.actions,
  transaction: transaction.actions,
  refill: refill.actions,

  review: review.actions,
  message: message.actions,
};

const reducers = {
  app: app.reducer,
  auth: auth.reducer,
  user: user.reducer,
  geo_country: geo_country.reducer,
  geo_city: geo_city.reducer,
  site: site.reducer,
  domain: domain.reducer,
  service_group: service_group.reducer,
  service: service.reducer,
  agency: agency.reducer,
  profile: profile.reducer,
  page: page.reducer,
  seo_template_page: seo_template_page.reducer,
  site_nav: site_nav.reducer,

  balance: balance.reducer,
  transaction: transaction.reducer,
  refill: refill.reducer,

  review: review.reducer,
  message: message.reducer,
};


function* rootSaga() {
  yield all([
    app.sagas.watchAppInit(),
    app.sagas.watchAppDataLoad(),

    auth.sagas.watchAuthLogin(),
    auth.sagas.watchAuthLogout(),
    user.sagas.watch(),

    geo_country.sagas.watch(),
    geo_city.sagas.watch(),

    site.sagas.watch(),

    domain.sagas.watch(),

    service_group.sagas.watch(),
    service.sagas.watch(),

    agency.sagas.watch(),
    profile.sagas.watch(),
    page.sagas.watch(),

    seo_template_page.sagas.watch(),

    site_nav.sagas.watch(),

    balance.sagas.watch(),
    transaction.sagas.watch(),
    refill.sagas.watch(),

    review.sagas.watch(),
    message.sagas.watch(),
  ])
}


export {
  actions,
  reducers,
  rootSaga,
}
