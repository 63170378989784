import React, { Component } from 'react';
import { Icon } from 'antd';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { ordering } from "utils";
import { ActionButton, TableList } from "components/common";

class SeoTemplatePageList extends Component {
    load = (filter) => {
        const { actions, seo_template_page } = this.props;
        let newFilter = Object.assign({}, filter);
        if (newFilter.activeFilter === undefined) {
            newFilter.activeFilter =  seo_template_page.filter.activeFilter;
        }
        if (newFilter.activeFilter.type) {
            newFilter.query = `?type=${newFilter.activeFilter.type}`;
        }
        actions.seoTemplatePageLoad(newFilter);
    };

    render() {
        const { history, actions, defaultFilter, activeFilter, topActions, seo_template_page } = this.props;
        const params = seo_template_page.filter.params;

        const columns = [
            {
                title: 'Путь',
                dataIndex: 'tpl_path',
                key: 'cpath',
                //width: 150,

                sorter: true,
                sortOrder: ordering('tpl_path', params.ordering),
            },
            {
                title: 'Тайтл',
                dataIndex: 'tpl_title',
                key: 'tpl_title',
                //width: 150,

                sorter: true,
                sortOrder: ordering('tpl_title', params.ordering),
            },
            {
                title: 'Тип',
                dataIndex: 'type',
                key: 'type',
                width: 150,

                sorter: true,
                sortOrder: ordering('type', params.ordering),
            },
            {
                title: 'Действия',
                dataIndex: 'actions',
                key: 'actions',
                width: 130,
                align: 'right',
                render: (val, item) => {
                    return (
                        <ActionButton
                            actions={[
                                {
                                    key: 'edit',
                                    icon: <Icon type="edit"/>,
                                    title: 'Редактировать',
                                    action: () => actions.seoTemplatePageShowEditForm({ show: true, object: item }),
                                },
                                {
                                    key: 'delete',
                                    icon: <Icon type="delete"/>,
                                    title: 'Удалить',
                                    action: () => actions.seoTemplatePageDelete({ id: item.id }),
                                    confirm: {
                                        title: 'Уверены, что хотите удалить?',
                                        okText: 'Удалить',
                                        cancelText: 'Отмена'
                                    }
                                },
                            ]}

                        />
                    )
                }
            }
        ];

        return (
            <TableList
                history={history}
                defaultFilter={defaultFilter}
                activeFilter={activeFilter}
                data={seo_template_page}
                columns={columns}
                topActions={topActions}
                onLoadData={this.load}
                plural={['шаблон', 'шаблона', 'шаблонов']}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        seo_template_page: state.seo_template_page,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.seo_template_page), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeoTemplatePageList);