import { takeEvery, select } from 'redux-saga/effects';

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/geo_country';


export function* watch() {
    yield takeEvery(actions.GEO_COUNTRY_LOAD.START, actionCountryLoad);
    yield takeEvery(actions.GEO_COUNTRY_DETAIL_LOAD.START, actionCountryDetailLoad);
    yield takeEvery(actions.GEO_COUNTRY_UPDATE.START, actionCountryUpdate);
    yield takeEvery(actions.GEO_COUNTRY_DELETE.START, actionCountryDelete);
}

function* actionCountryLoad(action) {
    yield base.ListLoad(action, api.countryLoad,
      {
          request: actions.geoCountryLoadRequest,
          complete: actions.geoCountryLoadComplete,
          error: actions.geoCountryLoadError
      },
    );
}

function* actionCountryDetailLoad(action) {
    yield base.DetailLoad(action, api.countryDetailLoad,
      {
          request: actions.geoCountryDetailLoadRequest,
          complete: actions.geoCountryDetailLoadComplete,
          error: actions.geoCountryDetailLoadError
      },
    );
}

function* actionCountryUpdate(action) {
    const filter = yield select((state) => state.geo_country.filter);
    yield base.Update(action, api.countryUpdate,
      {
          request: actions.geoCountryUpdateRequest,
          complete: actions.geoCountryUpdateComplete,
          error: actions.geoCountryUpdateError,
          showEditForm: actions.geoCountryShowEditForm,
          detailLoad: actions.geoCountryDetailLoad,
          listLoad: actions.geoCountryLoad,
      },
      '/country',
      filter,
    );
}

function* actionCountryDelete(action) {
    const filter = yield select((state) => state.geo_country.filter);
    yield base.Delete(action, api.countryDelete,
      {
          request: actions.geoCountryDeleteRequest,
          complete: actions.geoCountryDeleteComplete,
          error: actions.geoCountryDeleteError,
          listLoad: actions.geoCountryLoad,
      },
      '/country',
      filter,
    );
}
