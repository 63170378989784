import React, { Component } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { DomainList, DomainForm } from 'components/blocks/domain';


class DomainListScreen extends Component {
    showEditForm = (item = null) => {
        const { actions } = this.props;
        actions.domainShowEditForm({ show: true, object: item });
    };

    render() {
        const { history } = this.props;

        return (
            <Page>
                <PageHeader
                    title="Домены"
                    actions={[
                        <Button type="primary" className="" icon="plus" onClick={() => this.showEditForm()}>
                            Добавить домен
                        </Button>
                    ]}
                />

                <div className="page-content page-content-full">
                    <div className="page-block page-block-full">
                        <DomainList basePath="/domain" history={history}/>
                    </div>
                </div>

                <DomainForm/>
            </Page>
        );
    }
}


function mapStateToProps(state) {
    return {
        domain: state.domain,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.domain), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DomainListScreen);
