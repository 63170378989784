import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const PAGE_LOAD = createRequestTypes('PAGE_LOAD');
export const PAGE_DETAIL_LOAD = createRequestTypes('PAGE_DETAIL_LOAD');
export const PAGE_UPDATE = createRequestTypes('PAGE_UPDATE');
export const PAGE_DELETE = createRequestTypes('PAGE_DELETE');
export const PAGE_SHOW_EDIT_FORM = 'PAGE_SHOW_EDIT_FORM';

export const pageShowEditForm = createAction(PAGE_SHOW_EDIT_FORM);

export const pageLoad = createAction(PAGE_LOAD.START);
export const pageLoadRequest = createAction(PAGE_LOAD.REQUEST);
export const pageLoadComplete = createAction(PAGE_LOAD.COMPLETE);
export const pageLoadError = createAction(PAGE_LOAD.ERROR);

export const pageDetailLoad = createAction(PAGE_DETAIL_LOAD.START);
export const pageDetailLoadRequest = createAction(PAGE_DETAIL_LOAD.REQUEST);
export const pageDetailLoadComplete = createAction(PAGE_DETAIL_LOAD.COMPLETE);
export const pageDetailLoadError = createAction(PAGE_DETAIL_LOAD.ERROR);

export const pageUpdate = createAction(PAGE_UPDATE.START);
export const pageUpdateRequest = createAction(PAGE_UPDATE.REQUEST);
export const pageUpdateComplete = createAction(PAGE_UPDATE.COMPLETE);
export const pageUpdateError = createAction(PAGE_UPDATE.ERROR);

export const pageDelete = createAction(PAGE_DELETE.START);
export const pageDeleteRequest = createAction(PAGE_DELETE.REQUEST);
export const pageDeleteComplete = createAction(PAGE_DELETE.COMPLETE);
export const pageDeleteError = createAction(PAGE_DELETE.ERROR);
