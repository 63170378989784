import { takeEvery, select } from 'redux-saga/effects';

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/service_group';


export function* watch() {
  yield takeEvery(actions.SERVICE_GROUP_LOAD.START, actionServiceGroupLoad);
  yield takeEvery(actions.SERVICE_GROUP_DETAIL_LOAD.START, actionServiceGroupDetailLoad);
  yield takeEvery(actions.SERVICE_GROUP_UPDATE.START, actionServiceGroupUpdate);
  yield takeEvery(actions.SERVICE_GROUP_DELETE.START, actionServiceGroupDelete);
}


function* actionServiceGroupLoad(action) {
  yield base.ListLoad(action, api.serviceGroupLoad,
    {
      request: actions.serviceGroupLoadRequest,
      complete: actions.serviceGroupLoadComplete,
      error: actions.serviceGroupLoadError
    },
  );
}

function* actionServiceGroupDetailLoad(action) {
  yield base.DetailLoad(action, api.serviceGroupDetailLoad,
    {
      request: actions.serviceGroupDetailLoadRequest,
      complete: actions.serviceGroupDetailLoadComplete,
      error: actions.serviceGroupDetailLoadError
    },
  );
}

function* actionServiceGroupUpdate(action) {
  const filter = yield select((state) => state.service_group.filter);
  yield base.Update(action, api.serviceGroupUpdate,
    {
      request: actions.serviceGroupUpdateRequest,
      complete: actions.serviceGroupUpdateComplete,
      error: actions.serviceGroupUpdateError,
      showEditForm: actions.serviceGroupShowEditForm,
      detailLoad: actions.serviceGroupDetailLoad,
      listLoad: actions.serviceGroupLoad,
    },
    null,
    filter,
  );
}

function* actionServiceGroupDelete(action) {
  const filter = yield select((state) => state.service_group.filter);
  yield base.Delete(action, api.serviceGroupDelete,
    {
      request: actions.serviceGroupDeleteRequest,
      complete: actions.serviceGroupDeleteComplete,
      error: actions.serviceGroupDeleteError,
      listLoad: actions.serviceGroupLoad,
    },
    null,
    filter,
  );
}
