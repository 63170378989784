import React, { Component, Fragment } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { PhotoDetail } from "components/common";
import { DomainValue } from 'components/blocks/domain';
import { CountryValue, CityValue } from "components/blocks/geo";
import styles from './detail.module.css';


class SiteDetailScreen extends Component {
    render() {
        const { site } = this.props;
        const detail = site.detail || {};

        const config = detail.config || {};
        const tariff = detail.tariff || {};

        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <Row gutter={32}>
                        <Col span={17}>
                            <div className="page-block">
                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Домен:</span>
                                    </Col>
                                    <Col span={19}>
                                        <DomainValue id={detail.domain} mode="link"/>
                                    </Col>
                                </Row>
                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Страна:</span>
                                    </Col>
                                    <Col span={19}>
                                        <CountryValue id={detail.country} mode="link"/>
                                    </Col>
                                </Row>
                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Город:</span>
                                    </Col>
                                    <Col span={19}>
                                        <CityValue id={detail.city} mode="link"/>
                                    </Col>
                                </Row>
                            </div>

                            <br/>

                            <div className="page-block">
                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Meta:</span>
                                    </Col>
                                    <Col span={19}>{config.meta}</Col>
                                </Row>

                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Robots:</span>
                                    </Col>
                                    <Col span={19}>{config.robots}</Col>
                                </Row>

                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Метрика:</span>
                                    </Col>
                                    <Col span={19}>{config.metrics}</Col>
                                </Row>

                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Скрипты header:</span>
                                    </Col>
                                    <Col span={19}>{config.scripts_header}</Col>
                                </Row>

                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Скрипты footer:</span>
                                    </Col>
                                    <Col span={19}>{config.scripts_footer}</Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={7}>
                            <div className="page-block">
                                <PhotoDetail
                                    label="Favicon"
                                    photos={config.favicon}
                                />
                            </div>

                            <br/>

                            <div className="page-block">
                                <Row className={styles.row}>
                                    <Col span={12}>
                                        <span className={styles.label}>Биллинг включён:</span>
                                    </Col>
                                    <Col span={12}>{detail.billing_is_on ? 'Да': 'Нет'}</Col>
                                </Row>
                                <br/>

                                <div>
                                    <div>Тарифы анкет:</div>
                                    <br/>
                                    <Row className={styles.row}>
                                        <Col span={12}>
                                            <span className={styles.label}>Период:</span>
                                        </Col>
                                        <Col span={12}>{tariff.profile_period} {tariff.profile_period && 'мин'}</Col>
                                    </Row>

                                    <Row className={styles.row}>
                                        <Col span={12}>
                                            <span className={styles.label}>Цена размещения:</span>
                                        </Col>
                                        <Col span={12}>{tariff.profile_price} {tariff.profile_price && '€'}</Col>
                                    </Row>

                                    <Row className={styles.row}>
                                        <Col span={12}>
                                            <span className={styles.label}>Цена поднятия:</span>
                                        </Col>
                                        <Col span={12}>{tariff.profile_up_price} {tariff.profile_up_price && '€'}</Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        site: state.site,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.site), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SiteDetailScreen);
