import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Table, Icon, Button, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { parseDate } from "utils";
import { DateTimeText } from "components/common";

import styles from './detail.module.css';


class CityDistrictScreen extends Component {
    render() {
        const { domain, actions } = this.props;
        const detail = domain.detail || {};

        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <div className="page-block">
                        <Row className={styles.row}>
                            <Col span={5}>
                                <span className={styles.label}>Домен:</span>
                            </Col>
                            <Col span={19}>
                                {detail.domain}
                            </Col>
                        </Row>

                        <Row className={styles.row}>
                            <Col span={5}>
                                <span className={styles.label}>Дата регистрации:</span>
                            </Col>
                            <Col span={19}>{parseDate(detail.date_created).format('DD.MM.YYYY')}</Col>
                        </Row>

                        <Row className={styles.row}>
                            <Col span={5}>
                                <span className={styles.label}>Дата продления:</span>
                            </Col>
                            <Col span={19}>{parseDate(detail.date_ended).format('DD.MM.YYYY')}</Col>
                        </Row>

                        <Row className={styles.row}>
                            <Col span={5}>
                                <span className={styles.label}>Заблокирован:</span>
                            </Col>
                            <Col span={19}>
                                {detail.is_blocked &&
                                <Icon type="close-circle" theme="filled" style={{ color: '#ff0000' }}/>}
                            </Col>
                        </Row>

                        {detail.block_records && detail.block_records.length > 0 &&
                        <Table
                            columns={[
                                {
                                    title: 'Дата',
                                    dataIndex: 'date',
                                    key: 'date',
                                    width: 150,
                                    render: (text, item) => text && parseDate(text).format('DD.MM.YYYY'),
                                }, {
                                    title: 'Гос. орган',
                                    dataIndex: 'gos_organ',
                                    key: 'gos_organ',
                                    width: 150,
                                }, {
                                    title: 'Постановление',
                                    dataIndex: 'postanovlenie',
                                    key: 'postanovlenie',
                                    width: 150,
                                }
                            ]}
                            dataSource={detail.block_records}
                            pagination={false}
                            rowKey="postanovlenie"
                        />
                        }
                    </div>

                    <br/>

                    {detail.mirrors && detail.mirrors.length > 0 &&
                    <div className="page-block">
                        <div className="page-block-title">Зеркала</div>


                        <Table
                            columns={[
                                {
                                    title: 'Домен',
                                    dataIndex: 'domain',
                                    key: 'domain',
                                    render: (text, item) => <Link to={`/domain/${item.id}`}>{text}</Link>,
                                },
                                {
                                    title: 'Дата регистрации',
                                    dataIndex: 'date_created',
                                    key: 'date_created',
                                    width: 120,
                                    render: (text, item) => <DateTimeText date={text} onlyDate={true}/>,
                                },
                                {
                                    title: 'Дата продления',
                                    dataIndex: 'date_ended',
                                    key: 'date_ended',
                                    width: 120,
                                    render: (text, item) => <DateTimeText date={text} onlyDate={true}/>,
                                },
                                {
                                    title: 'Заблокирован',
                                    dataIndex: 'is_blocked',
                                    key: 'is_blocked',
                                    width: 100,
                                    render: (text, item) => text &&
                                        <Icon type="close-circle" theme="filled" style={{ color: '#ff0000' }}/>,
                                },
                                {
                                    title: 'Действия',
                                    dataIndex: 'actions',
                                    key: 'actions',
                                    width: 130,
                                    align: 'right',
                                    render: (text, item) => {
                                        return (
                                            <div>
                                                <Button
                                                    size="small"
                                                    onClick={() => actions.domainMakeMain({ id: item.id })}
                                                >
                                                    <Icon type="up-square"/>
                                                </Button>

                                                <span>&nbsp;</span>

                                                <Button
                                                    size="small"
                                                    onClick={() => actions.domainShowEditForm({
                                                        show: true,
                                                        object: item
                                                    })}
                                                >
                                                    <Icon type="edit"/>
                                                </Button>

                                                <span>&nbsp;</span>

                                                <Popconfirm
                                                    placement="bottomLeft" title="Уверены, что хотите удалить?"
                                                    okText="Да" cancelText="Нет"
                                                    onConfirm={() => actions.domainDelete({ id: item.id })}
                                                >
                                                    <Button type="danger" size="small">
                                                        <Icon type="delete"/>
                                                    </Button>
                                                </Popconfirm>

                                            </div>
                                        )
                                    }
                                }
                            ]}
                            dataSource={detail.mirrors}
                            pagination={false}
                            rowKey="id"
                        />
                    </div>
                    }
                </div>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        domain: state.domain,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.domain), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CityDistrictScreen);
