import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import { actions } from "store";
import { SitePageList, SitePageForm } from 'components/blocks/site';
import { Button } from "antd";


class PageListScreen extends Component {
    showForm = (item = null) => {
        const { actions } = this.props;
        actions.pageShowEditForm({ show: true, object: item });
    };

    render() {
        const { history, match } = this.props;
        const siteId = parseInt(match.params.id);

        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <div className="page-block page-block-full">
                        {siteId &&
                        <SitePageList
                            basePath={`/site/${siteId}`}
                            history={history}
                            activeFilter={{ site: siteId }}
                            topActions={[
                                <Button type="primary" className="" icon="plus" onClick={() => this.showForm()}>
                                    Добавить страницу
                                </Button>
                            ]}
                        />}
                    </div>

                    {siteId &&
                    <SitePageForm site_id={siteId}/>
                    }
                </div>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        site: state.site,
        page: state.page,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.page), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PageListScreen);
