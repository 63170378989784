import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as base from "../BaseReducers";

const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
);

export default handleActions(
  {
    [actions.balanceLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.balanceLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.balanceLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.balanceDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.balanceDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.balanceDetailLoadError]: (state, action) => base.detailLoadError(state, action),
  },
  initialState
);
