import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { Layout } from 'antd';

import { Header, Navigation } from 'components/layout';
import DashboardScreen from 'screens/DashboardScreen';
import { CountryListScreen, CountryScreen } from 'screens/Country';
import { SiteListScreen, SiteScreen } from 'screens/Site';
import { DomainListScreen, DomainScreen } from 'screens/Domain';
import { ServiceListScreen } from 'screens/Service';
import { AgencyListScreen, AgencyScreen } from 'screens/Agency';
import { ProfileListScreen, ProfileScreen } from 'screens/Profile';
import { BalanceListScreen, BalanceScreen } from 'screens/Balance';
import { TransactionListScreen } from 'screens/Transaction';
import { UserListScreen, UserScreen } from 'screens/User';
import { ReviewListScreen, ReviewScreen } from 'screens/Review';
import { MessageListScreen, MessageScreen } from 'screens/Message';


export default class MainScreen extends Component {
  state = {
    collapsed: false,
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  render() {
    const { history } = this.props;

    return (
      <Layout>
        <Layout.Sider
          breakpoint="lg"
          collapsedWidth="0"
          //trigger={null}
          //collapsible
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
          className="app-layout-sider"
        >
          <div className="logo"/>
          <Navigation history={history}/>
        </Layout.Sider>

        <Layout className="app-layout">
          <Header history={history}/>

          <Layout.Content className="app-layout-content">
            <Switch>
              <Route exact path="/" component={DashboardScreen}/>
              <Route exact path="/country" component={CountryListScreen}/>
              <Route path="/country/:id" component={CountryScreen}/>

              <Route exact path="/site" component={SiteListScreen}/>
              <Route path="/site/:id" component={SiteScreen}/>

              <Route exact path="/domain" component={DomainListScreen}/>
              <Route path="/domain/:id" component={DomainScreen}/>

              <Route exact path="/service" component={ServiceListScreen}/>

              <Route exact path="/agency" component={AgencyListScreen}/>
              <Route path="/agency/status:status" component={AgencyListScreen}/>
              <Route path="/agency/:id" component={AgencyScreen}/>

              <Route exact path="/profile" component={ProfileListScreen}/>
              <Route path="/profile/status:status" component={ProfileListScreen}/>
              <Route path="/profile/:id" component={ProfileScreen}/>

              <Route exact path="/balance" component={BalanceListScreen}/>
              <Route path="/balance/:id" component={BalanceScreen}/>

              <Route exact path="/user" component={UserListScreen}/>
              <Route path="/user/:id" component={UserScreen}/>

              <Route exact path="/review" component={ReviewListScreen}/>
              <Route path="/review/:id" component={ReviewScreen}/>

              <Route exact path="/message" component={MessageListScreen}/>
              <Route path="/message/:id" component={MessageScreen}/>

              <Route exact path="/transaction"
                     component={TransactionListScreen}/>
            </Switch>
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }
}
