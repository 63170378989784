import { takeEvery, select } from 'redux-saga/effects';

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/geo_city';


export function* watch() {
    yield takeEvery(actions.GEO_CITY_LOAD.START, actionCityLoad);
    yield takeEvery(actions.GEO_CITY_DETAIL_LOAD.START, actionCityDetailLoad);
    yield takeEvery(actions.GEO_CITY_UPDATE.START, actionCityUpdate);
    yield takeEvery(actions.GEO_CITY_DELETE.START, actionCityDelete);
}

function* actionCityLoad(action) {
    yield base.ListLoad(action, api.cityLoad,
      {
          request: actions.geoCityLoadRequest,
          complete: actions.geoCityLoadComplete,
          error: actions.geoCityLoadError
      },
    );
}

function* actionCityDetailLoad(action) {
    yield base.DetailLoad(action, api.cityDetailLoad,
      {
          request: actions.geoCityDetailLoadRequest,
          complete: actions.geoCityDetailLoadComplete,
          error: actions.geoCityDetailLoadError
      },
    );
}

function* actionCityUpdate(action) {
    const filter = yield select((state) => state.geo_city.filter);
    yield base.Update(action, api.cityUpdate,
      {
          request: actions.geoCityUpdateRequest,
          complete: actions.geoCityUpdateComplete,
          error: actions.geoCityUpdateError,
          showEditForm: actions.geoCityShowEditForm,
          detailLoad: actions.geoCityDetailLoad,
          listLoad: actions.geoCityLoad,
      },
      null,
      filter,
    );
}

function* actionCityDelete(action) {
    const filter = yield select((state) => state.geo_city.filter);
    yield base.Delete(action, api.cityDelete,
      {
          request: actions.geoCityDeleteRequest,
          complete: actions.geoCityDeleteComplete,
          error: actions.geoCityDeleteError,
          listLoad: actions.geoCityLoad,
      },
      null,
      filter,
    );
}
