import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const MESSAGE_LOAD = createRequestTypes('MESSAGE_LOAD');
export const MESSAGE_DETAIL_LOAD = createRequestTypes('MESSAGE_DETAIL_LOAD');
export const MESSAGE_UPDATE = createRequestTypes('MESSAGE_UPDATE');
export const MESSAGE_DELETE = createRequestTypes('MESSAGE_DELETE');
export const MESSAGE_SHOW_EDIT_FORM = 'MESSAGE_SHOW_EDIT_FORM';
export const MESSAGE_SHOW_REPLY_FORM = 'MESSAGE_SHOW_REPLY_FORM';

export const messageShowEditForm = createAction(MESSAGE_SHOW_EDIT_FORM);
export const messageShowReplyForm = createAction(MESSAGE_SHOW_REPLY_FORM);

export const messageLoad = createAction(MESSAGE_LOAD.START);
export const messageLoadRequest = createAction(MESSAGE_LOAD.REQUEST);
export const messageLoadComplete = createAction(MESSAGE_LOAD.COMPLETE);
export const messageLoadError = createAction(MESSAGE_LOAD.ERROR);

export const messageDetailLoad = createAction(MESSAGE_DETAIL_LOAD.START);
export const messageDetailLoadRequest = createAction(MESSAGE_DETAIL_LOAD.REQUEST);
export const messageDetailLoadComplete = createAction(MESSAGE_DETAIL_LOAD.COMPLETE);
export const messageDetailLoadError = createAction(MESSAGE_DETAIL_LOAD.ERROR);

export const messageUpdate = createAction(MESSAGE_UPDATE.START);
export const messageUpdateRequest = createAction(MESSAGE_UPDATE.REQUEST);
export const messageUpdateComplete = createAction(MESSAGE_UPDATE.COMPLETE);
export const messageUpdateError = createAction(MESSAGE_UPDATE.ERROR);

export const messageDelete = createAction(MESSAGE_DELETE.START);
export const messageDeleteRequest = createAction(MESSAGE_DELETE.REQUEST);
export const messageDeleteComplete = createAction(MESSAGE_DELETE.COMPLETE);
export const messageDeleteError = createAction(MESSAGE_DELETE.ERROR);
