import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const SERVICE_LOAD = createRequestTypes('SERVICE_LOAD');
export const SERVICE_DETAIL_LOAD = createRequestTypes('SERVICE_DETAIL_LOAD');
export const SERVICE_UPDATE = createRequestTypes('SERVICE_UPDATE');
export const SERVICE_DELETE = createRequestTypes('SERVICE_DELETE');
export const SERVICE_SHOW_EDIT_FORM = 'SERVICE_SHOW_EDIT_FORM';

export const serviceShowEditForm = createAction(SERVICE_SHOW_EDIT_FORM);

export const serviceLoad = createAction(SERVICE_LOAD.START);
export const serviceLoadRequest = createAction(SERVICE_LOAD.REQUEST);
export const serviceLoadComplete = createAction(SERVICE_LOAD.COMPLETE);
export const serviceLoadError = createAction(SERVICE_LOAD.ERROR);

export const serviceDetailLoad = createAction(SERVICE_DETAIL_LOAD.START);
export const serviceDetailLoadRequest = createAction(SERVICE_DETAIL_LOAD.REQUEST);
export const serviceDetailLoadComplete = createAction(SERVICE_DETAIL_LOAD.COMPLETE);
export const serviceDetailLoadError = createAction(SERVICE_DETAIL_LOAD.ERROR);

export const serviceUpdate = createAction(SERVICE_UPDATE.START);
export const serviceUpdateRequest = createAction(SERVICE_UPDATE.REQUEST);
export const serviceUpdateComplete = createAction(SERVICE_UPDATE.COMPLETE);
export const serviceUpdateError = createAction(SERVICE_UPDATE.ERROR);

export const serviceDelete = createAction(SERVICE_DELETE.START);
export const serviceDeleteRequest = createAction(SERVICE_DELETE.REQUEST);
export const serviceDeleteComplete = createAction(SERVICE_DELETE.COMPLETE);
export const serviceDeleteError = createAction(SERVICE_DELETE.ERROR);
