import { BASE_URL } from "config";
import * as baseApi from "./_base";

export const endpoints = {
    list: `${BASE_URL}/api/v1/billing/balance/`,
    detail: (id) => `${BASE_URL}/api/v1/billing/balance/${id}/`,
};

export const balanceLoad = (token, payload) => baseApi.ListLoad(endpoints.list, token, payload);
export const balanceDetailLoad = (token, { id }) => baseApi.DetailLoad(endpoints.detail, token, { id });
