import axios from 'axios';
import { BASE_URL } from "config";
import * as baseApi from "./_base";

export const endpoints = {
    current: `${BASE_URL}/api/v1/user/current`,
    list: `${BASE_URL}/api/v1/user/`,
    detail: (id) => `${BASE_URL}/api/v1/user/${id}/`,
};


export const currentUserLoad = ({ token }) => {
    return axios.get(endpoints.current, {
        headers: {
            'Authorization': `JWT ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            return { error: false, data: response.data, statusCode: response.status }
        })
        .catch(error => {
            return { error: true, data: error.response.data, statusCode: error.response.status }
        });
};

export const userLoad = (token, payload) => baseApi.ListLoad(endpoints.list, token, payload);
export const userDetailLoad = (token, { id }) => baseApi.DetailLoad(endpoints.detail, token, { id });
export const userUpdate = (token, data) => baseApi.Update(endpoints.list, endpoints.detail, token, data);
export const userDelete = (token, { id }) => baseApi.Delete(endpoints.detail, token, { id });
