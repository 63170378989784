import React, { Component } from 'react';
import { Icon } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { ordering } from "utils";
import { ActionButton, TableList } from "components/common";


class ServiceList extends Component {
    load = (filter) => {
        const { actions, service } = this.props;
        let newFilter = Object.assign({}, filter);
        if (newFilter.activeFilter === undefined) {
            newFilter.activeFilter = service.filter.activeFilter
        }
        actions.serviceGroupLoad({ query: '' });
        actions.serviceLoad(newFilter);
    };

    render() {
        const { history, actions, defaultFilter, activeFilter, service_group, service } = this.props;
        const params = service.filter.params;

        let dataSource = [];
        if (service_group.list.length > 0) {
            service_group.list.forEach(id => {
                const obj = service_group.byId[id];
                obj.pk = `group${id}`;
                let children = [];
                service.list.forEach(id => {
                    const obj2 = service.byId[id];
                    obj2.pk = `service${id}`;
                    if (obj2.group === obj.id) {
                        children.push(obj2);
                    }
                });
                obj.children = children;
                dataSource.push(obj);
            });
        }

        const columns = [
            {
                title: 'Название',
                dataIndex: 'name_en',
                key: 'name_en',
                sorter: true,
                sortOrder: ordering('name_en', params.ordering),
            },
            {
                title: 'Путь',
                dataIndex: 'slug',
                key: 'slug',
                width: 150,
                sorter: true,
                sortOrder: ordering('slug', params.ordering),
            },
            {
                title: 'Сортировка',
                dataIndex: 'sort',
                key: 'sort',
                width: 120,
                sorter: true,
                sortOrder: ordering('sort', params.ordering),
            },
            {
                title: 'Действия',
                dataIndex: 'actions',
                key: 'actions',
                width: 130,
                align: 'right',
                render: (val, item) => {
                    return (
                        <ActionButton
                            actions={[
                                {
                                    key: 'edit',
                                    icon: <Icon type="edit"/>,
                                    title: 'Редактировать',
                                    action: () => {
                                        if (item.children) {
                                            actions.serviceGroupShowEditForm({ show: true, object: item })
                                        } else {
                                            actions.serviceShowEditForm({ show: true, object: item })
                                        }
                                    },
                                },
                                {
                                    key: 'delete',
                                    icon: <Icon type="delete"/>,
                                    title: 'Удалить',
                                    action: () => {
                                        if (item.children) {
                                            actions.serviceGroupDelete({ id: item.id })
                                        } else {
                                            actions.serviceDelete({ id: item.id })
                                        }
                                    },
                                    confirm: {
                                        title: 'Уверены, что хотите удалить?',
                                        okText: 'Удалить',
                                        cancelText: 'Отмена'
                                    }
                                },
                            ]}

                        />
                    )
                }
            }
        ];

        return (
            <TableList
                history={history}
                defaultFilter={defaultFilter}
                activeFilter={activeFilter}
                data={service}
                dataSource={dataSource}
                childrenColumnName='children'
                rowKey="pk"
                columns={columns}
                onLoadData={this.load}
                plural={['услуга', 'услуги', 'услуг']}
                onRow={(item, rowIndex) => {
                    return {
                        onDoubleClick: event => {
                            this.props.history.push(`/country/${item.id}`);
                        },
                    };
                }}
            />
        );
    }
}


function mapStateToProps(state) {
    return {
        service_group: state.service_group,
        service: state.service,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.service_group, actions.service), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceList);
