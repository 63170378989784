import { put, call, takeEvery, select } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as balanceActions from '../balance/actions';
import * as transactionActions from '../transaction/actions';
import * as api from 'store/api/refill';


export function* watch() {
  yield takeEvery(actions.REFILL_LOAD.START, actionRefillLoad);
  yield takeEvery(actions.REFILL_DETAIL_LOAD.START, actionRefillDetailLoad);
  yield takeEvery(actions.REFILL_UPDATE.START, actionRefillUpdate);
}


function* actionRefillLoad(action) {
  yield base.ListLoad(action, api.refillLoad,
    {
      request: actions.refillLoadRequest,
      complete: actions.refillLoadComplete,
      error: actions.refillLoadError
    },
  );
}

function* actionRefillDetailLoad(action) {
  yield base.DetailLoad(action, api.refillDetailLoad,
    {
      request: actions.refillDetailLoadRequest,
      complete: actions.refillDetailLoadComplete,
      error: actions.refillDetailLoadError
    },
  );
}

function* actionRefillUpdate(action) {
  const token = yield select((state) => state.auth.token);
  const query = yield select((state) => state.transaction.query);

  yield put(actions.refillUpdateRequest());
  try {
    const { error, data, statusCode } = yield call(() => api.refillUpdate(token, action.payload.data));

    if (error) {
      yield put(actions.refillUpdateError({ data, statusCode }));
    } else {
      yield put(actions.refillUpdateComplete(data));
      yield put(actions.refillShowEditForm({ show: false }));
      yield put(balanceActions.balanceDetailLoad({ id: data.balance }));
      yield put(transactionActions.transactionLoad({ query, filter: { user_balance: data.balance } }));
      yield put(push(`/balance/${data.balance}/transaction`));
    }

  } catch (error) {
    yield put(actions.refillUpdateError());
  }
}
