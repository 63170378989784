import React, { Component } from 'react';
import { Icon } from 'antd';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { ordering } from "utils";
import { ActionButton, TableList } from "components/common";
import { PageLink } from "components/blocks/site"


class SiteNavList extends Component {
    load = (filter) => {
        const { actions, site_nav } = this.props;
        let newFilter = Object.assign({}, filter);
        if (newFilter.activeFilter === undefined) {
            newFilter.activeFilter = site_nav.filter.activeFilter
        }
        actions.siteNavLoad(newFilter);
    };

    render() {
        const { history, actions, defaultFilter, activeFilter, topActions, site_nav } = this.props;
        const params = site_nav.filter.params;

        const columns = [
            {
                title: 'Название',
                dataIndex: 'name_en',
                key: 'name_en',

                sorter: true,
                sortOrder: ordering('name_en', params.ordering),
            },
            {
                title: 'Путь',
                dataIndex: 'path',
                key: 'path',
                render: (text, item) => <PageLink id={text}/>
            },
            {
                title: 'Тип',
                dataIndex: 'type',
                key: 'type',
                width: 150,

                sorter: true,
                sortOrder: ordering('type', params.ordering),
            },
            {
                title: ' Сортировка',
                dataIndex: 'sort',
                key: 'sort',
                width: 150,

                sorter: true,
                sortOrder: ordering('sort', params.ordering),
            },
            {
                title: 'Действия',
                dataIndex: 'actions',
                key: 'actions',
                width: 130,
                align: 'right',
                render: (val, item) => {
                    return (
                        <ActionButton
                            actions={[
                                {
                                    key: 'edit',
                                    icon: <Icon type="edit"/>,
                                    title: 'Редактировать',
                                    action: () => actions.siteNavShowEditForm({ show: true, object: item }),
                                },
                                {
                                    key: 'delete',
                                    icon: <Icon type="delete"/>,
                                    title: 'Удалить',
                                    action: () => actions.siteNavDelete({ id: item.id }),
                                    confirm: {
                                        title: 'Уверены, что хотите удалить?',
                                        okText: 'Удалить',
                                        cancelText: 'Отмена'
                                    }
                                },
                            ]}

                        />
                    )
                }
            }
        ];

        return (
            <TableList
                history={history}
                defaultFilter={defaultFilter}
                activeFilter={activeFilter}
                data={site_nav}
                topActions={topActions}
                columns={columns}
                onLoadData={this.load}
                plural={['элемент', 'элемента', 'элементов']}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        site_nav: state.site_nav,
        page: state.page,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.site_nav, actions.page), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteNavList);
