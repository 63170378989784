import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import { actions } from "store";
import { ProfileList } from "components/blocks/profile";


class ProfileListScreen extends Component {
    showProfileForm = (item = null) => {
        const { actions } = this.props;
        actions.profileShowEditForm({ show: true, object: item });
    };

    render() {
        const { history, match } = this.props;
        const userId = parseInt(match.params.id);

        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <div className="page-block page-block-full">
                        <ProfileList
                            basePath={`/user/${userId}`}
                            history={history}
                            activeFilter={{ user: userId }}
                            hideFilters={true}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.user,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.profile), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileListScreen);
