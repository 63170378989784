import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const AGENCY_LOAD = createRequestTypes('AGENCY_LOAD');
export const AGENCY_DETAIL_LOAD = createRequestTypes('AGENCY_DETAIL_LOAD');
export const AGENCY_UPDATE = createRequestTypes('AGENCY_UPDATE');
export const AGENCY_DELETE = createRequestTypes('AGENCY_DELETE');
export const AGENCY_SHOW_EDIT_FORM = 'AGENCY_SHOW_EDIT_FORM';

export const agencyShowEditForm = createAction(AGENCY_SHOW_EDIT_FORM);

export const agencyLoad = createAction(AGENCY_LOAD.START);
export const agencyLoadRequest = createAction(AGENCY_LOAD.REQUEST);
export const agencyLoadComplete = createAction(AGENCY_LOAD.COMPLETE);
export const agencyLoadError = createAction(AGENCY_LOAD.ERROR);

export const agencyDetailLoad = createAction(AGENCY_DETAIL_LOAD.START);
export const agencyDetailLoadRequest = createAction(AGENCY_DETAIL_LOAD.REQUEST);
export const agencyDetailLoadComplete = createAction(AGENCY_DETAIL_LOAD.COMPLETE);
export const agencyDetailLoadError = createAction(AGENCY_DETAIL_LOAD.ERROR);

export const agencyUpdate = createAction(AGENCY_UPDATE.START);
export const agencyUpdateRequest = createAction(AGENCY_UPDATE.REQUEST);
export const agencyUpdateComplete = createAction(AGENCY_UPDATE.COMPLETE);
export const agencyUpdateError = createAction(AGENCY_UPDATE.ERROR);

export const agencyDelete = createAction(AGENCY_DELETE.START);
export const agencyDeleteRequest = createAction(AGENCY_DELETE.REQUEST);
export const agencyDeleteComplete = createAction(AGENCY_DELETE.COMPLETE);
export const agencyDeleteError = createAction(AGENCY_DELETE.ERROR);
