import React, { Component } from 'react';
import { Button, Modal, Form, Spin, Tabs } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";

import MainFieldSet from './MainFieldSet';
import ContactFieldSet from './ContactFieldSet';
import GeoFieldSet from './GeoFieldSet';


class AgencyForm extends Component {
    state = {
        id: null,
        photos: [],
        show: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { editObject, showEditForm } = nextProps.agency;

        let nextState = {};

        if (showEditForm !== prevState.show) {
            nextState = {
                id: null,
                photos: [],
                show: showEditForm,
            };
        }

        if (editObject && editObject.id !== prevState.id) {
            nextState.id = editObject.id;
            nextState.photos = editObject.photos;
        }
        return nextState;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { actions, agency } = this.props;
                const instance = agency.editObject;

                let data = Object.assign({}, values, { photos: this.state.photos });
                if (instance && instance.id) {
                    data.id = instance.id;
                }
                actions.agencyUpdate({ data });
            }
        });
    };

    close = () => {
        const { actions } = this.props;
        actions.agencyShowEditForm({ show: false });
    };

    handlePhotosChange = (photos) => {
        this.setState({
            photos: photos,
        })
    };

    render() {
        const { form, agency, auth_token } = this.props;
        const { showEditForm, updateStatus } = agency;

        const error = updateStatus.error || {};
        const errorData = error.data || {};

        const isLoading = updateStatus.isLoading;

        return (
            <Modal
                width={960}
                visible={showEditForm}
                title="Редактирование анкеты"
                onCancel={this.close}
                maskClosable={false}
                destroyOnClose={true}
                footer={[
                    <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
                    <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
                        Сохранить
                    </Button>,
                ]}
            >
                <Spin spinning={isLoading}>
                    <Form onSubmit={this.handleSubmit}>
                        {errorData.non_field_errors &&
                        <div className="form-error">{errorData.non_field_errors}</div>}

                        <Tabs className="tabs">
                            <Tabs.TabPane tab="Основное" key="detail">
                                <MainFieldSet agency={agency} form={form} onPhotoChange={this.handlePhotosChange}
                                              auth_token={auth_token}/>

                                <GeoFieldSet agency={agency} form={form}/>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab="Контакты" key="contacts">
                                <ContactFieldSet agency={agency} form={form}/>
                            </Tabs.TabPane>
                        </Tabs>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}


const WrappedAgencyForm = Form.create()(AgencyForm);

function mapStateToProps(state) {
    return {
        auth_token: state.auth.token,
        agency: state.agency,
        service: state.service,
        geo_city: state.geo_city,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            actions.agency,
            actions.service,
        ), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAgencyForm);

