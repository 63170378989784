import { put, call, takeEvery, select } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/domain';


export function* watch() {
  yield takeEvery(actions.DOMAIN_LOAD.START, actionDomainLoad);
  yield takeEvery(actions.DOMAIN_DETAIL_LOAD.START, actionDomainDetailLoad);
  yield takeEvery(actions.DOMAIN_UPDATE.START, actionDomainUpdate);
  yield takeEvery(actions.DOMAIN_DELETE.START, actionDomainDelete);
  yield takeEvery(actions.DOMAIN_MAKE_MAIN.START, actionDomainMakeMain);
}

function* actionDomainLoad(action) {
  yield base.ListLoad(action, api.domainLoad,
    {
      request: actions.domainLoadRequest,
      complete: actions.domainLoadComplete,
      error: actions.domainLoadError
    },
  );
}

function* actionDomainDetailLoad(action) {
  yield base.DetailLoad(action, api.domainDetailLoad,
    {
      request: actions.domainDetailLoadRequest,
      complete: actions.domainDetailLoadComplete,
      error: actions.domainDetailLoadError
    },
  );
}

function* actionDomainUpdate(action) {
  const filter = yield select((state) => state.domain.filter);
  yield base.Update(action, api.domainUpdate,
    {
      request: actions.domainUpdateRequest,
      complete: actions.domainUpdateComplete,
      error: actions.domainUpdateError,
      showEditForm: actions.domainShowEditForm,
      detailLoad: actions.domainDetailLoad,
      listLoad: actions.domainLoad,
    },
    '/domain',
    filter,
  );
}

function* actionDomainDelete(action) {
  const filter = yield select((state) => state.domain.filter);
  yield base.Delete(action, api.domainDelete,
    {
      request: actions.domainDeleteRequest,
      complete: actions.domainDeleteComplete,
      error: actions.domainDeleteError,
      listLoad: actions.domainLoad,
    },
    '/domain',
    filter,
  );
}


function* actionDomainMakeMain(action) {
  const token = yield select((state) => state.auth.token);

  yield put(actions.domainMakeMainRequest());
  try {
    const { error, data, statusCode } = yield call(() => api.domainMakeMain(token, action.payload));

    if (error) {
      yield put(actions.domainMakeMainError({ data, statusCode }));
    } else {
      yield put(actions.domainMakeMainComplete(data));
      yield put(actions.domainDetailLoad({ id: data.parent || data.id }));
      yield put(push(`/domain/${data.parent || data.id}`));
    }

  } catch (error) {
    yield put(actions.domainUpdateError());
  }
}
