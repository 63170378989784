import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const PROFILE_LOAD = createRequestTypes('PROFILE_LOAD');
export const PROFILE_DETAIL_LOAD = createRequestTypes('PROFILE_DETAIL_LOAD');
export const PROFILE_UPDATE = createRequestTypes('PROFILE_UPDATE');
export const PROFILE_DELETE = createRequestTypes('PROFILE_DELETE');
export const PROFILE_SHOW_EDIT_FORM = 'PROFILE_SHOW_EDIT_FORM';

export const profileShowEditForm = createAction(PROFILE_SHOW_EDIT_FORM);

export const profileLoad = createAction(PROFILE_LOAD.START);
export const profileLoadRequest = createAction(PROFILE_LOAD.REQUEST);
export const profileLoadComplete = createAction(PROFILE_LOAD.COMPLETE);
export const profileLoadError = createAction(PROFILE_LOAD.ERROR);

export const profileDetailLoad = createAction(PROFILE_DETAIL_LOAD.START);
export const profileDetailLoadRequest = createAction(PROFILE_DETAIL_LOAD.REQUEST);
export const profileDetailLoadComplete = createAction(PROFILE_DETAIL_LOAD.COMPLETE);
export const profileDetailLoadError = createAction(PROFILE_DETAIL_LOAD.ERROR);

export const profileUpdate = createAction(PROFILE_UPDATE.START);
export const profileUpdateRequest = createAction(PROFILE_UPDATE.REQUEST);
export const profileUpdateComplete = createAction(PROFILE_UPDATE.COMPLETE);
export const profileUpdateError = createAction(PROFILE_UPDATE.ERROR);

export const profileDelete = createAction(PROFILE_DELETE.START);
export const profileDeleteRequest = createAction(PROFILE_DELETE.REQUEST);
export const profileDeleteComplete = createAction(PROFILE_DELETE.COMPLETE);
export const profileDeleteError = createAction(PROFILE_DELETE.ERROR);
