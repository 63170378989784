import React, { Component } from 'react';
import { Button, Modal, Form, Spin, Input } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { FormItem } from "utils";

import styles from "./form.module.css";


class RefillForm extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { actions, refill, user_id } = this.props;
                const instance = refill.editObject;

                let data = Object.assign({ user: user_id }, values);
                if (instance && instance.id) {
                    data.id = instance.id;
                }
                actions.refillUpdate({ data });
            }
        });
    };

    close = () => {
        const { actions } = this.props;
        actions.refillShowEditForm({ show: false });
    };

    render() {
        const { form, refill } = this.props;
        const { showEditForm, updateStatus, editObject } = refill;
        const instance = editObject;

        const error = updateStatus.error || {};
        const errorData = error.data || {};

        const isLoading = refill.updateStatus.isLoading;

        return (
            <Modal
                visible={showEditForm}
                title="Редактирование шабона"
                onCancel={this.close}
                maskClosable={false}
                footer={[
                    <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
                    <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
                        Сохранить
                    </Button>,
                ]}
            >
                <Spin spinning={isLoading}>
                    <Form onSubmit={this.handleSubmit}>
                        {errorData.non_field_errors &&
                        <div className={styles.error}>{errorData.non_field_errors}</div>}

                        <FormItem
                            form={form}
                            instance={instance}
                            name="amount"
                            label="Сумма"
                            required={true}
                            errorData={errorData}
                        >
                            <Input size="large"/>
                        </FormItem>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}


const WrappedRefillForm = Form.create()(RefillForm);

function mapStateToProps(state) {
    return {
        refill: state.refill,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.refill), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedRefillForm);

