import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as base from "../BaseReducers";

const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.seoTemplatePageShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.seoTemplatePageLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.seoTemplatePageLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.seoTemplatePageLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.seoTemplatePageDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.seoTemplatePageDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.seoTemplatePageDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.seoTemplatePageUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.seoTemplatePageUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.seoTemplatePageUpdateError]: (state, action) => base.updateError(state, action),

    [actions.seoTemplatePageDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.seoTemplatePageDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.seoTemplatePageDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
