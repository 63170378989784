import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';

import { domainDetailLoad } from "store/api/domain";
import { DataLoadComponent } from 'components/common';


class DomainValue extends DataLoadComponent {
    apiDetailLoad = domainDetailLoad;

    render() {
        const { id, mode } = this.props;
        const { detail, loadStatus } = this.state;
        const value = (detail && detail.domain) || id;
        const asLink = mode === 'link';

        return (
            <Spin spinning={loadStatus.isLoading}>
                {asLink ?
                    <Link to={`/domain/${id}`}>{value}</Link>
                    :
                    <span>{value}</span>
                }
            </Spin>
        );
    }
}


function mapStateToProps(state) {
    return {
        auth_token: state.auth.token,
    };
}

export default connect(mapStateToProps)(DomainValue);
