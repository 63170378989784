import { loadStates, loadStatus, importResults, getUrlParams, deleteItem } from 'utils';

export const ListState = {
  list: [],
  byId: {},
  pageSize: 50,
  offset: 0,
  count: 0,
  info: null,

  filter: {
    query: null,
    params: {},
    activeFilter: {},
  },

  loadStatus: loadStatus(loadStates.notAsked),
};

export const DetailState = {
  detail: null,
  loadDetailStatus: loadStatus(loadStates.notAsked),
};

export const EditState = {
  showEditForm: false,
  editObject: null,

  updateStatus: loadStatus(loadStates.notAsked),
  deleteStatus: loadStatus(loadStates.notAsked),
};

export const listLoadRequest = (state, action) => {
  const query = (action.payload && action.payload.query) || '';
  const params = getUrlParams(query);
  const activeFilter = (action.payload && action.payload.activeFilter) || {};

  return {
    ...state,
    loadStatus: loadStatus(loadStates.loading),
    filter: {
      query: query,
      params: params,
      activeFilter: activeFilter,
    },
    offset: params['offset'] ? parseInt(params['offset']): 0,
  };
};

export const listLoadComplete = (state, action) => {
  const { list, byId } = importResults(action.payload.results);
  return {
    ...state,
    loadStatus: loadStatus(loadStates.loaded),
    list: list,
    byId: byId,
    count: action.payload.count,
    info: action.payload.info,
  };
};

export const listLoadError = (state, action) => ({
  ...state,
  loadStatus: loadStatus(loadStates.failed, action.payload),
});

export const detailLoadRequest = (state, action) => ({
  ...state,
  loadDetailStatus: loadStatus(loadStates.loading),
});

export const detailLoadComplete = (state, action) => {
  return {
    ...state,
    loadDetailStatus: loadStatus(loadStates.loaded),
    detail: action.payload,
  }
};

export const detailLoadError = (state, action) => ({
  ...state,
  loadDetailStatus: loadStatus(loadStates.failed, action.payload),
});


export const updateRequest = (state, action) => ({
  ...state,
  updateStatus: loadStatus(loadStates.loading),
});

export const updateComplete = (state, action) => {
  return {
    ...state,
    updateStatus: loadStatus(loadStates.loaded),
  }
};

export const updateError = (state, action) => ({
  ...state,
  updateStatus: loadStatus(loadStates.failed, action.payload),
});


export const deleteRequest = (state, action) => ({
  ...state,
  deleteStatus: loadStatus(loadStates.loading),
});

export const deleteComplete = (state, action) => {
  const { list, byId } = deleteItem(action.payload.id, state.list.slice(), Object.assign({}, state.byId));

  return {
    ...state,
    deleteStatus: loadStatus(loadStates.loaded),
    list: list,
    byId: byId,
  }
};

export const deleteError = (state, action) => ({
  ...state,
  deleteStatus: loadStatus(loadStates.failed, action.payload),
});


export const showEditForm = (state, action) => ({
  ...state,
  showEditForm: action.payload.show,
  editObject: action.payload.object,
});

