import axios from 'axios';
import { getHeaders } from "utils";

const METHODS = {
    'get': axios.get,
    'post': axios.post,
    'put': axios.put,
    'delete': axios.delete,
};

export const Load = (method, url, data, config) => {
    const action = METHODS[method];
    const promise = method === 'get' || method === 'delete' ? action(url, config) : action(url, data, config);
    return promise
        .then(response => {
            return { error: false, data: response.data, statusCode: response.status }
        })
        .catch(error => {
            console.log(error);
            let r = { error: true, err: error };
            if (error.response) {
                r.data = error.response.data;
                r.statusCode = error.response.status;
            }
            return r
        });
};


export const ListLoad = (listUrl, token, payload) => {
    let url = listUrl;
    if (payload.query) {
        url += payload.query;
    }
    return Load('get', url, null, {
        headers: getHeaders(token),
        params: payload.activeFilter,
    });
};

export const DetailLoad = (detailUrl, token, { id }) => {
    let url = detailUrl(id);
    return Load('get', url, null, {
        headers: getHeaders(token),
    });
};

export const Update = (listUrl, detailUrl, token, data) => {
    let url = listUrl;
    let method = 'post';
    if (data.id) {
        url = detailUrl(data.id);
        method = 'put';
    }
    return Load(method, url, data, {
        headers: getHeaders(token),
    });
};

export const Delete = (detailUrl, token, { id }) => {
    const url = detailUrl(id);
    return Load('delete', url, null, {
        headers: getHeaders(token),
    });
};
