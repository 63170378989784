import { takeEvery, select } from 'redux-saga/effects';

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/message';


export function* watch() {
  yield takeEvery(actions.MESSAGE_LOAD.START, actionMessageLoad);
  yield takeEvery(actions.MESSAGE_DETAIL_LOAD.START, actionMessageDetailLoad);
  yield takeEvery(actions.MESSAGE_UPDATE.START, actionMessageUpdate);
  yield takeEvery(actions.MESSAGE_DELETE.START, actionMessageDelete);
}

function* actionMessageLoad(action) {
  yield base.ListLoad(action, api.messageLoad,
    {
      request: actions.messageLoadRequest,
      complete: actions.messageLoadComplete,
      error: actions.messageLoadError
    },
  );
}

function* actionMessageDetailLoad(action) {
  yield base.DetailLoad(action, api.messageDetailLoad,
    {
      request: actions.messageDetailLoadRequest,
      complete: actions.messageDetailLoadComplete,
      error: actions.messageDetailLoadError
    },
  );
}

function* actionMessageUpdate(action) {
  const filter = yield select((state) => state.message.filter);
  yield base.Update(action, api.messageUpdate,
    {
      request: actions.messageUpdateRequest,
      complete: actions.messageUpdateComplete,
      error: actions.messageUpdateError,
      showEditForm: actions.messageShowEditForm,
      detailLoad: actions.messageDetailLoad,
      listLoad: actions.messageLoad,
    },
    '/message',
    filter,
    (data) => data.parent_msg || data.id
  );
}

function* actionMessageDelete(action) {
  const filter = yield select((state) => state.message.filter);
  yield base.Delete(action, api.messageDelete,
    {
      request: actions.messageDeleteRequest,
      complete: actions.messageDeleteComplete,
      error: actions.messageDeleteError,
      listLoad: actions.messageLoad,
    },
    '/message',
    filter,
  );
}
