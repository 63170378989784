import { takeEvery, select } from 'redux-saga/effects';

import * as base from 'store/BaseSagas';
import * as actions from './actions';
import * as api from 'store/api/agency';


export function* watch() {
  yield takeEvery(actions.AGENCY_LOAD.START, actionAgencyLoad);
  yield takeEvery(actions.AGENCY_DETAIL_LOAD.START, actionAgencyDetailLoad);
  yield takeEvery(actions.AGENCY_UPDATE.START, actionAgencyUpdate);
  yield takeEvery(actions.AGENCY_DELETE.START, actionAgencyDelete);
}

function* actionAgencyLoad(action) {
  yield base.ListLoad(action, api.agencyLoad,
    {
      request: actions.agencyLoadRequest,
      complete: actions.agencyLoadComplete,
      error: actions.agencyLoadError
    },
  );
}

function* actionAgencyDetailLoad(action) {
  yield base.DetailLoad(action, api.agencyDetailLoad,
    {
      request: actions.agencyDetailLoadRequest,
      complete: actions.agencyDetailLoadComplete,
      error: actions.agencyDetailLoadError
    },
  );
}

function* actionAgencyUpdate(action) {
  const filter = yield select((state) => state.agency.filter);
  yield base.Update(action, api.agencyUpdate,
    {
      request: actions.agencyUpdateRequest,
      complete: actions.agencyUpdateComplete,
      error: actions.agencyUpdateError,
      showEditForm: actions.agencyShowEditForm,
      detailLoad: actions.agencyDetailLoad,
      listLoad: actions.agencyLoad,
    },
    '/agency',
    filter,
  );
}

function* actionAgencyDelete(action) {
  const filter = yield select((state) => state.agency.filter);
  yield base.Delete(action, api.agencyDelete,
    {
      request: actions.agencyDeleteRequest,
      complete: actions.agencyDeleteComplete,
      error: actions.agencyDeleteError,
      listLoad: actions.agencyLoad,
    },
    '/agency',
    filter,
  );
}
