import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils/redux';

export const CURRENT_USER_LOAD = createRequestTypes('CURRENT_USER_LOAD');
export const USER_LOAD = createRequestTypes('USER_LOAD');
export const USER_DETAIL_LOAD = createRequestTypes('USER_DETAIL_LOAD');
export const USER_UPDATE = createRequestTypes('USER_UPDATE');
export const USER_DELETE = createRequestTypes('USER_DELETE');
export const USER_SHOW_EDIT_FORM = 'USER_SHOW_EDIT_FORM';

export const currentUserLoad = createAction(CURRENT_USER_LOAD.START);
export const currentUserLoadRequest = createAction(CURRENT_USER_LOAD.REQUEST);
export const currentUserLoadComplete = createAction(CURRENT_USER_LOAD.COMPLETE);
export const currentUserLoadError = createAction(CURRENT_USER_LOAD.ERROR);

export const userShowEditForm = createAction(USER_SHOW_EDIT_FORM);

export const userLoad = createAction(USER_LOAD.START);
export const userLoadRequest = createAction(USER_LOAD.REQUEST);
export const userLoadComplete = createAction(USER_LOAD.COMPLETE);
export const userLoadError = createAction(USER_LOAD.ERROR);

export const userDetailLoad = createAction(USER_DETAIL_LOAD.START);
export const userDetailLoadRequest = createAction(USER_DETAIL_LOAD.REQUEST);
export const userDetailLoadComplete = createAction(USER_DETAIL_LOAD.COMPLETE);
export const userDetailLoadError = createAction(USER_DETAIL_LOAD.ERROR);

export const userUpdate = createAction(USER_UPDATE.START);
export const userUpdateRequest = createAction(USER_UPDATE.REQUEST);
export const userUpdateComplete = createAction(USER_UPDATE.COMPLETE);
export const userUpdateError = createAction(USER_UPDATE.ERROR);

export const userDelete = createAction(USER_DELETE.START);
export const userDeleteRequest = createAction(USER_DELETE.REQUEST);
export const userDeleteComplete = createAction(USER_DELETE.COMPLETE);
export const userDeleteError = createAction(USER_DELETE.ERROR);
