import React, { Component } from 'react';

import { CountrySelect, CitySelect } from 'components/blocks/geo';


export default class GeoFieldSet extends Component {
    state = {
        countryId: null,
    };

    handleCountryChange = (value) => {
        this.setState({
            countryId: value,
        })
    };

    render() {
        const { form, profile, countryId, cityId } = this.props;
        const { updateStatus, editObject } = profile;
        const instance = editObject;
        const activeCountry = this.state.countryId || (instance && instance.country) || countryId;
        const activeCity = (instance && instance.city) || cityId;
        const cityActiveFilter = activeCountry ? { country: activeCountry } : null;

        const error = updateStatus.error || {};
        const errorData = error.data || {};

        return (
            <div className="form-block">
                <CountrySelect
                    form={form}
                    instance={instance}
                    name="country"
                    label="Страна"
                    required={true}
                    errorData={errorData}
                    onChange={this.handleCountryChange}
                    initialValue={activeCountry}
                    disabled={!!countryId}
                />

                <CitySelect
                    form={form}
                    instance={instance}
                    name="city"
                    label="Город"
                    required={true}
                    errorData={errorData}
                    activeFilter={cityActiveFilter}
                    initialValue={activeCity}
                    disabled={!!cityId}
                />
            </div>
        );
    }
}
