import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';

import { domainDetailLoad } from "store/api/domain";
import { DataLoadComponent } from 'components/common';
import styles from './mirrorlink.module.css';


class DomainMirrorValue extends DataLoadComponent {
    apiDetailLoad = domainDetailLoad;

    render() {
        const { id } = this.props;
        const { detail, loadStatus } = this.state;
        const mirrors = (detail && detail.mirrors) || [id];

        return (
            <Spin spinning={loadStatus.isLoading}>
                <div className={styles.block}>
                    {mirrors.map(d => (
                        <div key={`mirror${d.id}`} className={styles.item}>{d.domain}</div>
                    ))}
                </div>
            </Spin>
        )
    }
}

function mapStateToProps(state) {
    return {
        auth_token: state.auth.token,
    };
}

export default connect(mapStateToProps)(DomainMirrorValue);
