import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { ProfileList, ProfileForm } from 'components/blocks/profile';


class ProfileListScreen extends Component {
    state = {
        activeStatus: null,
    };

    showEditForm = (item = null) => {
        const { actions } = this.props;
        actions.profileShowEditForm({ show: true, object: item });
    };

    onTabChange = (key) => {
        const status = key.replace('status', '');
        this.setState({
            activeStatus: status === 'detail' ? null: status,
        });
        const url = key === 'detail' ? `/profile` : `/profile/${key}`;
        this.props.history.push(url);
    };

    render() {
        const { history, profile } = this.props;
        const info = profile.info || {};
        const { activeStatus } = this.state;

        return (
            <Page>
                <PageHeader
                    title="Анкеты"
                    actions={[
                        <Button type="primary" className="" icon="plus" onClick={() => this.showEditForm()}>
                            Добавить анкету
                        </Button>
                    ]}
                    history={history}
                    basePath={`/profile`}
                    tabPathPrefix="?status="
                    tabs={[
                        { title: 'Все', count: info['all'], key: 'detail' },
                        { title: 'Новые', count: info['0'], key: 'status0' },
                        { title: 'На проверке', count: info['1'], key: 'status1' },
                        { title: 'Проверенные', count: info['3'], key: 'status3' },
                        { title: 'Отклонённые', count: info['4'], key: 'status4' },
                        { title: 'Забаненные', count: info['5'], key: 'status5' },
                    ]}
                    onTabChange={this.onTabChange}
                />

                <div className="page-content page-content-full">
                    <div className="page-block page-block-full">
                        <ProfileList
                            basePath="/profile"
                            history={history}
                            activeFilter={activeStatus ? { status: activeStatus }: null}
                        />
                    </div>
                </div>

                <ProfileForm/>
            </Page>
        );
    }
}


function mapStateToProps(state) {
    return {
        profile: state.profile,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.profile), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProfileListScreen);
