import { takeEvery, select } from 'redux-saga/effects';

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/seo';


export function* watch() {
    yield takeEvery(actions.PAGE_LOAD.START, actionPageLoad);
    yield takeEvery(actions.PAGE_DETAIL_LOAD.START, actionPageDetailLoad);
    yield takeEvery(actions.PAGE_UPDATE.START, actionPageUpdate);
    yield takeEvery(actions.PAGE_DELETE.START, actionPageDelete);
}

function* actionPageLoad(action) {
    yield base.ListLoad(action, api.pageLoad,
      {
          request: actions.pageLoadRequest,
          complete: actions.pageLoadComplete,
          error: actions.pageLoadError
      },
    );
}

function* actionPageDetailLoad(action) {
    yield base.DetailLoad(action, api.pageDetailLoad,
      {
          request: actions.pageDetailLoadRequest,
          complete: actions.pageDetailLoadComplete,
          error: actions.pageDetailLoadError
      },
    );
}

function* actionPageUpdate(action) {
    const filter = yield select((state) => state.page.filter);
    yield base.Update(action, api.pageUpdate,
      {
          request: actions.pageUpdateRequest,
          complete: actions.pageUpdateComplete,
          error: actions.pageUpdateError,
          showEditForm: actions.pageShowEditForm,
          detailLoad: actions.pageDetailLoad,
          listLoad: actions.pageLoad,
      },
      null,
      filter,
    );
}

function* actionPageDelete(action) {
    const filter = yield select((state) => state.page.filter);
    yield base.Delete(action, api.pageDelete,
      {
          request: actions.pageDeleteRequest,
          complete: actions.pageDeleteComplete,
          error: actions.pageDeleteError,
          listLoad: actions.pageLoad,
      },
      null,
      filter,
    );
}
