import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';

import { userDetailLoad } from "store/api/user";
import { DataLoadComponent } from 'components/common';


class UserValue extends DataLoadComponent {
    apiDetailLoad = userDetailLoad;

    render() {
        const { id, mode } = this.props;
        const { detail, loadStatus } = this.state;
        const value = (detail && detail.email) || id;
        const asLink = mode === 'link';

        return (
            <Spin spinning={loadStatus.isLoading}>
                {asLink ?
                    <Link to={`/user/${id}`}>{value}</Link>
                    :
                    <span>{value}</span>
                }
            </Spin>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth_token: state.auth.token,
    };
}

export default connect(mapStateToProps)(UserValue);
