import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as base from "../BaseReducers";


const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
);

export default handleActions(
  {
    [actions.transactionLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.transactionLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.transactionLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.transactionDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.transactionDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.transactionDetailLoadError]: (state, action) => base.detailLoadError(state, action),
  },
  initialState
);
