import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Switch } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { ordering, getDateNowStr } from "utils";
import { TableList, Photo, DateTimeText, Status, ActionButton, ModerationNewValue } from 'components/common';
import { CountrySelect, CountryValue, CitySelect, CityValue } from 'components/blocks/geo';


class AgencyList extends Component {
    componentDidMount() {
        const { actions, geo_country, geo_city } = this.props;
        actions.geoCountryLoad({ query: geo_country.query, locationChange: false });
        actions.geoCityLoad({ query: geo_city.query, locationChange: false });
    }

    load = (filter) => {
        const { actions, agency } = this.props;
        let newFilter = Object.assign({}, filter);
        if (newFilter.activeFilter === undefined) {
            newFilter.activeFilter = agency.filter.activeFilter
        }
        actions.agencyLoad(newFilter);
    };

    render() {
        const { history, actions, defaultFilter, activeFilter, agency, geo_country, geo_city } = this.props;
        const params = agency.filter.params;

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 59,
            },
            {
                title: 'Фото',
                dataIndex: 'photo',
                key: 'photo',
                width: 112,
                render: (text, item) => (
                    <Link to={`/agency/${item.id}`}>
                        <Photo
                            url={item.photos.length && (item.photos[0].small || item.photos[0].url)}
                            width={80} height={60}
                        />
                    </Link>
                ),
            },
            {
                title: 'Имя',
                dataIndex: 'name_en',
                key: 'name_en',
                render: (text, item) => <div>
                    <Link to={`/agency/${item.id}`}>{text}</Link>
                    <ModerationNewValue value={item.moderation_data && item.moderation_data.name}/>
                </div>,

                sorter: true,
                sortOrder: ordering('name_en', params.ordering),
            },
            {
                title: 'Страна',
                dataIndex: 'country',
                key: 'country',
                width: 150,
                render: (val, item) => <CountryValue id={val} byId={geo_country.byId}/>,
            },
            {
                title: 'Город',
                dataIndex: 'city',
                key: 'city',
                width: 150,
                render: (val, item) => <CityValue id={val} byId={geo_city.byId}/>,
            },
            {
                title: 'Пользователь',
                dataIndex: 'user',
                key: 'user',
                width: 150,
                render: (val, item) => val,
            },
            {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                width: 120,
                render: (text, item) => <div>
                    <Status status={text}/>
                    <Switch
                        style={{ marginTop: '5px', minWidth: '62px' }}
                        checkedChildren="вкл" unCheckedChildren="выкл"
                        checked={item.is_on}
                        onClick={() => {
                            const data = { id: item.id, is_on: !item.is_on };
                            actions.agencyUpdate({ data, preventLoadDetail: true })
                        }}
                    />
                </div>,
            },
            {
                title: 'Просм.',
                dataIndex: 'views_count',
                key: 'views_count',
                width: 90,

                sorter: true,
                sortOrder: ordering('views_count', params.ordering),
            },
            {
                title: 'Активно до',
                dataIndex: 'date_active',
                key: 'date_active',
                width: 112,
                render: (text, item) => <DateTimeText date={text}/>,

                sorter: true,
                sortOrder: ordering('date_active', params.ordering),
            },
            {
                title: 'Дата поднятия',
                dataIndex: 'date_up',
                key: 'date_up',
                width: 112,
                render: (text, item) => <DateTimeText date={text}/>,

                sorter: true,
                sortOrder: ordering('date_up', params.ordering),
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                width: 30,
                align: 'right',
                render: (val, item) => {
                    return (
                        <ActionButton
                            actions={[
                                {
                                    key: 'up',
                                    icon: <Icon type="up-circle"/>,
                                    title: 'Поднять',
                                    action: () => {
                                        const data = { id: item.id, date_up: getDateNowStr() };
                                        actions.agencyUpdate({ data, preventLoadDetail: true })
                                    }
                                },
                                {
                                    key: 'edit',
                                    icon: <Icon type="edit"/>,
                                    title: 'Редактировать',
                                    action: () => actions.agencyShowEditForm({ show: true, object: item })
                                },
                                {
                                    key: 'delete',
                                    icon: <Icon type="delete"/>,
                                    title: 'Удалить',
                                    action: () => actions.agencyDelete({ id: item.id }),
                                    confirm: {
                                        title: 'Уверены, что хотите удалить?',
                                        okText: 'Удалить',
                                        cancelText: 'Отмена'
                                    }
                                },
                            ]}

                        />
                    )
                }
            }
        ];

        return (
            <TableList
                history={history}
                defaultFilter={defaultFilter}
                activeFilter={activeFilter}
                data={agency}
                columns={columns}
                onLoadData={this.load}
                plural={['агентство', 'агентства', 'агентств']}
                onRow={(item, rowIndex) => {
                    return {
                        onDoubleClick: event => {
                            this.props.history.push(`/agency/${item.id}`);
                        },
                    };
                }}

                filterFields={{
                    list: ['country', 'city'],
                    byName: {
                        'country': {
                            label: 'Страна',
                            type: 'list_int',
                            component: CountrySelect,
                            valueComponent: CountryValue,
                        },
                        'city': {
                            label: 'Город',
                            type: 'list_int',
                            component: CitySelect,
                            valueComponent: CityValue,
                        },
                    }
                }}
            />
        );
    }
}


function mapStateToProps(state) {
    return {
        agency: state.agency,
        geo_country: state.geo_country,
        geo_city: state.geo_city,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            actions.agency,
            actions.geo_country,
            actions.geo_city,
        ), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyList);
