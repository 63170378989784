import { handleActions } from 'redux-actions';
import * as base from 'store/BaseReducers';

import * as actions from './actions';

const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.agencyShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.agencyLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.agencyLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.agencyLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.agencyDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.agencyDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.agencyDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.agencyUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.agencyUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.agencyUpdateError]: (state, action) => base.updateError(state, action),

    [actions.agencyDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.agencyDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.agencyDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
