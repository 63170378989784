import { BASE_URL } from "config";
import * as baseApi from "./_base";

export const endpoints = {
    list: `${BASE_URL}/api/v1/billing/refill/`,
    detail: (id) => `${BASE_URL}/api/v1/billing/refill/${id}/`,
};

export const refillLoad = (token, payload) => baseApi.ListLoad(endpoints.list, token, payload);
export const refillDetailLoad = (token, { id }) => baseApi.DetailLoad(endpoints.detail, token, { id });
export const refillUpdate = (token, data) => baseApi.Update(endpoints.list, endpoints.detail, token, data);
