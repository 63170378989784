import querystring from 'querystring';
import { matchPath } from "react-router";

export function getObject(state, activeId) {
    try {
        return state.byId[activeId];
    } catch (err) {
        console.log(err);
    }
    return null;
}


export function getObjectList(state) {
    return state.list.map(id => state.byId[id]);
}


export function getFullName(obj) {
    let name = obj.last_name || "";
    if (obj.first_name) name += ` ${obj.first_name}`;
    if (obj.middle_name) name += ` ${obj.middle_name}`;
    return name;
}

export function getFullNameOrEmail(obj) {
    let name = obj.last_name || "";
    if (obj.first_name) name += ` ${obj.first_name}`;
    if (obj.middle_name) name += ` ${obj.middle_name}`;
    name = name.trim();
    if (!name) name = `${obj.email}`;
    return name;
}


export function getShortName(obj) {
    if (!obj) return null;
    let name = obj.last_name;
    if (obj.first_name) name += ` ${obj.first_name[0]}.`;
    if (obj.middle_name) name += ` ${obj.middle_name[0]}.`;
    return name;
}

export function getNoun(number, one, two, five) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
}

export function getToken() {
    if ('localStorage' in window) {
        try {
            if (window.localStorage !== null) {
                return window.localStorage.getItem('token');
            }
        } catch (e) {
        }
    }
    return null;
}

export function setToken(token) {
    if ('localStorage' in window) {
        try {
            if (window.localStorage !== null) {
                return window.localStorage.setItem('token', token);
            }
        } catch (e) {
        }
    }
    return null;
}

export function removeToken() {
    if ('localStorage' in window) {
        try {
            if (window.localStorage !== null) {
                return window.localStorage.removeItem('token');
            }
        } catch (e) {
        }
    }
    return null;
}

export function dictIsEmpty(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function isDict(v) {
    return typeof v === 'object' && v !== null && !(v instanceof Array) && !(v instanceof Date);
}

export function getUrlParams(search) {
    const params = querystring.parse(search.replace('?', ''));
    return Object.keys(params).reduce(function (result, key) {
        let values = params[key];
        let newValues = values.slice();
        if (!Array.isArray(values)) {
            values = [values];
        }

        if (values[0].indexOf('(') > -1) {
            newValues = values.reduce((result2, value) => {
                const parts = value.split('(');
                let values2 = result2[parts[0]] || [];
                values2.push(parts[1].replace(')', ''));
                result2[parts[0]] = values2;
                return result2;
            }, {});
        }

        result[key] = newValues;
        return result
    }, {});
}

export function getUrlSearch(params) {
    if (!params) return '';
    let newParams = [];
    Object.keys(params).forEach(function (key) {
        let value = params[key];
        if (value) {
            if (isDict(value)) {
                Object.keys(value).forEach(k => {
                    let v = value[k];
                    if (v) {
                        if (Array.isArray(v)) {
                            v.forEach(v2 => {
                                v2 && newParams.push(`${key}=${k}(${v2})`);
                            })
                        } else {
                            newParams.push(`${key}=${k}(${v})`);
                        }
                    }
                });
            } else {
                newParams.push(`${key}=${value}`);
            }
        }
    });
    return newParams.join('&');
}

export function cls(classNames) {
    return classNames.join(' ');
}

export function getBreadcrumbs({ routes, location }) {
    const matches = [
        {
            path: '/',
            title: () => '',
            icon: 'home',
            match: {},
        }
    ];
    const { pathname } = location;

    pathname
        .replace(/\/$/, '')
        .split('/')
        .reduce((previous, current) => {
            const pathSection = `${previous}/${current}`;

            let match, route;
            for (let i = 0; i < routes.length; i++) {
                const { matchOptions, path } = routes[i];
                match = matchPath(pathSection, { ...(matchOptions || { exact: true }), path });
                if (match) {
                    route = routes[i];
                    break;
                }
            }

            if (match) {
                matches.push({
                    path: pathSection,
                    title: route.title,
                    detail: route.detail,
                    icon: route.icon,
                    match: match,
                });
            }

            return pathSection;
        });

    return matches;
}


export function ordering(field, ordering) {
    if (ordering === field) return 'ascend';
    if (ordering === `-${field}`) return 'descend';
    return false;
}


export function filtering(field, params) {
    const value = params[field];
    if (value) return [value];
    return null;
}


export function dictIsEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}


export function arrayIsEqual(array1, array2) {
    // if the other array is a falsy value, return
    if (!array2)
        return false;

    // compare lengths - can save a lot of time
    if (array1.length !== array2.length)
        return false;

    for (let i = 0, l = array1.length; i < l; i++) {
        if (array1[i].id) {
            if (array1[i].id !== array2[i].id) {
                return false;
            }
        } else {
            if (array1[i] !== array2[i]) {
                return false;
            }
        }
    }
    return true;
}


export function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}
