import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { CityList, CityForm } from 'components/blocks/geo';


class CountryCityListScreen extends Component {
    showCityForm = (item = null) => {
        const { actions } = this.props;
        actions.geoCityShowEditForm({ show: true, object: item });
    };

    render() {
        const { history, match } = this.props;
        const countryId = parseInt(match.params.id);

        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <div className="page-block page-block-full">
                        <CityList
                            basePath={`/country/${countryId}`}
                            history={history}
                            activeFilter={{ country: countryId }}
                            topActions={[
                                <Button type="primary" className="" icon="plus" onClick={() => this.showCityForm()}>
                                    Добавить город
                                </Button>
                            ]}
                        />
                    </div>
                </div>

                <CityForm country_id={countryId}/>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        geo_city: state.geo_city,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.geo_city), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CountryCityListScreen);
