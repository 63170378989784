import React, { Component } from 'react';
import { Input } from 'antd';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { getUrlSearch, ordering } from "utils";
import { TableList } from 'components/common';


class BalanceList extends Component {
    state = {
        query: null,
        filters: [],
        searchQuery: '',
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { balance } = nextProps;
        const { query, params } = balance;
        if (query === prevState.query) {
            return null;
        }

        return {
            query: nextProps.balance.query,
            searchQuery: params.search || '',
        }
    }

    load = ({ query }) => {
        const { actions } = this.props;
        actions.balanceLoad({ query });
    };

    search = (value) => {
        const { balance, history } = this.props;
        let params = Object.assign({}, balance.params);
        params.search = value;
        const query = '?' + getUrlSearch(params);
        this.load({ query });
        history.push(history.location.pathname + query);
    };

    searchChange = (e) => {
        this.setState({
            searchQuery: e.target.value,
        });
    };

    render() {
        const { balance, history } = this.props;
        const { searchQuery } = this.state;
        const { params } = balance;

        const columns = [
            {
                title: 'Пользователь',
                dataIndex: 'user__email',
                key: 'user__email',
                render: (text, item) => <Link to={`/balance/${item.id}`}>{item.user.email}</Link>,
            },
            {
                title: 'Баланс',
                dataIndex: 'balance',
                key: 'balance',
                render: (text, item) => <span>{text} €</span>,

                sorter: true,
                sortOrder: ordering('balance', params.ordering),
            },
            {
                title: 'Вести учёт',
                dataIndex: 'is_charging',
                key: 'is_charging',
                render: (text, item) => <span>{text ? 'Да': 'Нет'}</span>,

                sorter: true,
                sortOrder: ordering('is_charging', params.ordering),
            }
        ];

        return (
            <div>
                <div className="page-block-header">
                    <div className="page-block-header-start">
                        <div className="page-block-header-search">
                            <Input.Search
                                placeholder="Поиск"
                                value={searchQuery}
                                //allowClear={true}
                                onSearch={this.search}
                                onChange={this.searchChange}
                            />
                        </div>
                    </div>
                </div>

                <TableList
                    history={history}
                    data={balance}
                    columns={columns}
                    onLoadData={this.load}
                    plural={['баланс', 'баланса', 'балансов']}
                />
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        balance: state.balance,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.balance), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceList);


