import React, { PureComponent } from 'react';

import { cls } from 'utils';
import styles from './status.module.css';

export const STATUS = {
    0: 'новая',
    1: 'на проверке',
    3: 'проверена',
    4: 'отклонена',
    5: 'забанена',
};

export default class Status extends PureComponent {
    render() {
        const { status } = this.props;

        const classNames = [styles.status, styles[`s${status}`]];

        return (
            <div className={cls(classNames)}>{STATUS[status] || status}</div>
        );
    }
}
