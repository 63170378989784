import React, { Component } from 'react';
import { Col, Row, Input, Select } from "antd";

import { FormItem } from 'utils';
import * as choices from 'utils/choices';


export default class ParametersFieldSet extends Component {
    render() {
        const { form, profile } = this.props;
        const { updateStatus, editObject } = profile;
        const instance = editObject;

        const error = updateStatus.error || {};
        const errorData = error.data || {};

        return (
            <div className="form-block">
                <Row gutter={16}>
                    <Col span={6}>
                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.gender"
                            label="Пол"
                            errorData={errorData}
                        >
                            <Select style={{ width: '100%' }} placeholder="Выберите">
                                {choices.GENDER.map(item =>
                                    <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
                                )}
                            </Select>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.meeting"
                            label="Встречается"
                            errorData={errorData}
                        >
                            <Select style={{ width: '100%' }} placeholder="Выберите" mode="multiple">
                                {choices.MEETING_WITH.map(item =>
                                    <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
                                )}
                            </Select>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.breast_size"
                            label="Размер груди"
                            errorData={errorData}
                        >
                            <Select style={{ width: '100%' }} placeholder="Выберите">
                                {choices.BREAST_SIZE.map(item =>
                                    <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
                                )}
                            </Select>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.travel"
                            label="Путешествия"
                            errorData={errorData}
                        >
                            <Select style={{ width: '100%' }} placeholder="Выберите">
                                {choices.TRAVEL.map(item =>
                                    <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
                                )}
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.nationality"
                            label="Национальность"
                            errorData={errorData}
                        >
                            <Select style={{ width: '100%' }} placeholder="Выберите">
                                {choices.NATIONALITY.map(item =>
                                    <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
                                )}
                            </Select>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.age"
                            label="Возраст"
                            required={false}
                            errorData={errorData}
                        >
                            <Input size="large" style={{ width: '100%' }}/>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.breast_type"
                            label="Тип груди"
                            errorData={errorData}
                        >
                            <Select style={{ width: '100%' }} placeholder="Выберите">
                                {choices.BREAST_TYPE.map(item =>
                                    <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
                                )}
                            </Select>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.languages"
                            label="Языки"
                            errorData={errorData}
                        >
                            <Select style={{ width: '100%' }} placeholder="Выберите" mode="multiple">
                                {choices.LANGUAGES.map(item =>
                                    <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
                                )}
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.ethnicity"
                            label="Тип внешности"
                            errorData={errorData}
                        >
                            <Select style={{ width: '100%' }} placeholder="Выберите">
                                {choices.ETHNICITY.map(item =>
                                    <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
                                )}
                            </Select>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.height"
                            label="Рост"
                            required={false}
                            errorData={errorData}
                        >
                            <Input size="large" style={{ width: '100%' }}/>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.hair_color"
                            label="Цвет волос"
                            errorData={errorData}
                        >
                            <Select style={{ width: '100%' }} placeholder="Выберите">
                                {choices.HAIR_COLOR.map(item =>
                                    <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
                                )}
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={6}>
                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.orientation"
                            label="Ориентация"
                            errorData={errorData}
                        >
                            <Select style={{ width: '100%' }} placeholder="Выберите">
                                {choices.SEXUAL_ORIENTATION.map(item =>
                                    <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
                                )}
                            </Select>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.weight"
                            label="Вес"
                            required={false}
                            errorData={errorData}
                        >
                            <Input size="large" style={{ width: '100%' }}/>
                        </FormItem>

                        <FormItem
                            form={form}
                            instance={instance}
                            name="parameters.eye_color"
                            label="Цвет глаз"
                            errorData={errorData}
                        >
                            <Select style={{ width: '100%' }} placeholder="Выберите">
                                {choices.EYE_COLOR.map(item =>
                                    <Select.Option key={item[0]} value={item[0]}>{item[1]}</Select.Option>
                                )}
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
            </div>
        );
    }
}
