import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as base from "../BaseReducers";

const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.refillShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.refillLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.refillLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.refillLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.refillDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.refillDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.refillDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.refillUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.refillUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.refillUpdateError]: (state, action) => base.updateError(state, action),
  },
  initialState
);
