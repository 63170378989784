import { handleActions } from 'redux-actions';
import { loadStates, loadStatus } from 'utils';

import * as actions from './actions';
import * as base from "../BaseReducers";

const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.domainShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.domainLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.domainLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.domainLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.domainDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.domainDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.domainDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.domainUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.domainUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.domainUpdateError]: (state, action) => base.updateError(state, action),

    [actions.domainDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.domainDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.domainDeleteError]: (state, action) => base.deleteError(state, action),

    [actions.domainMakeMainRequest]: (state, action) => ({
      ...state,
      loadStatus: loadStatus(loadStates.loading),
    }),
    [actions.domainMakeMainComplete]: (state, action) => {
      return {
        ...state,
        loadStatus: loadStatus(loadStates.loaded),
      }
    },
    [actions.domainMakeMainError]: (state, action) => ({
      ...state,
      loadStatus: loadStatus(loadStates.failed, action.payload),
    }),
  },
  initialState
);
