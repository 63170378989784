import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const GEO_CITY_LOAD = createRequestTypes('GEO_CITY_LOAD');
export const GEO_CITY_DETAIL_LOAD = createRequestTypes('GEO_CITY_DETAIL_LOAD');
export const GEO_CITY_UPDATE = createRequestTypes('GEO_CITY_UPDATE');
export const GEO_CITY_DELETE = createRequestTypes('GEO_CITY_DELETE');
export const GEO_CITY_SHOW_EDIT_FORM = 'GEO_CITY_SHOW_EDIT_FORM';

export const geoCityShowEditForm = createAction(GEO_CITY_SHOW_EDIT_FORM);

export const geoCityLoad = createAction(GEO_CITY_LOAD.START);
export const geoCityLoadRequest = createAction(GEO_CITY_LOAD.REQUEST);
export const geoCityLoadComplete = createAction(GEO_CITY_LOAD.COMPLETE);
export const geoCityLoadError = createAction(GEO_CITY_LOAD.ERROR);

export const geoCityDetailLoad = createAction(GEO_CITY_DETAIL_LOAD.START);
export const geoCityDetailLoadRequest = createAction(GEO_CITY_DETAIL_LOAD.REQUEST);
export const geoCityDetailLoadComplete = createAction(GEO_CITY_DETAIL_LOAD.COMPLETE);
export const geoCityDetailLoadError = createAction(GEO_CITY_DETAIL_LOAD.ERROR);

export const geoCityUpdate = createAction(GEO_CITY_UPDATE.START);
export const geoCityUpdateRequest = createAction(GEO_CITY_UPDATE.REQUEST);
export const geoCityUpdateComplete = createAction(GEO_CITY_UPDATE.COMPLETE);
export const geoCityUpdateError = createAction(GEO_CITY_UPDATE.ERROR);

export const geoCityDelete = createAction(GEO_CITY_DELETE.START);
export const geoCityDeleteRequest = createAction(GEO_CITY_DELETE.REQUEST);
export const geoCityDeleteComplete = createAction(GEO_CITY_DELETE.COMPLETE);
export const geoCityDeleteError = createAction(GEO_CITY_DELETE.ERROR);
