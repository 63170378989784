import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { ReviewList, ReviewForm } from 'components/blocks/review';


class ReviewListScreen extends Component {
    showEditForm = (item = null) => {
        const { actions } = this.props;
        actions.reviewShowEditForm({ show: true, object: item });
    };

    render() {
        const { history } = this.props;

        return (
            <Page>
                <PageHeader
                    title="Отзывы"
                    actions={[
                        <Button type="primary" className="" icon="plus" onClick={() => this.showEditForm()}>
                            Добавить отзыв
                        </Button>
                    ]}
                    history={history}
                    basePath={`/review`}
                    onTabChange={this.onTabChange}
                />

                <div className="page-content page-content-full">
                    <div className="page-block page-block-full">
                        <ReviewList
                            basePath="/review"
                            history={history}
                        />
                    </div>
                </div>

                <ReviewForm/>
            </Page>
        );
    }
}


function mapStateToProps(state) {
    return {
        review: state.review,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.review), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReviewListScreen);
