import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const GEO_COUNTRY_LOAD = createRequestTypes('GEO_COUNTRY_LOAD');
export const GEO_COUNTRY_DETAIL_LOAD = createRequestTypes('GEO_COUNTRY_DETAIL_LOAD');
export const GEO_COUNTRY_UPDATE = createRequestTypes('GEO_COUNTRY_UPDATE');
export const GEO_COUNTRY_DELETE = createRequestTypes('GEO_COUNTRY_DELETE');
export const GEO_COUNTRY_SHOW_EDIT_FORM = 'GEO_COUNTRY_SHOW_EDIT_FORM';

export const geoCountryShowEditForm = createAction(GEO_COUNTRY_SHOW_EDIT_FORM);

export const geoCountryLoad = createAction(GEO_COUNTRY_LOAD.START);
export const geoCountryLoadRequest = createAction(GEO_COUNTRY_LOAD.REQUEST);
export const geoCountryLoadComplete = createAction(GEO_COUNTRY_LOAD.COMPLETE);
export const geoCountryLoadError = createAction(GEO_COUNTRY_LOAD.ERROR);

export const geoCountryDetailLoad = createAction(GEO_COUNTRY_DETAIL_LOAD.START);
export const geoCountryDetailLoadRequest = createAction(GEO_COUNTRY_DETAIL_LOAD.REQUEST);
export const geoCountryDetailLoadComplete = createAction(GEO_COUNTRY_DETAIL_LOAD.COMPLETE);
export const geoCountryDetailLoadError = createAction(GEO_COUNTRY_DETAIL_LOAD.ERROR);

export const geoCountryUpdate = createAction(GEO_COUNTRY_UPDATE.START);
export const geoCountryUpdateRequest = createAction(GEO_COUNTRY_UPDATE.REQUEST);
export const geoCountryUpdateComplete = createAction(GEO_COUNTRY_UPDATE.COMPLETE);
export const geoCountryUpdateError = createAction(GEO_COUNTRY_UPDATE.ERROR);

export const geoCountryDelete = createAction(GEO_COUNTRY_DELETE.START);
export const geoCountryDeleteRequest = createAction(GEO_COUNTRY_DELETE.REQUEST);
export const geoCountryDeleteComplete = createAction(GEO_COUNTRY_DELETE.COMPLETE);
export const geoCountryDeleteError = createAction(GEO_COUNTRY_DELETE.ERROR);
