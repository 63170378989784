import React, { Component, Fragment } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import styles from './detail.module.css';


class BalanceDetailScreen extends Component {
    render() {
        const { balance } = this.props;
        const detail = balance.detail || {};
        const user = detail.user || {};

        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <div className="page-block">

                        <Row className={styles.row}>
                            <Col span={5}>
                                <span className={styles.label}>Пользователь:</span>
                            </Col>
                            <Col span={19}>{user.email}</Col>
                        </Row>

                        <Row className={styles.row}>
                            <Col span={5}>
                                <span className={styles.label}>Баланс:</span>
                            </Col>
                            <Col span={19}>{detail.balance}</Col>
                        </Row>

                        <Row className={styles.row}>
                            <Col span={5}>
                                <span className={styles.label}>Вести учёт:</span>
                            </Col>
                            <Col span={19}>{detail.is_charging ? 'Да': 'Нет'}</Col>
                        </Row>
                    </div>
                </div>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        balance: state.balance,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.balance), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(BalanceDetailScreen);
