import React, { Component } from 'react';
import { Icon } from "antd";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { ordering } from "utils";
import { TableList, ActionButton } from "components/common";


class CityList extends Component {
    load = (filter) => {
        const { actions, geo_city } = this.props;
        let newFilter = Object.assign({}, filter);
        if (newFilter.activeFilter === undefined) {
            newFilter.activeFilter =  geo_city.filter.activeFilter
        }
        actions.geoCityLoad(newFilter);
    };

    render() {
        const { history, actions, defaultFilter, activeFilter, topActions, geo_city } = this.props;
        const params = geo_city.filter.params;

        const columns = [
            {
                title: 'Название',
                dataIndex: 'name_en',
                key: 'name_en',

                sorter: true,
                sortOrder: ordering('name_en', params.ordering),
            },
            {
                title: 'Путь',
                dataIndex: 'slug',
                key: 'slug',
            },
            {
                title: 'Кол-во анкет',
                dataIndex: 'profile_count',
                key: 'profile_count',
                width: 150,
            },
            {
                title: 'Действия',
                dataIndex: 'actions',
                key: 'actions',
                width: 130,
                align: 'right',
                render: (val, item) => {
                    return (
                        <ActionButton
                            actions={[
                                {
                                    key: 'edit',
                                    icon: <Icon type="edit"/>,
                                    title: 'Редактировать',
                                    action: () => actions.geoCityShowEditForm({ show: true, object: item }),
                                },
                                {
                                    key: 'delete',
                                    icon: <Icon type="delete"/>,
                                    title: 'Удалить',
                                    action: () => actions.geoCityDelete({ id: item.id }),
                                    confirm: {
                                        title: 'Уверены, что хотите удалить?',
                                        okText: 'Удалить',
                                        cancelText: 'Отмена'
                                    }
                                },
                            ]}

                        />
                    )
                }
            }
        ];

        return (
            <TableList
                history={history}
                defaultFilter={defaultFilter}
                activeFilter={activeFilter}
                topActions={topActions}
                data={geo_city}
                columns={columns}
                onLoadData={this.load}
                plural={['город', 'города', 'городов']}
            />
        );
    }
}


function mapStateToProps(state) {
    return {
        geo_city: state.geo_city,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.geo_city), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CityList);
