import { Component } from 'react';

import { dictIsEqual, loadStates, loadStatus } from "utils";


export default class DataLoadComponent extends Component {
    apiListLoad = null;
    apiDetailLoad = null;

    state = {
        items: [],
        detail: null,
        loadStatus: loadStatus(loadStates.notAsked),
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            !dictIsEqual(prevProps.activeFilter, this.props.activeFilter) ||
            prevProps.id !== this.props.id ||
            !dictIsEqual(prevProps.byId, this.props.byId)
        ) {
            this.load();
        }
    }

    load = () => {
        const { auth_token, activeFilter, id, byId } = this.props;

        if (id && byId) {
            this.setState({
                detail: byId[id],
                loadStatus: loadStatus(loadStates.loaded),
            });

        } else {
            if (this.apiListLoad) {
                this.setState({
                    loadStatus: loadStatus(loadStates.loading),
                });

                this.apiListLoad(auth_token, { activeFilter: activeFilter })
                    .then(({ error, data }) => {
                        if (error) {
                            console.log(error);
                            this.setState({
                                loadStatus: loadStatus(loadStates.failed, error),
                            });
                        } else {
                            this.setState({
                                items: data.results,
                                loadStatus: loadStatus(loadStates.loaded),
                            });
                        }
                    })
            }

            if (id && this.apiDetailLoad) {
                this.setState({
                    loadStatus: loadStatus(loadStates.loading),
                });

                this.apiDetailLoad(auth_token, { id })
                    .then(({ error, data }) => {
                        if (error) {
                            console.log(error);
                            this.setState({
                                loadStatus: loadStatus(loadStates.failed, error),
                            });
                        } else {
                            this.setState({
                                detail: data,
                                loadStatus: loadStatus(loadStates.loaded),
                            });
                        }
                    })
            }
        }
    };
}
