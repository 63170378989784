import React from 'react';
import { Form, Input, Tabs } from 'antd';
import { LANGUAGES, LANGUAGE_CODE } from 'config';
import { isFunction } from 'utils';


function getValidateStatus(fieldName, errorData) {
    if (fieldName in errorData) {
        return {
            validateStatus: "error",
            help: errorData[fieldName]
        }
    }
    return {};
}


export const FormItem = props => {
    const { form, name, label, required, errorData, instance, initialValue, valuePropName, className, extra, convertValue } = props;
    const { getFieldDecorator } = form;

    let value;
    if (instance) {
        value = instance;
        name.split('.').forEach(n => {
            value = value && value[n];
        });

        if (convertValue) {
            value = convertValue(value);
        }
    }

    let params = {
        rules: [{ required: required, message: 'Обязательное поле' }],
        initialValue: value || initialValue
    };
    if (valuePropName) {
        params.valuePropName = valuePropName;
    }

    return (
        <Form.Item
            className={className}
            {...getValidateStatus(name, errorData || {})}
            label={label}
            extra={extra}
        >
            {getFieldDecorator(name, params)(
                props.children
            )}
        </Form.Item>
    )
};


export const TranslationFormItem = props => {
    const { name, required, inputType, addonAfter, label, ...otherProps } = props;

    return (
        <Tabs className="tabs" type="card" tabPosition="bottom">
            {LANGUAGES.map(language => {
                const lang = language[0];
                const isRequired = (LANGUAGE_CODE === lang) && required;
                const fieldName = `${name}_${lang}`;
                const fieldLabel = isFunction(label) ? label(fieldName) : label;
                return (
                    <Tabs.TabPane tab={lang} key={lang}>
                        <FormItem name={fieldName} required={isRequired} label={fieldLabel} {...otherProps}>
                            {inputType === 'textarea' ?
                                <Input.TextArea size="large" autosize={{ minRows: 3 }}/>
                                :
                                <Input size="large" addonAfter={addonAfter && addonAfter(fieldName)}/>
                            }
                        </FormItem>
                    </Tabs.TabPane>
                )
            })}
        </Tabs>
    )
};