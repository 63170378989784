import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';

import { domainLoad } from "store/api/domain";
import { FormItem } from "utils";
import { DataLoadComponent } from 'components/common';


class DomainSelect extends DataLoadComponent {
    apiListLoad = domainLoad;

    render() {
        const { form, instance, name, label, required, errorData, onChange } = this.props;
        const { items, loadStatus } = this.state;
        const initialValue = instance && instance.id ? null : this.props.initialValue

        return (
            <FormItem
                form={form}
                instance={instance}
                name={name}
                label={label}
                required={required}
                errorData={errorData}
                initialValue={initialValue}
            >
                <Select
                    style={{ width: 200 }}
                    loading={loadStatus.isLoading}
                    onChange={onChange}
                    showSearch={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {!required && <Select.Option value={null}>---</Select.Option>}
                    {items.map(item => (
                        <Select.Option key={`domain${item.id}`} value={item.id}>{item.domain}</Select.Option>
                    ))}
                </Select>
            </FormItem>
        );
    }
}


function mapStateToProps(state) {
    return {
        auth_token: state.auth.token,
    };
}

export default connect(mapStateToProps)(DomainSelect);
