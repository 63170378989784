import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from "antd";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { ActionButton, TableList } from "components/common";
import { CountryValue, CityValue } from 'components/blocks/geo';
import { DomainValue, DomainMirrorValue } from 'components/blocks/domain';


class SiteList extends Component {
    componentDidMount() {
        const { actions, geo_country, geo_city, domain } = this.props;
        actions.domainLoad({ query: domain.query });
        actions.geoCountryLoad({ query: geo_country.query });
        actions.geoCityLoad({ query: geo_city.query });
    }

    load = (filter) => {
        const { actions, site } = this.props;
        let newFilter = Object.assign({}, filter);
        if (newFilter.activeFilter === undefined) {
            newFilter.activeFilter = site.filter.activeFilter
        }
        actions.siteLoad(newFilter);
    };

    render() {
        const { history, actions, defaultFilter, activeFilter, site, domain, geo_country, geo_city } = this.props;
        //const params = site.filter.params;

        const columns = [
            {
                title: 'Домен',
                dataIndex: 'domain',
                key: 'domain',
                width: 200,
                render: (val, item) =>
                    <Link to={`/site/${item.id}`}>
                        <DomainValue id={val} byId={domain.byId}/>
                    </Link>,
            },
            {
                title: 'Зеркала',
                dataIndex: 'mirrors',
                key: 'mirrors',
                width: 200,
                render: (val, item) => <DomainMirrorValue id={item.domain} byId={domain.byId}/>,
            },
            {
                title: 'Страна',
                dataIndex: 'country',
                key: 'country',
                width: 150,
                render: (val, item) => <CountryValue id={val} byId={geo_country.byId}/>,
            },
            {
                title: 'Город',
                dataIndex: 'city',
                key: 'city',
                width: 150,
                render: (val, item) => <CityValue id={val} byId={geo_city.byId}/>,
            },
            {
                title: 'Действия',
                dataIndex: 'actions',
                key: 'actions',
                width: 130,
                align: 'right',
                render: (val, item) => {
                    return (
                        <ActionButton
                            actions={[
                                {
                                    key: 'edit',
                                    icon: <Icon type="edit"/>,
                                    title: 'Редактировать',
                                    action: () => actions.siteShowEditForm({ show: true, object: item }),
                                },
                                {
                                    key: 'delete',
                                    icon: <Icon type="delete"/>,
                                    title: 'Удалить',
                                    action: () => actions.siteDelete({ id: item.id }),
                                    confirm: {
                                        title: 'Уверены, что хотите удалить?',
                                        okText: 'Удалить',
                                        cancelText: 'Отмена'
                                    }
                                },
                            ]}

                        />
                    )
                }
            }
        ];

        return (
            <TableList
                history={history}
                defaultFilter={defaultFilter}
                activeFilter={activeFilter}
                data={site}
                columns={columns}
                onLoadData={this.load}
                plural={['сайт', 'сайта', 'сайтов']}
                onRow={(item, rowIndex) => {
                    return {
                        onDoubleClick: event => {
                            this.props.history.push(`/country/${item.id}`);
                        },
                    };
                }}
            />
        );
    }
}


function mapStateToProps(state) {
    return {
        site: state.site,
        geo_country: state.geo_country,
        geo_city: state.geo_city,
        domain: state.domain,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            Object.assign({},
                actions.site,
                actions.geo_country,
                actions.geo_city,
                actions.domain
            ),
            dispatch
        ),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteList);
