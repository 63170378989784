import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as base from "../BaseReducers";

const initialState = Object.assign({},
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.geoCountryShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.geoCountryLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.geoCountryLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.geoCountryLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.geoCountryDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.geoCountryDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.geoCountryDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.geoCountryUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.geoCountryUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.geoCountryUpdateError]: (state, action) => base.updateError(state, action),

    [actions.geoCountryDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.geoCountryDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.geoCountryDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
