import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const BALANCE_LOAD = createRequestTypes('BALANCE_LOAD');
export const BALANCE_DETAIL_LOAD = createRequestTypes('BALANCE_DETAIL_LOAD');

export const balanceLoad = createAction(BALANCE_LOAD.START);
export const balanceLoadRequest = createAction(BALANCE_LOAD.REQUEST);
export const balanceLoadComplete = createAction(BALANCE_LOAD.COMPLETE);
export const balanceLoadError = createAction(BALANCE_LOAD.ERROR);

export const balanceDetailLoad = createAction(BALANCE_DETAIL_LOAD.START);
export const balanceDetailLoadRequest = createAction(BALANCE_DETAIL_LOAD.REQUEST);
export const balanceDetailLoadComplete = createAction(BALANCE_DETAIL_LOAD.COMPLETE);
export const balanceDetailLoadError = createAction(BALANCE_DETAIL_LOAD.ERROR);
