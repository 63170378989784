import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { DomainList, DomainForm } from 'components/blocks/domain';


class DomainListScreen extends Component {
    showForm = (item = null) => {
        const { actions } = this.props;
        actions.domainShowEditForm({ show: true, object: item });
    };

    render() {
        const { site, history, match } = this.props;
        const siteId = parseInt(match.params.id);
        const detail = site.detail;

        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <div className="page-block page-block-full">
                        {detail &&
                        <DomainList
                            basePath={`/site/${siteId}`}
                            history={history}
                            activeFilter={{ id: detail.domain }}
                            topActions={[
                                <Button type="primary" className="" icon="plus" onClick={() => this.showForm()}>
                                    Добавить домен
                                </Button>
                            ]}
                        />}
                    </div>
                </div>

                {detail &&
                <DomainForm parent_id={detail.domain}/>
                }
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        site: state.site,
        domain: state.domain,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.domain), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DomainListScreen);
