import React, { PureComponent } from 'react';

import { cls } from 'utils/helpers';
import styles from './loader.module.css';


export default class Loader extends PureComponent {
    render() {
        if (!this.props.active) return null;
        const { fixed, color } = this.props;

        let mainClasses = [styles.main];
        if (fixed) mainClasses.push(styles.mainFixed);
        if (color && styles[`color_${color}`]) mainClasses.push(styles[`color_${color}`]);

        return (
            <div className={cls(mainClasses)}>
                <div className={styles.spinner}>
                    <div className={styles.bounce1}/>
                    <div className={styles.bounce2}/>
                    <div className={styles.bounce3}/>
                </div>
            </div>
        );
    }
}
