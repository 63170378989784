import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const SERVICE_GROUP_LOAD = createRequestTypes('SERVICE_GROUP_LOAD');
export const SERVICE_GROUP_DETAIL_LOAD = createRequestTypes('SERVICE_GROUP_DETAIL_LOAD');
export const SERVICE_GROUP_UPDATE = createRequestTypes('SERVICE_GROUP_UPDATE');
export const SERVICE_GROUP_DELETE = createRequestTypes('SERVICE_GROUP_DELETE');
export const SERVICE_GROUP_SHOW_EDIT_FORM = 'SERVICE_GROUP_SHOW_EDIT_FORM';

export const serviceGroupShowEditForm = createAction(SERVICE_GROUP_SHOW_EDIT_FORM);

export const serviceGroupLoad = createAction(SERVICE_GROUP_LOAD.START);
export const serviceGroupLoadRequest = createAction(SERVICE_GROUP_LOAD.REQUEST);
export const serviceGroupLoadComplete = createAction(SERVICE_GROUP_LOAD.COMPLETE);
export const serviceGroupLoadError = createAction(SERVICE_GROUP_LOAD.ERROR);

export const serviceGroupDetailLoad = createAction(SERVICE_GROUP_DETAIL_LOAD.START);
export const serviceGroupDetailLoadRequest = createAction(SERVICE_GROUP_DETAIL_LOAD.REQUEST);
export const serviceGroupDetailLoadComplete = createAction(SERVICE_GROUP_DETAIL_LOAD.COMPLETE);
export const serviceGroupDetailLoadError = createAction(SERVICE_GROUP_DETAIL_LOAD.ERROR);

export const serviceGroupUpdate = createAction(SERVICE_GROUP_UPDATE.START);
export const serviceGroupUpdateRequest = createAction(SERVICE_GROUP_UPDATE.REQUEST);
export const serviceGroupUpdateComplete = createAction(SERVICE_GROUP_UPDATE.COMPLETE);
export const serviceGroupUpdateError = createAction(SERVICE_GROUP_UPDATE.ERROR);

export const serviceGroupDelete = createAction(SERVICE_GROUP_DELETE.START);
export const serviceGroupDeleteRequest = createAction(SERVICE_GROUP_DELETE.REQUEST);
export const serviceGroupDeleteComplete = createAction(SERVICE_GROUP_DELETE.COMPLETE);
export const serviceGroupDeleteError = createAction(SERVICE_GROUP_DELETE.ERROR);
