import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { actions } from "./store";
import LoginScreen from 'screens/LoginScreen';
import AppInitScreen from 'screens/AppInitScreen';
import MainScreen from 'screens/MainScreen';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        rest.isAuthenticated === true
            ? <Component {...props} />
            : <Redirect to={{
                pathname: `/login`,
                state: { from: props.location }
            }}/>
    )}/>
);

class App extends Component {
    componentDidMount() {
        const { actions } = this.props;
        actions.appInit();
    }

    render() {
        const { appIsLoaded, isAuthenticated } = this.props;

        if (!appIsLoaded) return <AppInitScreen/>;

        return (
            <Switch>
                <Route path="/login" component={LoginScreen}/>
                <PrivateRoute path="/" component={MainScreen} isAuthenticated={isAuthenticated}/>
            </Switch>
        );
    }
}


function mapStateToProps(state) {
    return {
        appIsLoaded: state.app.loadStatus.isLoaded,
        isAuthenticated: state.auth.isAuthenticated,
        router: state.router,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.app), dispatch),
        dispatch: dispatch
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

