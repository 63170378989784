import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { TransactionList } from 'components/blocks/transaction';


class BalanceTransactionListScreen extends Component {
    render() {
        const { balance, history } = this.props;
        const detail = balance.detail || {};

        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <div className="page-block page-block-full">
                        {detail.id &&
                        <TransactionList balance_id={detail.id} history={history}/>
                        }
                    </div>
                </div>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        balance: state.balance,
    };
}


export default connect(mapStateToProps)(BalanceTransactionListScreen);
