import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router";

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { ActionButton } from "components/common";
import { MessageForm } from 'components/blocks/message';
import MessageDetailScreen from './MessageDetailScreen';


class MessageScreen extends Component {
  state = {
    id: null,
  };

  componentDidMount() {
    const { actions, match } = this.props;
    actions.messageDetailLoad({ id: match.params.id });
    this.setState({ id: match.params.id });
  }

  showEditForm = (item = null) => {
    const { actions } = this.props;
    actions.messageShowEditForm({ show: true, object: item });
  };

  showReplyForm = (item = null) => {
    const { actions } = this.props;
    actions.messageShowReplyForm({ show: true, object: item });
  };

  delete = () => {
    const { actions } = this.props;
    const { id } = this.state;
    actions.messageDelete({ id });
  };

  render() {
    const { message, history } = this.props;
    const detail = message.detail || {};

    return (
      <Page>
        <PageHeader
          history={history}
          title={detail.subject}
          actions={[
            <Button onClick={() => this.showEditForm(detail)}>
              <Icon type="edit"/>
              <span>Редактировать</span>
            </Button>,
            <Button onClick={() => this.showReplyForm(detail)}>
              <span>Ответить</span>
            </Button>,
            <ActionButton
              actions={[
                {
                  key: 'delete',
                  icon: <Icon type="delete"/>,
                  title: 'Удалить',
                  action: this.delete,
                  confirm: {
                    title: 'Уверены, что хотите удалить?',
                    okText: 'Удалить',
                    cancelText: 'Отмена'
                  }
                },
              ]}

            />
          ]}
          basePath={`/message/${this.state.id}`}
        />

        <Route exact path="/message/:id" component={MessageDetailScreen}/>

        <MessageForm/>
      </Page>
    );
  }
}


function mapStateToProps(state) {
  return {
    message: state.message,
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, actions.message), dispatch),
    dispatch: dispatch
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(MessageScreen);
