import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from "connected-react-router";
import { Menu, Dropdown, Icon, Avatar } from 'antd';

import { actions } from 'store';
import { getFullNameOrEmail } from 'utils/helpers';
//import Icon from 'components/common/Icon';
import styles from './user_panel.module.css';


class HeaderUserPanel extends Component {
    handleMenuClick = ({ key }) => {
        const { dispatch, actions } = this.props;
        if (key === 'logout') {
            actions.authLogout();
        } else {
            dispatch(push(`/${key}`));
        }
    };

    render() {
        const { user_fields } = this.props;

        const menu = (
            <Menu onClick={this.handleMenuClick}>
                <Menu.Item key="setting">
                    <Icon type="setting"/>
                    <span>Настройки</span>
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Item key="logout">
                    <Icon type="logout"/>
                    <span>Выход</span>
                </Menu.Item>
            </Menu>
        );

        return (
            <Dropdown overlay={menu}>
                <div className={styles.action}>
                    <Avatar
                        size="small"
                        className={styles.avatar}
                        src={user_fields.photo}
                        icon="user"
                        alt="avatar"
                    />
                    <span className={styles.name}>{getFullNameOrEmail(user_fields)}</span>
                </div>
            </Dropdown>
        )
    }
}


function mapStateToProps(state) {
    return {
        user_fields: state.user.fields || {},
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.auth), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserPanel);
