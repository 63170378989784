import { takeEvery, select } from 'redux-saga/effects';

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/seo_template_page';


export function* watch() {
  yield takeEvery(actions.SEO_TEMPLATE_PAGE_LOAD.START, actionSeoTemplatePageLoad);
  yield takeEvery(actions.SEO_TEMPLATE_PAGE_DETAIL_LOAD.START, actionSeoTemplatePageDetailLoad);
  yield takeEvery(actions.SEO_TEMPLATE_PAGE_UPDATE.START, actionSeoTemplatePageUpdate);
  yield takeEvery(actions.SEO_TEMPLATE_PAGE_DELETE.START, actionSeoTemplatePageDelete);
}


function* actionSeoTemplatePageLoad(action) {
  yield base.ListLoad(action, api.seoTemplatePageLoad,
    {
      request: actions.seoTemplatePageLoadRequest,
      complete: actions.seoTemplatePageLoadComplete,
      error: actions.seoTemplatePageLoadError
    },
  );
}

function* actionSeoTemplatePageDetailLoad(action) {
  yield base.DetailLoad(action, api.seoTemplatePageDetailLoad,
    {
      request: actions.seoTemplatePageDetailLoadRequest,
      complete: actions.seoTemplatePageDetailLoadComplete,
      error: actions.seoTemplatePageDetailLoadError
    },
  );
}

function* actionSeoTemplatePageUpdate(action) {
  const filter = yield select((state) => state.seo_template_page.filter);
  yield base.Update(action, api.seoTemplatePageUpdate,
    {
      request: actions.seoTemplatePageUpdateRequest,
      complete: actions.seoTemplatePageUpdateComplete,
      error: actions.seoTemplatePageUpdateError,
      showEditForm: actions.seoTemplatePageShowEditForm,
      detailLoad: actions.seoTemplatePageDetailLoad,
      listLoad: actions.seoTemplatePageLoad,
    },
    null,
    filter,
  );
}

function* actionSeoTemplatePageDelete(action) {
  const filter = yield select((state) => state.seo_template_page.filter);
  yield base.Delete(action, api.seoTemplatePageDelete,
    {
      request: actions.seoTemplatePageDeleteRequest,
      complete: actions.seoTemplatePageDeleteComplete,
      error: actions.seoTemplatePageDeleteError,
      listLoad: actions.seoTemplatePageLoad,
    },
    null,
    filter,
  );
}
