import React, { Component, Fragment } from 'react';
import { Row, Col, Switch } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { getUrl } from "utils";
import { ModerationNewValue, Status } from "components/common";
import { CountryValue, CityValue } from "components/blocks/geo";
import styles from './detail.module.css';


class AgencyDetailScreen extends Component {
    componentDidMount() {
        const { actions, geo_city } = this.props;
        if (geo_city.loadStatus.notAsked || geo_city.query !== '') {
            actions.geoCityLoad({ query: '' });
        }
    }

    render() {
        const { agency, actions } = this.props;
        const detail = agency.detail || {};
        const moderation_data = detail.moderation_data || {};

        const contacts = detail.contacts || {};
        const photos = moderation_data.photos || detail.photos;


        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <Row gutter={16}>
                        <Col span={16}>
                            <div className="page-block">
                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Имя:</span>
                                    </Col>
                                    <Col span={19}>
                                        {detail.name_en}
                                        <ModerationNewValue value={moderation_data.name}/>
                                    </Col>
                                </Row>

                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Описание:</span>
                                    </Col>
                                    <Col span={19}>
                                        {detail.text_en}
                                        <ModerationNewValue value={moderation_data.text}/>
                                    </Col>
                                </Row>

                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Страна:</span>
                                    </Col>
                                    <Col span={19}>
                                        <CountryValue id={detail.country}/>
                                    </Col>
                                </Row>

                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Город:</span>
                                    </Col>
                                    <Col span={19}>
                                        <CityValue id={detail.city}/>
                                    </Col>
                                </Row>

                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Статус:</span>
                                    </Col>
                                    <Col span={19}>
                                        <Status status={detail.status}/>
                                    </Col>
                                </Row>

                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Включена:</span>
                                    </Col>
                                    <Col span={19}>
                                        <Switch
                                            checkedChildren="вкл" unCheckedChildren="выкл"
                                            checked={detail.is_on}
                                            onClick={() => {
                                                const data = { id: detail.id, is_on: !detail.is_on };
                                                actions.agencyUpdate({ data, preventLoadDetail: false })
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <br/>

                            <div className="page-block">
                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Телефон:</span>
                                    </Col>
                                    <Col span={19}>{contacts.phone}</Col>
                                </Row>

                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>WhatsApp:</span>
                                    </Col>
                                    <Col span={19}>{contacts.whatsapp}</Col>
                                </Row>

                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Telegram:</span>
                                    </Col>
                                    <Col span={19}>{contacts.telegram}</Col>
                                </Row>

                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Skype:</span>
                                    </Col>
                                    <Col span={19}>{contacts.skype}</Col>
                                </Row>

                                <Row className={styles.row}>
                                    <Col span={5}>
                                        <span className={styles.label}>Viber:</span>
                                    </Col>
                                    <Col span={19}>{contacts.viber}</Col>
                                </Row>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className="page-block">
                                {photos && photos.length > 0 &&
                                <div>
                                    <div className={styles.photoDetail}>
                                        <img src={getUrl(photos[0].medium || photos[0].url)} alt=""/>
                                    </div>

                                    {photos.length > 1 &&
                                    <div className={styles.photos}>
                                        {photos.slice(1).map(p => (
                                            <div key={`photo${p.id}`} className={styles.photo}>
                                                <img src={getUrl(p.medium || p.url)} alt=""/>
                                            </div>
                                        ))}
                                    </div>
                                    }
                                </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        agency: state.agency,
        geo_city: state.geo_city,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.agency, actions.geo_city), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AgencyDetailScreen);
