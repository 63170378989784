import React, { Component } from 'react';
import { Col, Row, Input, Checkbox } from "antd";

import { FormItem } from 'utils';


export default class TariffFieldSet extends Component {
    render() {
        const { form, instance, errorData } = this.props;

        return (
            <div className="form-block">
                <Row gutter={32}>
                    <Col span={17}>
                        <FormItem
                            form={form}
                            instance={instance}
                            name="billing_is_on"
                            required={false}
                            errorData={errorData}
                            valuePropName="checked"
                        >
                            <Checkbox>
                                Биллинг включён
                            </Checkbox>
                        </FormItem>

                        <div className="fieldset">
                            <div className="fieldset-title">Анкета</div>
                            <div>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="tariff.profile_period"
                                    label="Период"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input
                                        size="large" style={{ width: '100%' }}
                                        addonAfter="мин"
                                    />
                                </FormItem>

                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="tariff.profile_price"
                                    label="Цена размещения"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input
                                        size="large" style={{ width: '100%' }}
                                        formatter={value => `${value} €`}
                                        addonAfter="€"
                                    />
                                </FormItem>

                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="tariff.profile_up_price"
                                    label="Цена поднятия"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input
                                        size="large" style={{ width: '100%' }}
                                        addonAfter="€"
                                    />
                                </FormItem>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
        );
    }
}
