import { takeEvery, select } from 'redux-saga/effects';

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/service';


export function* watch() {
  yield takeEvery(actions.SERVICE_LOAD.START, actionServiceLoad);
  yield takeEvery(actions.SERVICE_DETAIL_LOAD.START, actionServiceDetailLoad);
  yield takeEvery(actions.SERVICE_UPDATE.START, actionServiceUpdate);
  yield takeEvery(actions.SERVICE_DELETE.START, actionServiceDelete);
}


function* actionServiceLoad(action) {
  yield base.ListLoad(action, api.serviceLoad,
    {
      request: actions.serviceLoadRequest,
      complete: actions.serviceLoadComplete,
      error: actions.serviceLoadError
    },
  );
}

function* actionServiceDetailLoad(action) {
  yield base.DetailLoad(action, api.serviceDetailLoad,
    {
      request: actions.serviceDetailLoadRequest,
      complete: actions.serviceDetailLoadComplete,
      error: actions.serviceDetailLoadError
    },
  );
}

function* actionServiceUpdate(action) {
  const filter = yield select((state) => state.service.filter);
  yield base.Update(action, api.serviceUpdate,
    {
      request: actions.serviceUpdateRequest,
      complete: actions.serviceUpdateComplete,
      error: actions.serviceUpdateError,
      showEditForm: actions.serviceShowEditForm,
      detailLoad: actions.serviceDetailLoad,
      listLoad: actions.serviceLoad,
    },
    null,
    filter,
  );
}

function* actionServiceDelete(action) {
  const filter = yield select((state) => state.service.filter);
  yield base.Delete(action, api.serviceDelete,
    {
      request: actions.serviceDeleteRequest,
      complete: actions.serviceDeleteComplete,
      error: actions.serviceDeleteError,
      listLoad: actions.serviceLoad,
    },
    null,
    filter,
  );
}
