import React, { Component, Fragment } from 'react';
import { Button } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { SiteNavList, SiteNavForm } from 'components/blocks/site';


class SiteNavListScreen extends Component {
    showForm = (item = null) => {
        const { actions } = this.props;
        actions.siteNavShowEditForm({ show: true, object: item });
    };

    render() {
        const { history, match } = this.props;
        const siteId = parseInt(match.params.id);

        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <div className="page-block page-block-full">
                        {siteId &&
                        <SiteNavList
                            basePath={`/site/${siteId}`}
                            history={history}
                            activeFilter={{ site: siteId }}
                            siteId={siteId}
                            topActions={[
                                <Button type="primary" className="" icon="plus" onClick={() => this.showForm()}>
                                    Добавить навигацию
                                </Button>
                            ]}
                        />
                        }
                    </div>
                </div>

                {siteId &&
                <SiteNavForm site_id={siteId}/>
                }
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        site: state.site,
        site_nav: state.site_nav,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.site_nav), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteNavListScreen);
