import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { ProfileList, ProfileForm } from "components/blocks/profile";


class CityProfileListScreen extends Component {
    showForm = (item = null) => {
        const { actions } = this.props;
        actions.profileShowEditForm({ show: true, object: item });
    };

    render() {
        const { geo_city, profile, history, actions } = this.props;
        const city = geo_city.detail || {};

        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <div className="page-block page-block-full">
                        <div className="page-block-header">
                            <div className="page-block-header-start">

                            </div>

                            <div className="page-block-header-end">
                                <Button type="primary" className="" icon="plus" onClick={() => this.showForm()}>
                                    Добавить анкету
                                </Button>
                            </div>
                        </div>

                        {city.id &&
                        <ProfileList city_id={city.id} profile={profile} history={history} actions={actions}/>
                        }
                    </div>
                </div>

                <ProfileForm city_id={city.id}/>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        geo_city: state.geo_city,
        profile: state.profile,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.profile), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CityProfileListScreen);
