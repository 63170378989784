import React, { Component } from 'react';
import { Button, Modal, Form, Spin, Input } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { FormItem } from "utils";

import { DomainSelect } from "components/blocks/domain";


class DomainForm extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { actions, domain } = this.props;
                const instance = domain.editObject;

                let data = Object.assign({}, values);
                if (instance && instance.id) {
                    data.id = instance.id;
                }
                actions.domainUpdate({ data });
            }
        });
    };

    close = () => {
        const { actions } = this.props;
        actions.domainShowEditForm({ show: false });
    };

    render() {
        const { form, domain, parent_id } = this.props;
        const { showEditForm, updateStatus, editObject } = domain;
        const instance = editObject;

        const error = updateStatus.error || {};
        const errorData = error.data || {};

        const isLoading = domain.updateStatus.isLoading;

        return (
            <Modal
                visible={showEditForm}
                title="Редактирование домена"
                onCancel={this.close}
                maskClosable={false}
                destroyOnClose={true}
                footer={[
                    <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
                    <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
                        Сохранить
                    </Button>,
                ]}
            >
                <Spin spinning={isLoading}>
                    <Form onSubmit={this.handleSubmit}>
                        {errorData.non_field_errors &&
                        <div className="form-error">{errorData.non_field_errors}</div>}

                        <DomainSelect
                            form={form}
                            instance={instance}
                            name="parent"
                            label="Основной домен"
                            required={false}
                            errorData={errorData}
                            initialValue={parent_id}
                        />

                        <FormItem
                            form={form}
                            instance={instance}
                            name="domain"
                            label="Домен"
                            required={true}
                            errorData={errorData}

                        >
                            <Input size="large"/>
                        </FormItem>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}


const WrappedDomainForm = Form.create()(DomainForm);

function mapStateToProps(state) {
    return {
        domain: state.domain,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.domain), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedDomainForm);

