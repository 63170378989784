import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Switch } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { ordering, getDateNowStr } from "utils";
import { TableList, Photo, DateTimeText, Status, ActionButton } from 'components/common';
import { CountrySelect, CitySelect, CountryValue, CityValue } from 'components/blocks/geo';
import { UserValue } from 'components/blocks/user';


class ProfileList extends Component {
    componentDidMount() {
        const { actions, geo_country, geo_city } = this.props;
        actions.geoCountryLoad({ query: geo_country.query, locationChange: false });
        actions.geoCityLoad({ query: geo_city.query, locationChange: false });
    }

    load = (filter) => {
;        const { actions, profile } = this.props;
        let newFilter = Object.assign({}, filter);
        if (newFilter.activeFilter === undefined) {
            newFilter.activeFilter = profile.filter.activeFilter
        }
        actions.profileLoad(newFilter);
    };

    render() {
        const { history, actions, defaultFilter, activeFilter, topActions, profile, geo_country, geo_city, hideFilters } = this.props;
        const params = profile.filter.params;

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 59,
            },
            {
                title: 'Имя',
                dataIndex: 'name_en',
                key: 'name_en',
                width: 150,
                render: (val, item) => (
                    <div>
                        <div style={{marginBottom: "5px"}}>
                            <Link to={`/profile/${item.id}`}>{val}</Link>
                        </div>
                        <Link to={`/profile/${item.id}`}>
                            <Photo
                                url={item.photos.length && (item.photos[0].small || item.photos[0].url)}
                                width={80} height={60}
                            />
                        </Link>
                    </div>
                ),
                sorter: true,
                sortOrder: ordering('name', params.ordering),
            },
            {
                title: 'Город',
                dataIndex: 'city',
                key: 'city',
                width: 120,
                render: (val, item) => (
                    <div>
                        <CountryValue id={item.country} byId={geo_country.byId}/>
                        <CityValue id={val} byId={geo_city.byId}/>
                    </div>
                ),
            },
            {
                title: 'Пользователь',
                dataIndex: 'user',
                key: 'user',
                width: 150,
                render: (val, item) => <UserValue id={val}/>,
            },
            {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                width: 120,
                render: (text, item) => <div>
                    <Status status={text}/>
                    <Switch
                        style={{ marginTop: '5px', minWidth: '62px' }}
                        checkedChildren="вкл" unCheckedChildren="выкл"
                        checked={item.is_on}
                        onClick={() => {
                            const data = { id: item.id, is_on: !item.is_on };
                            actions.profileUpdate({ data, preventLoadDetail: true })
                        }}
                    />
                </div>,
            },
            /*{
                title: 'Просм.',
                dataIndex: 'views_count',
                key: 'views_count',
                width: 90,

                sorter: true,
                sortOrder: ordering('views_count', params.ordering),
            },*/
            {
                title: 'Активна до',
                dataIndex: 'date_active',
                key: 'date_active',
                width: 112,
                render: (text, item) => <DateTimeText date={text}/>,

                sorter: true,
                sortOrder: ordering('date_active', params.ordering),
            },
            {
                title: 'Дата поднятия',
                dataIndex: 'date_up',
                key: 'date_up',
                width: 112,
                render: (text, item) => <DateTimeText date={text}/>,

                sorter: true,
                sortOrder: ordering('date_up', params.ordering),
            },

            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                width: 30,
                align: 'right',
                render: (val, item) => {
                    return (
                        <ActionButton
                            actions={[
                                {
                                    key: 'up',
                                    icon: <Icon type="up-circle"/>,
                                    title: 'Поднять',
                                    action: () => {
                                        const data = { id: item.id, date_up: getDateNowStr() };
                                        actions.profileUpdate({ data, preventLoadDetail: true })
                                    }
                                },
                                {
                                    key: 'edit',
                                    icon: <Icon type="edit"/>,
                                    title: 'Редактировать',
                                    action: () => actions.profileShowEditForm({ show: true, object: item })
                                },
                                {
                                    key: 'delete',
                                    icon: <Icon type="delete"/>,
                                    title: 'Удалить',
                                    action: () => actions.profileDelete({ id: item.id }),
                                    confirm: {
                                        title: 'Уверены, что хотите удалить?',
                                        okText: 'Удалить',
                                        cancelText: 'Отмена'
                                    }
                                },
                            ]}

                        />
                    )
                }
            }
        ];

        const filterFields = hideFilters ? null: {
            list: [
                'is_verified', 'has_reviews', 'has_video', 'country', 'city',
                'prices.in_hour', 'prices.out_hour',
                'parameters.gender',
            ],
            byName: {
                'is_verified': {
                    label: 'Проверенные фото',
                    type: 'bool',
                },
                'has_reviews': {
                    label: 'С отзывами',
                    type: 'bool',
                },
                'has_video': {
                    label: 'С видео',
                    type: 'bool',
                },
                'country': {
                    label: 'Страна',
                    type: 'list_int',
                    component: CountrySelect,
                    valueComponent: CountryValue,
                },
                'city': {
                    label: 'Город',
                    type: 'list_int',
                    component: CitySelect,
                    valueComponent: CityValue,
                },
                'prices.in_hour': {
                    label: 'Апартаменты час',
                    type: 'list',
                    options: [
                        { label: '0-150 €', value: '0-150' },
                        { label: '151-300 €', value: '151-300' },
                        { label: '301-more €', value: '301-' },
                    ]
                },
                'prices.out_hour': {
                    label: 'Выезд час',
                    type: 'list',
                    options: [
                        { label: '0-150 €', value: '0-150' },
                        { label: '151-300 €', value: '151-300' },
                        { label: '301-more €', value: '301-' },
                    ]
                },
                'parameters.gender': {
                    label: 'Пол',
                    type: 'list',
                    options: [
                        { label: 'Женский', value: 'female' },
                        { label: 'Мужской', value: 'male' },
                        { label: 'Транс', value: 'trans' },
                    ]
                },
            }
        };

        return (
            <TableList
                history={history}
                defaultFilter={defaultFilter}
                activeFilter={activeFilter}
                data={profile}
                columns={columns}
                onLoadData={this.load}
                topActions={topActions}
                plural={['анкета', 'анкеты', 'анкет']}
                onRow={(item, rowIndex) => {
                    return {
                        onDoubleClick: event => {
                            this.props.history.push(`/profile/${item.id}`);
                        },
                    };
                }}

                filterFields={filterFields}
            />
        );
    }
}


function mapStateToProps(state) {
    return {
        profile: state.profile,
        geo_country: state.geo_country,
        geo_city: state.geo_city,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            actions.profile,
            actions.geo_country,
            actions.geo_city
        ), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileList);
