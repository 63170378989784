import React, { Component } from 'react';
import { Button, Modal, Form, Spin, Tabs } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";

import MainFieldSet from "./MainFieldSet";
import ConfigFieldSet from "./ConfigFieldSet";
import TariffFieldSet from "./TariffFieldSet";


class SiteForm extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { actions, site } = this.props;
                const instance = site.editObject;

                let data = Object.assign({}, values);
                if (instance && instance.id) {
                    data.id = instance.id;
                }
                actions.siteUpdate({ data });
            }
        });
    };

    close = () => {
        const { actions } = this.props;
        actions.siteShowEditForm({ show: false });
    };

    render() {
        const { form, site, auth_token } = this.props;
        const { showEditForm, updateStatus, editObject } = site;
        const instance = editObject;

        const error = updateStatus.error || {};
        const errorData = error.data || {};

        const isLoading = site.updateStatus.isLoading;

        return (
            <Modal
                width={960}
                visible={showEditForm}
                title="Редактирование сайта"
                onCancel={this.close}
                maskClosable={false}
                destroyOnClose={true}
                footer={[
                    <Button key="cancel" onClick={this.close} disabled={isLoading}>Отмена</Button>,
                    <Button key="submit" type="primary" loading={isLoading} onClick={this.handleSubmit}>
                        Сохранить
                    </Button>,
                ]}
            >
                <Spin spinning={isLoading}>
                    <Form onSubmit={this.handleSubmit}>
                        {errorData.non_field_errors &&
                        <div className="form-error">{errorData.non_field_errors}</div>}

                        <Tabs className="tabs">
                            <Tabs.TabPane tab="Основное" key="detail">
                                <MainFieldSet instance={instance} errorData={errorData} form={form}/>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab="Настройки" key="config">
                                <ConfigFieldSet instance={instance} errorData={errorData} form={form} auth_token={auth_token}/>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab="Тарифы" key="tariff">
                                <TariffFieldSet instance={instance} errorData={errorData} form={form}/>
                            </Tabs.TabPane>
                        </Tabs>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}


const WrappedSiteForm = Form.create()(SiteForm);

function mapStateToProps(state) {
    return {
        auth_token: state.auth.token,
        site: state.site,
        geo_city: state.geo_city,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.site), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedSiteForm);

