import axios from 'axios';
import { BASE_URL } from "config";

export const endpoints = {
    login: `${BASE_URL}/api/v1/auth/`,
    refresh: `${BASE_URL}/api/v1/auth/refresh/`,
    password_change: `${BASE_URL}/api/v1/auth/password/change/`,
};


export const apiAuthTokenCheck = (token) =>
    axios.post(endpoints.refresh, {
        token: token,
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            return response.data.token
        })
        .catch(error => {
            return null;
        });


export const apiAuthLogin = (data) =>
    axios.post(endpoints.login, {
        username: data.username,
        password: data.password
    }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            return { error: false, data: response.data, statusCode: response.status }
        })
        .catch(error => {
            return { error: true, data: error.response.data, statusCode: error.response.status }
        });
