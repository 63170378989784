import { handleActions } from 'redux-actions';
import { loadStates, loadStatus } from 'utils/redux';

import * as actions from './actions';
import * as base from "../BaseReducers";

const initialState = Object.assign({
    fields: null,
    currentLoadStatus: loadStatus(loadStates.notAsked),
  },
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.currentUserLoadRequest]: (state, action) => ({
      ...state,
      currentLoadStatus: loadStatus(loadStates.loading),
    }),
    [actions.currentUserLoadComplete]: (state, action) => ({
      ...state,
      currentLoadStatus: loadStatus(loadStates.loaded),
      fields: action.payload,
    }),
    [actions.currentUserLoadError]: (state, action) => ({
      ...state,
      currentLoadStatus: loadStatus(loadStates.failed, action.payload),
    }),

    [actions.userShowEditForm]: (state, action) => base.showEditForm(state, action),

    [actions.userLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.userLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.userLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.userDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.userDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.userDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.userUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.userUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.userUpdateError]: (state, action) => base.updateError(state, action),

    [actions.userDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.userDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.userDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
