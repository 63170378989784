import { put, call, takeEvery, select } from 'redux-saga/effects';

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/user';


export function* watch() {
  yield takeEvery(actions.CURRENT_USER_LOAD.START, actionCurrentUserLoad);

  yield takeEvery(actions.USER_LOAD.START, actionUserLoad);
  yield takeEvery(actions.USER_DETAIL_LOAD.START, actionUserDetailLoad);
  yield takeEvery(actions.USER_UPDATE.START, actionUserUpdate);
  yield takeEvery(actions.USER_DELETE.START, actionUserDelete);
}

function* actionCurrentUserLoad(action) {
  yield call(currentUserLoad, action.payload)
}

export function* currentUserLoad({ token }) {
  yield put(actions.currentUserLoadRequest());

  try {
    const { error, data, statusCode } = yield call(() => api.currentUserLoad({ token }));

    if (error) {
      yield put(actions.currentUserLoadError({ data, statusCode }));
    } else {
      yield put(actions.currentUserLoadComplete(data));
    }

  } catch (error) {
    yield put(actions.currentUserLoadError());
  }
}


function* actionUserLoad(action) {
  yield base.ListLoad(action, api.userLoad,
    {
      request: actions.userLoadRequest,
      complete: actions.userLoadComplete,
      error: actions.userLoadError
    },
  );
}

function* actionUserDetailLoad(action) {
  yield base.DetailLoad(action, api.userDetailLoad,
    {
      request: actions.userDetailLoadRequest,
      complete: actions.userDetailLoadComplete,
      error: actions.userDetailLoadError
    },
  );
}

function* actionUserUpdate(action) {
  const filter = yield select((state) => state.user.filter);
  yield base.Update(action, api.userUpdate,
    {
      request: actions.userUpdateRequest,
      complete: actions.userUpdateComplete,
      error: actions.userUpdateError,
      showEditForm: actions.userShowEditForm,
      detailLoad: actions.userDetailLoad,
      listLoad: actions.userLoad,
    },
    '/user',
    filter,
  );
}

function* actionUserDelete(action) {
  const filter = yield select((state) => state.user.filter);
  yield base.Delete(action, api.userDelete,
    {
      request: actions.userDeleteRequest,
      complete: actions.userDeleteComplete,
      error: actions.userDeleteError,
      listLoad: actions.userLoad,
    },
    '/user',
    filter,
  );
}
