import React, { Component } from 'react';
import { Button, Icon, Tabs } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router";

import { actions } from "store";
import { RefillForm } from 'components/blocks/balance';
import BalanceDetailScreen from './BalanceDetailScreen';
import BalanceTransactionListScreen from './BalanceTransactionListScreen';


class BalanceScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
        }
    }

    componentDidMount() {
        const { actions, match } = this.props;
        actions.balanceDetailLoad({ id: match.params.id });
        this.setState({ id: match.params.id });
    }

    showForm = () => {
        const { actions } = this.props;
        actions.refillShowEditForm({ show: true });
    };

    onTabChange = (key) => {
        const { id } = this.state;
        this.props.history.push(`/balance/${id}/${key}`);
    };

    render() {
        const { balance, location } = this.props;
        const detail = balance.detail || {};
        const user = detail.user || {};
        const tabKey = location.pathname.split('/')[3];

        return (
            <div className="page">
                <div className="page-header">
                    <div className="page-header-title">{detail.user && detail.user.email}</div>

                    <div className="page-header-actions">
                        <Button onClick={() => this.showForm(detail)}>
                            <Icon type="plus"/>
                            <span>Пополнить</span>
                        </Button>
                    </div>

                    <div className="page-header-nav">
                        <Tabs className="page-header-tabs" activeKey={tabKey} onChange={this.onTabChange}>
                            <Tabs.TabPane tab="Детали" key="detail"/>
                            <Tabs.TabPane tab="Транзакции" key="transaction"/>
                        </Tabs>
                    </div>
                </div>

                <Route exact path="/balance/:id" component={BalanceDetailScreen}/>
                <Route exact path="/balance/:id/detail" component={BalanceDetailScreen}/>
                <Route exact path="/balance/:id/transaction" component={BalanceTransactionListScreen}/>

                {user.id &&
                <RefillForm user_id={user.id}/>
                }
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        balance: state.balance,
        refill: state.refill,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.balance, actions.refill), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(BalanceScreen);
