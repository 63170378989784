import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const REVIEW_LOAD = createRequestTypes('REVIEW_LOAD');
export const REVIEW_DETAIL_LOAD = createRequestTypes('REVIEW_DETAIL_LOAD');
export const REVIEW_UPDATE = createRequestTypes('REVIEW_UPDATE');
export const REVIEW_DELETE = createRequestTypes('REVIEW_DELETE');
export const REVIEW_SHOW_EDIT_FORM = 'REVIEW_SHOW_EDIT_FORM';

export const reviewShowEditForm = createAction(REVIEW_SHOW_EDIT_FORM);

export const reviewLoad = createAction(REVIEW_LOAD.START);
export const reviewLoadRequest = createAction(REVIEW_LOAD.REQUEST);
export const reviewLoadComplete = createAction(REVIEW_LOAD.COMPLETE);
export const reviewLoadError = createAction(REVIEW_LOAD.ERROR);

export const reviewDetailLoad = createAction(REVIEW_DETAIL_LOAD.START);
export const reviewDetailLoadRequest = createAction(REVIEW_DETAIL_LOAD.REQUEST);
export const reviewDetailLoadComplete = createAction(REVIEW_DETAIL_LOAD.COMPLETE);
export const reviewDetailLoadError = createAction(REVIEW_DETAIL_LOAD.ERROR);

export const reviewUpdate = createAction(REVIEW_UPDATE.START);
export const reviewUpdateRequest = createAction(REVIEW_UPDATE.REQUEST);
export const reviewUpdateComplete = createAction(REVIEW_UPDATE.COMPLETE);
export const reviewUpdateError = createAction(REVIEW_UPDATE.ERROR);

export const reviewDelete = createAction(REVIEW_DELETE.START);
export const reviewDeleteRequest = createAction(REVIEW_DELETE.REQUEST);
export const reviewDeleteComplete = createAction(REVIEW_DELETE.COMPLETE);
export const reviewDeleteError = createAction(REVIEW_DELETE.ERROR);
