import React from 'react';
import classNames from 'classnames';

import { PageHeaderTabs } from 'components/layout';
import './styles.css';


export default function PageHeader({ mode, title, actions, basePath, tabs, tabPathPrefix, onTabChange, history }) {
    const cx = classNames([
        'page-header',
        mode && `page-header--${mode}`,
    ]);
    return (
        <header className={cx}>
            <div className="page-header-main">
                <div className="page-header-content">
                    <div className="page-header-title">{title}</div>
                </div>

                <div className="page-header-actions">
                    {actions && actions.map((item, index) =>
                        <div className="action" key={`action${index}`}>{item}</div>
                    )}
                </div>
            </div>

            {tabs &&
            <PageHeaderTabs
                basePath={basePath}
                tabPathPrefix={tabPathPrefix}
                tabs={tabs}
                onTabChange={onTabChange}
                history={history}
            />}
        </header>
    );
}
