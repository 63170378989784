import { put, call, select } from 'redux-saga/effects';
import { push, replace } from "connected-react-router";


export function* ListLoad(action, apiLoad, actions) {
  const token = yield select((state) => state.auth.token);

  const { payload } = action;
  yield put(actions.request(payload));
  if (payload.locationChange !== false) {
    yield put(replace({ search: payload.query }));
  }

  try {
    const { error, data, statusCode } = yield call(() => apiLoad(token, payload));

    if (error) {
      yield put(actions.error({ data, statusCode }));
    } else {
      yield put(actions.complete(data));
    }

  } catch (error) {
    yield put(actions.error());
  }
}


export function* DetailLoad(action, apiLoad, actions) {
  const token = yield select((state) => state.auth.token);

  const { id } = action.payload;
  yield put(actions.request());

  try {
    const { error, data, statusCode } = yield call(() => apiLoad(token, { id }));

    if (error) {
      yield put(actions.error({ data, statusCode }));
    } else {
      yield put(actions.complete(data));
    }

  } catch (error) {
    yield put(actions.error());
  }
}


export function* Update(action, apiLoad, actions, baseUrl, filter, getId = null) {
  const token = yield select((state) => state.auth.token);
  const { preventLoadDetail } = action.payload;

  yield put(actions.request());
  try {
    const { error, data, statusCode } = yield call(() => apiLoad(token, action.payload.data));

    if (error) {
      yield put(actions.error({ data, statusCode }));
    } else {
      yield put(actions.complete(data));
      yield put(actions.showEditForm({ show: false }));
      if (!preventLoadDetail && baseUrl) {
        const id = getId ? getId(data): data.id;
        yield put(actions.detailLoad({ id: id }));
        yield put(push(`${baseUrl}/${id}`));
      } else {
        yield put(actions.listLoad(filter));
      }
    }
  } catch (error) {
    yield put(actions.error());
  }
}


export function* Delete(action, apiLoad, actions, baseUrl, filter) {
  const token = yield select((state) => state.auth.token);

  yield put(actions.request());
  try {
    const { error, data, statusCode } = yield call(() => apiLoad(token, action.payload));

    if (error) {
      yield put(actions.error({ data, statusCode }));
    } else {
      yield put(actions.complete(data));
      if (baseUrl) {
        yield put(push(`${baseUrl}`));
      }
      yield put(actions.listLoad(filter));
    }

  } catch (error) {
    yield put(actions.error());
  }
}
