import React, { Component, Fragment } from 'react';
import { Row, Col, Switch } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { getUrl } from "utils";
import { getParametetsValue } from "utils/choices";
import { ModerationNewValue, Status } from "components/common";
import { CountryValue, CityValue } from "components/blocks/geo";
import { ServiceValue } from "components/blocks/service";
import styles from './detail.module.css';


const FieldValue = props => {
    const labelWidth = props.labelWidth || 5;
    return (
        <Row className={styles.row}>
            <Col span={labelWidth}>
                <span className={styles.label}>{props.label}:</span>
            </Col>
            <Col span={24 - labelWidth}>{props.children}</Col>
        </Row>
    )
};


class ProfileDetailScreen extends Component {
    componentDidMount() {
        const { actions, geo_city } = this.props;
        if (geo_city.loadStatus.notAsked || geo_city.query !== '') {
            actions.geoCityLoad({ query: '' });
        }
    }

    render() {
        const { profile, actions } = this.props;
        const detail = profile.detail || {};
        const moderation_data = detail.moderation_data || {};

        const parameters = detail.parameters || {};
        const contacts = detail.contacts || {};
        const photos = moderation_data.photos || detail.photos;
        const services = Array.isArray(detail.services) ? detail.services : [];

        console.log(detail.user);

        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <Row gutter={16}>
                        <Col span={16}>
                            <div className="page-block">
                                <FieldValue label="Имя" labelWidth={5}>
                                    {detail.name_en}
                                    <ModerationNewValue value={moderation_data.name}/>
                                </FieldValue>

                                <FieldValue label="Описание" labelWidth={5}>
                                    {detail.text_en}
                                    <ModerationNewValue value={moderation_data.text}/>
                                </FieldValue>

                                <FieldValue label="Страна" labelWidth={5}>
                                    <CountryValue id={detail.country}/>
                                </FieldValue>

                                <FieldValue label="Город" labelWidth={5}>
                                    <CityValue id={detail.city}/>
                                </FieldValue>

                                <FieldValue label="Статус" labelWidth={5}>
                                    <Status status={detail.status}/>
                                </FieldValue>

                                <FieldValue label="Включена" labelWidth={5}>
                                    <Switch
                                        checkedChildren="вкл" unCheckedChildren="выкл"
                                        checked={detail.is_on}
                                        onClick={() => {
                                            const data = { id: detail.id, is_on: !detail.is_on };
                                            actions.profileUpdate({ data, preventLoadDetail: false })
                                        }}
                                    />
                                </FieldValue>
                            </div>

                            <br/>

                            <div className="page-block">
                                <Row>
                                    <Col span={12}>
                                        <FieldValue label="Пол" labelWidth={10}>
                                            {getParametetsValue(parameters, 'gender')}
                                        </FieldValue>

                                        <FieldValue label="Внешность" labelWidth={10}>
                                            {getParametetsValue(parameters, 'ethnicity')}
                                        </FieldValue>

                                        <FieldValue label="Встречается" labelWidth={10}>
                                            {getParametetsValue(parameters, 'meeting')}
                                        </FieldValue>

                                        <FieldValue label="Рост" labelWidth={10}>
                                            {getParametetsValue(parameters, 'height')}
                                        </FieldValue>

                                        <FieldValue label="Размер груди" labelWidth={10}>
                                            {getParametetsValue(parameters, 'breast_size')}
                                        </FieldValue>

                                        <FieldValue label="Цвет волос" labelWidth={10}>
                                            {getParametetsValue(parameters, 'hair_color')}
                                        </FieldValue>

                                        <FieldValue label="Путешествия" labelWidth={10}>
                                            {getParametetsValue(parameters, 'travel')}
                                        </FieldValue>
                                    </Col>

                                    <Col span={12}>
                                        <FieldValue label="Национальность" labelWidth={10}>
                                            {getParametetsValue(parameters, 'nationality')}
                                        </FieldValue>

                                        <FieldValue label="Ориентация" labelWidth={10}>
                                            {getParametetsValue(parameters, 'orientation')}
                                        </FieldValue>

                                        <FieldValue label="Возраст" labelWidth={10}>
                                            {getParametetsValue(parameters, 'age')}
                                        </FieldValue>

                                        <FieldValue label="Вес" labelWidth={10}>
                                            {getParametetsValue(parameters, 'weight')}
                                        </FieldValue>

                                        <FieldValue label="Тип груди" labelWidth={10}>
                                            {getParametetsValue(parameters, 'breast_type')}
                                        </FieldValue>

                                        <FieldValue label="Цвет глаз" labelWidth={10}>
                                            {getParametetsValue(parameters, 'eye_color')}
                                        </FieldValue>

                                        <FieldValue label="Языки" labelWidth={10}>
                                            {getParametetsValue(parameters, 'languages')}
                                        </FieldValue>
                                    </Col>
                                </Row>
                            </div>

                            <br/>

                            <div className="page-block">
                                <Row>
                                    <Col span={12}>
                                        <FieldValue label="Услуги" labelWidth={10}>
                                            {services.map(id => <ServiceValue key={`service${id}`} id={id}/>)}
                                        </FieldValue>
                                    </Col>
                                </Row>
                            </div>

                            <br/>

                            <div className="page-block">
                                <FieldValue label="Телефон" labelWidth={5}>
                                    {contacts.phone}
                                </FieldValue>

                                <FieldValue label="WhatsApp" labelWidth={5}>
                                    {contacts.whatsapp}
                                </FieldValue>

                                <FieldValue label="Telegram" labelWidth={5}>
                                    {contacts.telegram}
                                </FieldValue>

                                <FieldValue label="Skype" labelWidth={5}>
                                    {contacts.skype}
                                </FieldValue>

                                <FieldValue label="Viber" labelWidth={5}>
                                    {contacts.viber}
                                </FieldValue>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className="page-block">
                                {photos && photos.length > 0 &&
                                <div>
                                    <div className={styles.photoDetail}>
                                        <img src={getUrl(photos[0].medium || photos[0].url)} alt=""/>
                                    </div>

                                    {photos.length > 1 &&
                                    <div className={styles.photos}>
                                        {photos.slice(1).map(p => (
                                            <div key={`photo${p.id}`} className={styles.photo}>
                                                <img src={getUrl(p.medium || p.url)} alt=""/>
                                            </div>
                                        ))}
                                    </div>
                                    }
                                </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        profile: state.profile,
        geo_city: state.geo_city,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.profile, actions.geo_city), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetailScreen);
