import { takeEvery, select } from 'redux-saga/effects';

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/site_nav';


export function* watch() {
  yield takeEvery(actions.SITE_NAV_LOAD.START, actionSiteNavLoad);
  yield takeEvery(actions.SITE_NAV_DETAIL_LOAD.START, actionSiteNavDetailLoad);
  yield takeEvery(actions.SITE_NAV_UPDATE.START, actionSiteNavUpdate);
  yield takeEvery(actions.SITE_NAV_DELETE.START, actionSiteNavDelete);
}

function* actionSiteNavLoad(action) {
  yield base.ListLoad(action, api.siteNavLoad,
    {
      request: actions.siteNavLoadRequest,
      complete: actions.siteNavLoadComplete,
      error: actions.siteNavLoadError
    },
  );
}

function* actionSiteNavDetailLoad(action) {
  yield base.DetailLoad(action, api.siteNavDetailLoad,
    {
      request: actions.siteNavDetailLoadRequest,
      complete: actions.siteNavDetailLoadComplete,
      error: actions.siteNavDetailLoadError
    },
  );
}

function* actionSiteNavUpdate(action) {
  const filter = yield select((state) => state.site_nav.filter);
  yield base.Update(action, api.siteNavUpdate,
    {
      request: actions.siteNavUpdateRequest,
      complete: actions.siteNavUpdateComplete,
      error: actions.siteNavUpdateError,
      showEditForm: actions.siteNavShowEditForm,
      detailLoad: actions.siteNavDetailLoad,
      listLoad: actions.siteNavLoad,
    },
    null,
    filter,
  );
}

function* actionSiteNavDelete(action) {
  const filter = yield select((state) => state.site_nav.filter);
  yield base.Delete(action, api.siteNavDelete,
    {
      request: actions.siteNavDeleteRequest,
      complete: actions.siteNavDeleteComplete,
      error: actions.siteNavDeleteError,
      listLoad: actions.siteNavLoad,
    },
    null,
    filter,
  );
}
