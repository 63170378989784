import React, { Component } from 'react';
import { Col, Row, Select } from "antd";

import { FormItem, TranslationFormItem } from 'utils';
import PhotoUpload from 'components/common/PhotoUpload';


export default class MainFieldSet extends Component {
    state = {
        id: null,
        photos: [],
        show: false,
        moderationData: {}
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { editObject, showEditForm } = nextProps.agency;

        let nextState = {};

        if (showEditForm !== prevState.show) {
            nextState = {
                id: null,
                photos: [],
                show: showEditForm,
                moderationData: {}
            };
        }

        if (editObject && editObject.id !== prevState.id) {
            let moderationData = editObject && (editObject.moderation_data || {});
            ['name', 'text'].forEach(fieldName => {
                if (moderationData[fieldName]) {
                    moderationData[`${fieldName}_en`] = moderationData[fieldName];
                }
            });

            nextState.id = editObject.id;
            nextState.photos = moderationData.photos || editObject.photos;
            nextState.moderationData = moderationData;
        }
        return nextState;
    }

    handlePhotoChange = (photos) => {
        const new_photos = this.state.photos.slice();
        if (new_photos.length > 0) {
            new_photos[0] = photos[0];
        } else {
            new_photos.push(photos[0]);
        }
        this.setState({
            photos: new_photos,
        });

        this.props.onPhotoChange && this.props.onPhotoChange(new_photos);
    };

    handleOtherPhotoChange = (photos) => {
        const new_photos = this.state.photos.slice(0, 1);
        photos.forEach(item => {
            new_photos.push(item);
        });
        this.setState({
            photos: new_photos,
        });

        this.props.onPhotoChange && this.props.onPhotoChange(new_photos);
    };

    render() {
        const { form, agency } = this.props;
        const { photos, moderationData } = this.state;
        const { updateStatus, editObject } = agency;
        const instance = editObject ? Object.assign({}, editObject, moderationData) : null;

        const error = updateStatus.error || {};
        const errorData = error.data || {};

        return (
            <Row gutter={32}>
                <Col span={17}>
                    <div className="form-block">
                        <FormItem
                            form={form}
                            instance={instance}
                            name="status"
                            label="Статус"
                            required={true}
                            errorData={errorData}
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Выберите"
                            >
                                <Select.Option value={0}>Новая</Select.Option>
                                <Select.Option value={1}>На проверке</Select.Option>
                                <Select.Option value={3}>Проверена</Select.Option>
                                <Select.Option value={4}>Отклонена</Select.Option>
                                <Select.Option value={5}>Забанена</Select.Option>
                            </Select>
                        </FormItem>

                        <TranslationFormItem
                            form={form}
                            instance={instance}
                            name="name"
                            label="Название"
                            required={true}
                            errorData={errorData}
                        />

                        <TranslationFormItem
                            form={form}
                            instance={instance}
                            name="text"
                            label="Описание"
                            required={false}
                            errorData={errorData}
                            inputType="textarea"
                        />
                    </div>
                </Col>

                <Col span={7}>
                    <PhotoUpload
                        mode="single" title="Загрузить фото" photos={photos.slice(0, 1)}
                        onChange={this.handlePhotoChange}
                    />

                    {photos.length > 0 &&
                    <PhotoUpload title="Загрузить фото" photos={photos.slice(1)}
                                 onChange={this.handleOtherPhotoChange}/>
                    }
                </Col>
            </Row>
        );
    }
}
