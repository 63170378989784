import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { TransactionList } from 'components/blocks/transaction';


class TransactionListScreen extends Component {
    render() {
        const { history } = this.props;

        return (
            <div className="page">
                <div className="page-header">
                    <div className="page-header-title">Транзакции</div>
                </div>

                <div className="page-content page-content-full">
                    <div className="page-block page-block-full">
                        <TransactionList history={history}/>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        transaction: state.transaction,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.transaction), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TransactionListScreen);
