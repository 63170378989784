import React, { Component } from 'react';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { actions } from "store";
import { ordering } from "utils";
import { TableList, ActionButton, DateTimeText } from 'components/common';


class DomainList extends Component {
    load = (filter) => {
        const { actions, domain } = this.props;
        let newFilter = Object.assign({}, filter);
        if (newFilter.activeFilter === undefined) {
            newFilter.activeFilter =  domain.filter.activeFilter
        }
        actions.domainLoad(newFilter);
    };

    render() {
        const { history, actions, defaultFilter, activeFilter, topActions, domain } = this.props;
        const params = domain.filter.params;

        const columns = [
            {
                title: 'Домен',
                dataIndex: 'domain',
                key: 'domain',
                render: (text, item) => <Link to={`/domain/${item.id}`}>{text}</Link>,

                sorter: true,
                sortOrder: ordering('domain', params.ordering),
            },
            {
                title: 'Дата регистрации',
                dataIndex: 'date_created',
                key: 'date_created',
                width: 120,
                render: (text, item) => <DateTimeText date={text} onlyDate={true}/>,

                sorter: true,
                sortOrder: ordering('date_created', params.ordering),
            },
            {
                title: 'Дата продления',
                dataIndex: 'date_ended',
                key: 'date_ended',
                width: 120,
                render: (text, item) => <DateTimeText date={text} onlyDate={true}/>,

                sorter: true,
                sortOrder: ordering('date_ended', params.ordering),
            },
            {
                title: 'Заблокирован',
                dataIndex: 'is_blocked',
                key: 'is_blocked',
                width: 100,
                render: (text, item) => text && <Icon type="close-circle" theme="filled" style={{ color: '#ff0000' }}/>,

                sorter: true,
                sortOrder: ordering('is_blocked', params.ordering),
            },
            {
                title: 'Действия',
                dataIndex: 'actions',
                key: 'actions',
                width: 130,
                align: 'right',
                render: (val, item) => {
                    return (
                        <ActionButton
                            actions={[
                                {
                                    key: 'makeMain',
                                    icon: <Icon type="up-square"/>,
                                    title: 'Сделать главным',
                                    action: () => actions.domainMakeMain({ id: item.id }),
                                },
                                {
                                    key: 'edit',
                                    icon: <Icon type="edit"/>,
                                    title: 'Редактировать',
                                    action: () => actions.domainShowEditForm({ show: true, object: item }),
                                },
                                {
                                    key: 'delete',
                                    icon: <Icon type="delete"/>,
                                    title: 'Удалить',
                                    action: () => actions.domainDelete({ id: item.id }),
                                    confirm: {
                                        title: 'Уверены, что хотите удалить?',
                                        okText: 'Удалить',
                                        cancelText: 'Отмена'
                                    }
                                },
                            ]}

                        />
                    )
                }
            }
        ];

        return (
            <TableList
                history={history}
                defaultFilter={defaultFilter}
                activeFilter={activeFilter}
                data={domain}
                columns={columns}
                topActions={topActions}
                onLoadData={this.load}
                plural={['домен', 'домена', 'доменов']}
                childrenColumnName='mirrors'
            />
        );
    }
}


function mapStateToProps(state) {
    return {
        domain: state.domain,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.domain), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainList);
