import { BASE_URL } from "config";
import * as baseApi from "./_base";

export const endpoints = {
    list: `${BASE_URL}/api/v1/review/`,
    detail: (id) => `${BASE_URL}/api/v1/review/${id}/`,
};

export const reviewLoad = (token, payload) => baseApi.ListLoad(endpoints.list, token, payload);
export const reviewDetailLoad = (token, { id }) => baseApi.DetailLoad(endpoints.detail, token, { id });
export const reviewUpdate = (token, data) => baseApi.Update(endpoints.list, endpoints.detail, token, data);
export const reviewDelete = (token, { id }) => baseApi.Delete(endpoints.detail, token, { id });
