import React, { Component } from 'react';
import { Input, Col, Row } from "antd";

import { UserSelect } from 'components/blocks/user';
import { FormItem } from 'utils';


export default class MainFieldSet extends Component {
  render() {
    const { form, message, currentUserId } = this.props;
    const { updateStatus, editObject, showReplyForm } = message;
    const instance = showReplyForm ? null : editObject;
    let replyRecipient = null, replySubject = '';
    if (showReplyForm && editObject) {
      replyRecipient = editObject.sender !== currentUserId ? editObject.sender : editObject.recipient;
      replySubject = `Re: ${editObject.subject}`;
    }

    const error = updateStatus.error || {};
    const errorData = error.data || {};

    return (
      <Row gutter={16}>
        <Col span={24}>
          <div className="form-block">
            <Row gutter={16}>
              <Col span={12}>
                <UserSelect
                  form={form}
                  instance={instance}
                  name="sender"
                  label="Отправитель"
                  required={true}
                  errorData={errorData}
                  initialValue={currentUserId}
                />
              </Col>
              <Col span={12}>
                <UserSelect
                  form={form}
                  instance={instance}
                  name="recipient"
                  label="Получатель"
                  required={true}
                  errorData={errorData}
                  initialValue={replyRecipient}
                />
              </Col>
            </Row>
          </div>
        </Col>

        <Col span={24}>
          <div className="form-block">
            <FormItem
              form={form}
              instance={instance}
              name="subject"
              label="Тема"
              required={true}
              errorData={errorData}
              initialValue={replySubject}
            >
              <Input size="large"/>
            </FormItem>

            <FormItem
              form={form}
              instance={instance}
              name="body"
              label="Сообщение"
              required={true}
              errorData={errorData}
            >
              <Input.TextArea size="large" autosize={{ minRows: 3 }}/>
            </FormItem>

          </div>
        </Col>
      </Row>
    );
  }
}
