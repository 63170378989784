import React, { Component } from 'react';
import { Row, Col, Rate } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { OnOff, DateTimeText, FieldValue } from "components/common";
import { PageBlock } from "components/layout";
import { CityValue } from "components/blocks/geo";
import { ProfileValue } from "components/blocks/profile";


class ReviewDetailScreen extends Component {
    render() {
        const { review, actions } = this.props;
        const detail = review.detail || {};

        return (
            <div className="page-content page-content-full">
                <Row gutter={16}>
                    <Col span={15}>
                        <PageBlock>
                            <FieldValue label="Анкета" labelWidth={6}>
                                <ProfileValue id={detail.profile} mode={"link"}/>
                            </FieldValue>

                            <FieldValue label="Город" labelWidth={6}>
                                <CityValue id={detail.city} mode={"link"}/>
                            </FieldValue>

                            <FieldValue label="Рейтинг" labelWidth={6}>
                                <Rate style={{fontSize: '14px'}} disabled defaultValue={detail.rate} />
                            </FieldValue>

                            <FieldValue label="Имя" labelWidth={6}>
                                {detail.name}
                            </FieldValue>

                            <FieldValue label="Отзыв" labelWidth={6}>
                                {detail.text}
                            </FieldValue>

                            <FieldValue label="Опубликовано" labelWidth={6}>
                                <OnOff object={detail} param="is_published"
                                       onChange={data => actions.reviewUpdate({ data, preventLoadDetail: false })}/>
                            </FieldValue>
                        </PageBlock>
                    </Col>

                    <Col span={9}>
                        <PageBlock>
                            <FieldValue label="Дата встречи" labelWidth={12}>
                                <DateTimeText date={detail.meeting_date} onlyDate={true}/>
                            </FieldValue>

                            <FieldValue label="Длительность" labelWidth={12}>
                                {detail.meeting_length}ч
                            </FieldValue>

                            <FieldValue label="Стоимость" labelWidth={12}>
                                {detail.price}€
                            </FieldValue>
                        </PageBlock>
                    </Col>
                </Row>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        review: state.review,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.review), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReviewDetailScreen);
