import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { ProfileForm, ProfileList } from "components/blocks/profile";
import { SitePageForm } from 'components/blocks/site';


class ProfileListScreen extends Component {
    state = {
        profileFilterQuery: '',
    };

    componentDidMount() {
        this.tryRedirect();
    }

    tryRedirect() {
        const { site, location, history } = this.props;
        const profile_filter = (site.detail && site.detail.profile_filter) || '';

        if (profile_filter && profile_filter !== this.state.profileFilterQuery) {
            this.setState({
                profileFilterQuery: profile_filter,
            });

            history.push(location.pathname + profile_filter);
        }
    }

    render() {
        const { site, location, history } = this.props;
        const site_detail = site.detail || {};
        const profile_filter = site_detail.profile_filter || '';
        const show_list = !profile_filter || location.search;

        return (
            <Fragment>
                <div className="page-content page-content-full">
                    <div className="page-block page-block-full">
                        {site_detail.id && show_list &&
                        <ProfileList site_id={site_detail.id} history={history}/>
                        }
                    </div>
                </div>

                {site_detail.id &&
                <ProfileForm site_id={site_detail.id} city_id={site_detail.city}/>
                }

                <SitePageForm site_id={site_detail.id}/>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    return {
        site: state.site,
        profile: state.profile,
    };
}


export default connect(mapStateToProps)(ProfileListScreen);
