import React, { Component } from 'react';
import { Icon, Modal, Upload } from "antd";

import { cls, getUrl, arrayIsEqual, getHeaders } from 'utils';
import { endpoints } from 'store/api/file';
import styles from './upload.module.css';


export default class PhotoUpload extends Component {
    state = {
        previewVisible: false,
        previewImage: '',
        photos: [],
        fileList: [],
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (arrayIsEqual(nextProps.photos, prevState.photos)) {
            return null;
        }

        let newState = {
            photos: nextProps.photos,
        };

        if (prevState.fileList.length === 0) {
            newState.fileList = nextProps.photos.map(item => ({ uid: item.id, url: getUrl(item.url) }));
        }

        return newState
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    };

    handleChange = (info) => {
        const { onChange } = this.props;
        this.setState({ fileList: info.fileList });
        if (info.file.status === 'done') {
            onChange && onChange(info.fileList.map(item => {
                if (item.response) {
                    return item.response
                } else {
                    return item
                }
            }));
        }
    };

    render() {
        const { className, mode, title, auth_token } = this.props;
        const { previewVisible, previewImage, fileList } = this.state;

        let mainClasses = [styles.main];
        if (mode === 'single') mainClasses.push(styles.full);
        if (className) mainClasses.push(className);

        const headers = {
            'Authorization': `JWT ${auth_token}`,
        };

        return (
            <div className={cls(mainClasses)}>
                <Upload
                    className={mode === 'single' ? 'ant-upload-full' : ''}
                    listType="picture-card"
                    fileList={fileList}
                    headers={headers}
                    action={endpoints.upload}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                >
                    {(mode !== 'single' || fileList.length === 0) &&
                    <div>
                        <Icon className={styles.uploadIcon} type="plus"/>
                        <div className={styles.uploadText}>{title}</div>
                    </div>
                    }
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img style={{ width: '100%' }} src={previewImage} alt=""/>
                </Modal>
            </div>
        );
    }
}
