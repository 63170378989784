import React, { Component } from 'react';
import { Button, Icon, Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from "react-router";

import { actions } from "store";
import { Page, PageHeader } from 'components/layout';
import { ActionButton } from "components/common";
import { SiteForm } from 'components/blocks/site';
import SiteDetailScreen from './SiteDetailScreen';
import DomainListScreen from './DomainListScreen';
import PageListScreen from './PageListScreen';
import ProfileListScreen from './ProfileListScreen';
import SiteNavListScreen from './SiteNavListScreen';
import SeoTemplatePageListScreen from './SeoTemplatePageListScreen';


class SiteScreen extends Component {
    state = {
        id: null,
    };

    componentDidMount() {
        const { actions, match } = this.props;
        actions.siteDetailLoad({ id: match.params.id });
        this.setState({ id: match.params.id });
    }

    showEditForm = (item = null) => {
        const { actions } = this.props;
        actions.siteShowEditForm({ show: true, object: item });
    };

    delete = () => {
        const { actions } = this.props;
        const { id } = this.state;
        actions.siteDelete({ id });
    };

    onTabChange = (key) => {
        const { id } = this.state;
        this.props.history.push(`/site/${id}/${key}`);
    };

    render() {
        const { history, site } = this.props;
        const detail = site.detail || {};

        return (
            <Page>
                <Spin spinning={site.updateStatus.isLoading}>
                    <PageHeader
                        history={history}
                        title={detail.name}
                        actions={[
                            <Button onClick={() => this.showEditForm(detail)}>
                                <Icon type="edit"/>
                                <span>Редактировать</span>
                            </Button>,
                            <ActionButton
                                actions={[
                                    {
                                        key: 'delete',
                                        icon: <Icon type="delete"/>,
                                        title: 'Удалить',
                                        action: this.delete,
                                        confirm: {
                                            title: 'Уверены, что хотите удалить?',
                                            okText: 'Удалить',
                                            cancelText: 'Отмена'
                                        }
                                    },
                                ]}

                            />
                        ]}
                        basePath={`/site/${this.state.id}`}
                        tabs={[
                            { title: 'Детали', key: 'detail' },
                            { title: 'Домены', key: 'domains' },
                            { title: 'Навигация', key: 'nav' },
                            { title: 'SEO-шаблоны', key: 'seo_template_page' },
                            { title: 'Страницы', key: 'pages' },
                            //{ title: 'Анкеты', key: 'profiles' },
                        ]}
                    />

                    <Route exact path="/site/:id" component={SiteDetailScreen}/>
                    <Route exact path="/site/:id/detail" component={SiteDetailScreen}/>
                    <Route exact path="/site/:id/domains" component={DomainListScreen}/>
                    <Route exact path="/site/:id/nav" component={SiteNavListScreen}/>
                    <Route exact path="/site/:id/seo_template_page" component={SeoTemplatePageListScreen}/>
                    <Route exact path="/site/:id/pages" component={PageListScreen}/>
                    <Route exact path="/site/:id/profiles" component={ProfileListScreen}/>

                    <SiteForm/>
                </Spin>
            </Page>
        );
    }
}


function mapStateToProps(state) {
    return {
        site: state.site,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.site), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SiteScreen);
