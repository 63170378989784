import { put, call, takeEvery, select } from 'redux-saga/effects';
import { push } from "connected-react-router";

import * as base from "../BaseSagas";
import * as actions from './actions';
import * as api from 'store/api/site';


export function* watch() {
  yield takeEvery(actions.SITE_LOAD.START, actionSiteLoad);
  yield takeEvery(actions.SITE_DETAIL_LOAD.START, actionSiteDetailLoad);
  yield takeEvery(actions.SITE_UPDATE.START, actionSiteUpdate);
  yield takeEvery(actions.SITE_DELETE.START, actionSiteDelete);
  yield takeEvery(actions.SITE_GENERATE.START, actionSiteGenerate);
}


function* actionSiteLoad(action) {
  yield base.ListLoad(action, api.siteLoad,
    {
      request: actions.siteLoadRequest,
      complete: actions.siteLoadComplete,
      error: actions.siteLoadError
    },
  );
}

function* actionSiteDetailLoad(action) {
  yield base.DetailLoad(action, api.siteDetailLoad,
    {
      request: actions.siteDetailLoadRequest,
      complete: actions.siteDetailLoadComplete,
      error: actions.siteDetailLoadError
    },
  );
}

function* actionSiteUpdate(action) {
  const filter = yield select((state) => state.site.filter);
  yield base.Update(action, api.siteUpdate,
    {
      request: actions.siteUpdateRequest,
      complete: actions.siteUpdateComplete,
      error: actions.siteUpdateError,
      showEditForm: actions.siteShowEditForm,
      detailLoad: actions.siteDetailLoad,
      listLoad: actions.siteLoad,
    },
    '/site',
    filter,
  );
}

function* actionSiteDelete(action) {
  const filter = yield select((state) => state.site.filter);
  yield base.Delete(action, api.siteDelete,
    {
      request: actions.siteDeleteRequest,
      complete: actions.siteDeleteComplete,
      error: actions.siteDeleteError,
      listLoad: actions.siteLoad,
    },
    '/site',
    filter,
  );
}


function* actionSiteGenerate(action) {
  const token = yield select((state) => state.auth.token);

  yield put(actions.siteGenerateRequest());
  try {
    const { error, data, statusCode } = yield call(() => api.siteGenerate(token, action.payload));

    if (error) {
      yield put(actions.siteGenerateError({ data, statusCode }));
    } else {
      yield put(actions.siteGenerateComplete(data));
      yield put(actions.siteShowEditForm({ show: false }));
      yield put(actions.siteDetailLoad({ id: action.payload.id }));
      yield put(push(`/site/${action.payload.id}`));
    }

  } catch (error) {
    yield put(actions.siteGenerateError());
  }
}
