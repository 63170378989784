import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { ordering } from "utils";
import { TableList, ActionButton, DateTimeText } from 'components/common';
import { UserSelect, UserValue } from 'components/blocks/user';


class MessageList extends Component {
  load = (filter) => {
    const { actions, message } = this.props;
    let newFilter = Object.assign({}, filter);
    if (newFilter.activeFilter === undefined) {
      newFilter.activeFilter = message.filter.activeFilter
    }
    actions.messageLoad(newFilter);
  };

  render() {
    const { history, actions, defaultFilter, activeFilter, topActions, message } = this.props;
    const params = message.filter.params;

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 59,
      },
      {
        title: 'Тема',
        dataIndex: 'subject',
        key: 'subject',
        render: (val, item) => <Link to={`/message/${item.id}`}>{val}</Link>,

        sorter: true,
        sortOrder: ordering('name', params.ordering),
      },
      {
        title: 'Сообщение',
        dataIndex: 'body',
        key: 'body',
      },
      {
        title: 'Отправитель',
        dataIndex: 'sender',
        key: 'sender',
        render: (val, item) => <UserValue id={val}/>,
      },
      {
        title: 'Получатель',
        dataIndex: 'recipient',
        key: 'recipient',
        render: (val, item) => <UserValue id={val}/>,
      },
      {
        title: 'Дата отправки',
        dataIndex: 'sent_at',
        key: 'sent_at',
        width: 100,
        render: (val, item) => <DateTimeText date={val}/>,

        sorter: true,
        sortOrder: ordering('sent_at', params.ordering),
      },
      {
        title: 'Дата прочтения',
        dataIndex: 'read_at',
        key: 'read_at',
        width: 100,
        render: (val, item) => <DateTimeText date={val}/>,

        sorter: true,
        sortOrder: ordering('read_at', params.ordering),
      },
      {
        title: 'Ответов',
        dataIndex: 'replies',
        key: 'replies',
        width: 120,
        render: (val, item) => val.length,
      },

      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: 30,
        align: 'right',
        render: (val, item) => {
          return (
            <ActionButton
              actions={[
                {
                  key: 'edit',
                  icon: <Icon type="edit"/>,
                  title: 'Редактировать',
                  action: () => actions.messageShowEditForm({ show: true, object: item })
                },
                {
                  key: 'delete',
                  icon: <Icon type="delete"/>,
                  title: 'Удалить',
                  action: () => actions.messageDelete({ id: item.id }),
                  confirm: {
                    title: 'Уверены, что хотите удалить?',
                    okText: 'Удалить',
                    cancelText: 'Отмена'
                  }
                },
              ]}

            />
          )
        }
      }
    ];

    return (
      <TableList
        history={history}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        data={message}
        columns={columns}
        onLoadData={this.load}
        topActions={topActions}
        plural={['сообщение', 'сообщения', 'сообщений']}
        onRow={(item, rowIndex) => {
          return {
            onDoubleClick: event => {
              this.props.history.push(`/message/${item.id}`);
            },
          };
        }}

        filterFields={{
          list: [
            'sender', 'recipient'
          ],
          byName: {
            'sender': {
              label: 'Отправитель',
              type: 'list_int',
              component: UserSelect,
              valueComponent: UserValue,
            },
            'recipient': {
              label: 'Получатель',
              type: 'list_int',
              component: UserSelect,
              valueComponent: UserValue,
            },
          }
        }}
      />
    );
  }
}


function mapStateToProps(state) {
  return {
    message: state.message,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      actions.message,
    ), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
