import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from "connected-react-router";
import { Menu, Icon } from 'antd';

import styles from './nav.module.css';


class Navigation extends Component {
    navigate(path) {
        this.props.dispatch(push(path));
    }

    getCurrentRootNav() {
        const { pathname } = this.props.history.location;
        return pathname.split('/')[1];
    }

    getCurrentNav(currentRootNav) {
        const { pathname } = this.props.history.location;
        return `${currentRootNav}-${pathname.split('/')[2]}`;
    }

    render() {
        const currentRootNav = this.getCurrentRootNav();

        return (
            <Menu
                theme="dark"
                mode="inline"
                defaultOpenKeys={[currentRootNav]}
                defaultSelectedKeys={[currentRootNav]}
                className={styles.menu}
            >
                <Menu.ItemGroup title="Страны и города">
                    <Menu.Item
                        key={`country`}
                        onClick={() => this.navigate(`/country`)}
                    >
                        <Icon type="share-alt"/>
                        <span>Страны</span>
                    </Menu.Item>
                </Menu.ItemGroup>

                <Menu.ItemGroup title="Сайты">
                    <Menu.Item
                        key={`site`}
                        onClick={() => this.navigate(`/site`)}
                    >
                        <Icon type="share-alt"/>
                        <span>Сайты</span>
                    </Menu.Item>

                    <Menu.Item
                        key={`domain`}
                        onClick={() => this.navigate(`/domain`)}
                    >
                        <Icon type="share-alt"/>
                        <span>Домены</span>
                    </Menu.Item>
                </Menu.ItemGroup>

                <Menu.ItemGroup title="Анкеты">
                    <Menu.Item
                        key={`agency`}
                        onClick={() => this.navigate(`/agency`)}
                    >
                        <Icon type="share-alt"/>
                        <span>Агентства</span>
                    </Menu.Item>

                    <Menu.Item
                        key={`profile`}
                        onClick={() => this.navigate(`/profile`)}
                    >
                        <Icon type="share-alt"/>
                        <span>Анкеты</span>
                    </Menu.Item>

                    <Menu.Item
                        key={`service`}
                        onClick={() => this.navigate(`/service`)}
                    >
                        <Icon type="share-alt"/>
                        <span>Услуги</span>
                    </Menu.Item>

                    <Menu.Item
                        key={`review`}
                        onClick={() => this.navigate(`/review`)}
                    >
                        <Icon type="share-alt"/>
                        <span>Отзывы</span>
                    </Menu.Item>
                </Menu.ItemGroup>

                <Menu.ItemGroup title="Пользователи">
                    <Menu.Item
                        key={`user`}
                        onClick={() => this.navigate(`/user`)}
                    >
                        <Icon type="share-alt"/>
                        <span>Пользователи</span>
                    </Menu.Item>

                    <Menu.Item
                        key={`message`}
                        onClick={() => this.navigate(`/message`)}
                    >
                        <Icon type="share-alt"/>
                        <span>Сообщения</span>
                    </Menu.Item>

                    <Menu.Item
                        key={`balance`}
                        onClick={() => this.navigate(`/balance`)}
                    >
                        <Icon type="share-alt"/>
                        <span>Баланс</span>
                    </Menu.Item>
                </Menu.ItemGroup>
            </Menu>
        );
    }
}


function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(Navigation);
