import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from "store";
import { DateTimeText, FieldValue } from "components/common";
import { PageBlock } from "components/layout";
import { UserValue } from "components/blocks/user";
import { ReplyList } from "components/blocks/message";


class MessageDetailScreen extends Component {
    render() {
        const { message } = this.props;
        const detail = message.detail || {};

        return (
            <div className="page-content page-content-full">
                <Row gutter={16}>
                    <Col span={15}>
                        <PageBlock>
                            <FieldValue label="Отправитель" labelWidth={6}>
                                <UserValue id={detail.sender} mode={"link"}/>
                            </FieldValue>

                            <FieldValue label="Получатель" labelWidth={6}>
                                <UserValue id={detail.recipient} mode={"link"}/>
                            </FieldValue>

                            <FieldValue label="Сообщение" labelWidth={6}>
                                {detail.body}
                            </FieldValue>
                        </PageBlock>
                    </Col>

                    <Col span={9}>
                        <PageBlock>
                            <FieldValue label="Дата отправки" labelWidth={12}>
                                <DateTimeText date={detail.sent_at}/>
                            </FieldValue>

                            <FieldValue label="Дата прочтения" labelWidth={12}>
                                <DateTimeText date={detail.read_at}/>
                            </FieldValue>
                        </PageBlock>
                    </Col>
                </Row>

                <br/>

                <PageBlock>
                    <ReplyList dataSource={detail.replies}/>
                </PageBlock>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        message: state.message,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, actions.message), dispatch),
        dispatch: dispatch
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MessageDetailScreen);
