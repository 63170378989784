import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Switch } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { ordering } from "utils";
import { TableList, ActionButton, DateTimeText } from 'components/common';


class UserList extends Component {
    load = (filter) => {
        const { actions, user } = this.props;
        let newFilter = Object.assign({}, filter);
        if (newFilter.activeFilter === undefined) {
            newFilter.activeFilter = user.filter.activeFilter
        }
        actions.userLoad(newFilter);
    };

    render() {
        const { history, actions, defaultFilter, activeFilter, topActions, user } = this.props;
        const params = user.filter.params;

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 59,
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                key: 'email',
                render: (val, item) => <Link to={`/user/${item.id}`}>{val || item.username}</Link>,

                sorter: true,
                sortOrder: ordering('email', params.ordering),
            },
            {
                title: 'Имя',
                dataIndex: 'first_name',
                key: 'first_name',
                width: 150,

                sorter: true,
                sortOrder: ordering('first_name', params.ordering),
            },
            {
                title: 'Фамилия',
                dataIndex: 'last_name',
                key: 'last_name',
                width: 150,

                sorter: true,
                sortOrder: ordering('last_name', params.ordering),
            },
            {
                title: 'Дата регистрации',
                dataIndex: 'date_joined',
                key: 'date_joined',
                width: 150,
                render: (val, item) => <DateTimeText date={val}/>
            },
            {
                title: 'Роль',
                dataIndex: 'role',
                key: 'role',
                width: 150,
                render: (val, item) => val,
            },
            {
                title: 'Активен',
                dataIndex: 'is_active',
                key: 'is_active',
                width: 120,
                render: (val, item) => <div>
                    <Switch
                        style={{ marginTop: '5px', minWidth: '62px' }}
                        checkedChildren="вкл" unCheckedChildren="выкл"
                        checked={val}
                        onClick={() => {
                            const data = { id: item.id, is_active: !item.is_active };
                            actions.userUpdate({ data, preventLoadDetail: true })
                        }}
                    />
                </div>,
            },

            {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                width: 30,
                align: 'right',
                render: (val, item) => {
                    return (
                        <ActionButton
                            actions={[
                                {
                                    key: 'edit',
                                    icon: <Icon type="edit"/>,
                                    title: 'Редактировать',
                                    action: () => actions.userShowEditForm({ show: true, object: item })
                                },
                                {
                                    key: 'delete',
                                    icon: <Icon type="delete"/>,
                                    title: 'Удалить',
                                    action: () => actions.userDelete({ id: item.id }),
                                    confirm: {
                                        title: 'Уверены, что хотите удалить?',
                                        okText: 'Удалить',
                                        cancelText: 'Отмена'
                                    }
                                },
                            ]}

                        />
                    )
                }
            }
        ];

        return (
            <TableList
                history={history}
                defaultFilter={defaultFilter}
                activeFilter={activeFilter}
                data={user}
                columns={columns}
                onLoadData={this.load}
                topActions={topActions}
                plural={['анкета', 'анкеты', 'анкет']}
                onRow={(item, rowIndex) => {
                    return {
                        onDoubleClick: event => {
                            this.props.history.push(`/user/${item.id}`);
                        },
                    };
                }}

                filterFields={{
                    list: [
                        'role', 'is_active', 'is_support'
                    ],
                    byName: {
                        'role': {
                            label: 'Роль',
                            type: 'list',
                            options: [
                                { label: 'участник', value: 'member' },
                                { label: 'индивидуалка', value: 'girl' },
                                { label: 'агентство', value: 'agency' },
                                { label: 'менеджер', value: 'manager' },
                            ]
                        },

                        'is_active': {
                            label: 'Активно',
                            type: 'bool',
                        },
                        'is_support': {
                            label: 'Поддержка',
                            type: 'bool',
                        },
                        'has_video': {
                            label: 'С видео',
                            type: 'bool',
                        },
                    }
                }}
            />
        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({},
            actions.user,
        ), dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
