import React, { Component } from 'react';

import { LoginForm } from 'components/common';
import styles from './screen.module.css';


export default class LoginScreen extends Component {
    render() {
        return (
            <div className={styles.main}>
                <div className={styles.panel}>
                    <div>
                        <div className={styles.title}>Вход</div>
                        <LoginForm/>
                    </div>
                </div>
            </div>
        );
    }
}
