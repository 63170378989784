import { handleActions } from 'redux-actions';

import * as actions from './actions';
import * as base from "../BaseReducers";

const initialState = Object.assign({
    showReplyForm: false,
  },
  base.ListState,
  base.DetailState,
  base.EditState,
);

export default handleActions(
  {
    [actions.messageShowEditForm]: (state, action) => ({
        ...state,
        showEditForm: action.payload.show,
        showReplyForm: action.payload.show,
        editObject: action.payload.object,
    }),
    [actions.messageShowReplyForm]: (state, action) => ({
      ...state,
      showReplyForm: action.payload.show,
      editObject: action.payload.object,
    }),

    [actions.messageLoadRequest]: (state, action) => base.listLoadRequest(state, action),
    [actions.messageLoadComplete]: (state, action) => base.listLoadComplete(state, action),
    [actions.messageLoadError]: (state, action) => base.listLoadError(state, action),

    [actions.messageDetailLoadRequest]: (state, action) => base.detailLoadRequest(state, action),
    [actions.messageDetailLoadComplete]: (state, action) => base.detailLoadComplete(state, action),
    [actions.messageDetailLoadError]: (state, action) => base.detailLoadError(state, action),

    [actions.messageUpdateRequest]: (state, action) => base.updateRequest(state, action),
    [actions.messageUpdateComplete]: (state, action) => base.updateComplete(state, action),
    [actions.messageUpdateError]: (state, action) => base.updateError(state, action),

    [actions.messageDeleteRequest]: (state, action) => base.deleteRequest(state, action),
    [actions.messageDeleteComplete]: (state, action) => base.deleteComplete(state, action),
    [actions.messageDeleteError]: (state, action) => base.deleteError(state, action),
  },
  initialState
);
