import React, { Component } from 'react';
import { Tabs } from 'antd';
import './styles.css';


export default class PageHeaderTabs extends Component {
    state = {
        activeTab: 'detail',
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const { history, basePath } = nextProps;
        let path = history.location.pathname.replace(basePath, '');
        const parts = path.split('/');
        const activeTab = parts.length >= 2 ? parts[parts.length - 1] || 'detail': 'detail';

        if (prevState.activeTab !== activeTab) {
            return {
                activeTab: activeTab,
            };
        }

        return null;
    };

    onTabChange = (key) => {
        if (this.props.onTabChange) {
            this.props.onTabChange(key);
        } else {
            const { basePath } = this.props;
            const url = key === 'detail' ? basePath : `${basePath}/${key}`;
            this.props.history.push(url);
        }
    };

    render() {
        const { tabs } = this.props;
        const { activeTab } = this.state;

        return (
            <Tabs className="page-header-tabs" activeKey={activeTab} onChange={this.onTabChange}>
                {tabs.map(item =>
                    <Tabs.TabPane
                        tab={(<div>{item.title} {item.count && <span>{item.count}</span>}</div>)}
                        key={item.key}
                    />
                )}
            </Tabs>
        );
    }
}
