import React, { Component } from 'react';

import { ServiceTransfer } from 'components/blocks/service';


export default class ServiceFieldSet extends Component {
    render() {
        const { form, profile } = this.props;
        const { updateStatus, editObject } = profile;
        const instance = editObject;

        const error = updateStatus.error || {};
        const errorData = error.data || {};

        return (
            <div className="form-block">
                <ServiceTransfer
                    form={form}
                    instance={instance}
                    name="services"
                    label="Услуги"
                    required={false}
                    errorData={errorData}
                    valuePropName='targetKeys'
                    convertValue={value => {
                        if (Array.isArray(value)) {
                            return value;
                        }
                        return [];
                    }}
                />
            </div>
        );
    }
}
