import React, { PureComponent } from 'react';

import { parseDate } from "utils";
import styles from './datetime.module.css';


export default class DateTimeText extends PureComponent {
    render() {
        const { date, onlyDate } = this.props;
        let text = '-';

        if (date) {
            if (onlyDate) {
                text = parseDate(date).format('DD.MM.YYYY');
            } else {
                text = parseDate(date).format('DD.MM.YYYY HH:mm');
            }
        }

        return (
            <div className={styles.text}>{text}</div>
        );
    }
}
