import React, { Component } from 'react';
import { Col, Row, Input } from "antd";

import { FormItem } from 'utils';
import styles from './fieldset.module.css';


export default class TariffFieldSet extends Component {
    render() {
        const { form, profile } = this.props;
        const { updateStatus, editObject } = profile;
        const instance = editObject;

        const error = updateStatus.error || {};
        const errorData = error.data || {};

        return (
            <Row gutter={32}>
                <Col span={12}>
                    <div className="form-block">
                        <div className={styles.fieldsetTitle}>Апартаменты</div>

                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.in_hour"
                                    label="1 час"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} addonAfter="€"/>
                                </FormItem>
                            </Col>

                            <Col span={16}>
                                <div className={styles.emptyLabel}/>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.in_hour_note"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} placeholder="комментарий"/>
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                  form={form}
                                  instance={instance}
                                  name="prices.in_hour15"
                                  label="1.5 часа"
                                  required={false}
                                  errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} addonAfter="€"/>
                                </FormItem>
                            </Col>

                            <Col span={16}>
                                <div className={styles.emptyLabel}/>
                                <FormItem
                                  form={form}
                                  instance={instance}
                                  name="prices.in_hour15_note"
                                  required={false}
                                  errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} placeholder="комментарий"/>
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.in_hour2"
                                    label="2 часа"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} addonAfter="€"/>
                                </FormItem>
                            </Col>

                            <Col span={16}>
                                <div className={styles.emptyLabel}/>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.in_hour2_note"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} placeholder="комментарий"/>
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.in_night"
                                    label="Ночь"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} addonAfter="€"/>
                                </FormItem>
                            </Col>

                            <Col span={16}>
                                <div className={styles.emptyLabel}/>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.in_night_note"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} placeholder="комментарий"/>
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.in_express"
                                    label="Экспресс"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} addonAfter="€"/>
                                </FormItem>
                            </Col>

                            <Col span={16}>
                                <div className={styles.emptyLabel}/>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.in_express_note"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} placeholder="комментарий"/>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col span={12}>
                    <div className="form-block">
                        <div className={styles.fieldsetTitle}>Выезд</div>

                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.out_hour"
                                    label="1 час"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} addonAfter="€"/>
                                </FormItem>
                            </Col>

                            <Col span={16}>
                                <div className={styles.emptyLabel}/>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.out_hour_note"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} placeholder="комментарий"/>
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                  form={form}
                                  instance={instance}
                                  name="prices.out_hour15"
                                  label="1.5 часа"
                                  required={false}
                                  errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} addonAfter="€"/>
                                </FormItem>
                            </Col>

                            <Col span={16}>
                                <div className={styles.emptyLabel}/>
                                <FormItem
                                  form={form}
                                  instance={instance}
                                  name="prices.out_hour15_note"
                                  required={false}
                                  errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} placeholder="комментарий"/>
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.out_hour2"
                                    label="2 часа"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} addonAfter="€"/>
                                </FormItem>
                            </Col>

                            <Col span={16}>
                                <div className={styles.emptyLabel}/>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.out_hour2_note"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} placeholder="комментарий"/>
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.out_night"
                                    label="Ночь"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} addonAfter="€"/>
                                </FormItem>
                            </Col>

                            <Col span={16}>
                                <div className={styles.emptyLabel}/>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.out_night_note"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} placeholder="комментарий"/>
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col span={8}>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.out_express"
                                    label="Экспресс"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} addonAfter="€"/>
                                </FormItem>
                            </Col>

                            <Col span={16}>
                                <div className={styles.emptyLabel}/>
                                <FormItem
                                    form={form}
                                    instance={instance}
                                    name="prices.out_express_note"
                                    required={false}
                                    errorData={errorData}
                                >
                                    <Input size="large" style={{ width: '100%' }} placeholder="комментарий"/>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        );
    }
}
