import React, { PureComponent } from 'react';
import { Switch } from "antd";


export default class OnOff extends PureComponent {
    render() {
        const { object, param, onChange } = this.props;

        return (
            <Switch
                checkedChildren="вкл" unCheckedChildren="выкл"
                checked={object[param]}
                onClick={() => {
                    const data = { id: object.id, [param]: !object[param] };
                    onChange(data);
                }}
            />
        );
    }
}
