import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const SITE_LOAD = createRequestTypes('SITE_LOAD');
export const SITE_DETAIL_LOAD = createRequestTypes('SITE_DETAIL_LOAD');
export const SITE_UPDATE = createRequestTypes('SITE_UPDATE');
export const SITE_DELETE = createRequestTypes('SITE_DELETE');
export const SITE_GENERATE = createRequestTypes('SITE_GENERATE');
export const SITE_SHOW_EDIT_FORM = 'SITE_SHOW_EDIT_FORM';

export const siteShowEditForm = createAction(SITE_SHOW_EDIT_FORM);

export const siteLoad = createAction(SITE_LOAD.START);
export const siteLoadRequest = createAction(SITE_LOAD.REQUEST);
export const siteLoadComplete = createAction(SITE_LOAD.COMPLETE);
export const siteLoadError = createAction(SITE_LOAD.ERROR);

export const siteDetailLoad = createAction(SITE_DETAIL_LOAD.START);
export const siteDetailLoadRequest = createAction(SITE_DETAIL_LOAD.REQUEST);
export const siteDetailLoadComplete = createAction(SITE_DETAIL_LOAD.COMPLETE);
export const siteDetailLoadError = createAction(SITE_DETAIL_LOAD.ERROR);

export const siteUpdate = createAction(SITE_UPDATE.START);
export const siteUpdateRequest = createAction(SITE_UPDATE.REQUEST);
export const siteUpdateComplete = createAction(SITE_UPDATE.COMPLETE);
export const siteUpdateError = createAction(SITE_UPDATE.ERROR);

export const siteDelete = createAction(SITE_DELETE.START);
export const siteDeleteRequest = createAction(SITE_DELETE.REQUEST);
export const siteDeleteComplete = createAction(SITE_DELETE.COMPLETE);
export const siteDeleteError = createAction(SITE_DELETE.ERROR);

export const siteGenerate = createAction(SITE_GENERATE.START);
export const siteGenerateRequest = createAction(SITE_GENERATE.REQUEST);
export const siteGenerateComplete = createAction(SITE_GENERATE.COMPLETE);
export const siteGenerateError = createAction(SITE_GENERATE.ERROR);
