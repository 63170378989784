import React, { Component } from 'react';
import { Layout, Icon, Breadcrumb } from "antd";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getBreadcrumbs } from "utils";
import { HeaderUserPanel } from 'components/common';
import styles from './header.module.css';


const routes = [
    { path: '/', title: ({ id, detail }) => 'Home' },
    { path: '/country', title: ({ id, detail }) => 'Страны' },
    {
        path: '/country/:id',
        title: ({ id, detail }) => (detail && detail.name_en) || id,
        detail: ({ id, state }) => state.geo_country && (state.geo_country.byId[id] || state.geo_country.detail)
    },

    { path: '/site', title: ({ id, detail }) => 'Сайты' },
    {
        path: '/site/:id',
        title: ({ id, detail }) => (detail && detail.name) || id,
        detail: ({ id, state }) => state.site && (state.site.byId[id] || state.site.detail)
    },

    { path: '/domain', title: ({ id, detail }) => 'Домены' },
    {
        path: '/domain/:id',
        title: ({ id, detail }) => (detail && detail.domain) || id,
        detail: ({ id, state }) => state.domain && (state.domain.byId[id] || state.domain.detail)
    },

    { path: '/service', title: ({ id, detail }) => 'Услуги' },

    { path: '/agency', title: ({ id, detail }) => 'Агентства' },
    {
        path: '/agency/:id',
        title: ({ id, detail }) => (detail && detail.name) || id,
        detail: ({ id, state }) => state.agency && (state.agency.byId[id] || state.agency.detail)
    },

    { path: '/profile', title: ({ id, detail }) => 'Анкеты' },
    {
        path: '/profile/:id',
        title: ({ id, detail }) => (detail && detail.name) || id,
        detail: ({ id, state }) => state.profile && (state.profile.byId[id] || state.profile.detail)
    },

    { path: '/user', title: ({ id, detail }) => 'Пользователи' },
    {
        path: '/user/:id',
        title: ({ id, detail }) => (detail && detail.email) || id,
        detail: ({ id, state }) => state.user && (state.user.byId[id] || state.user.detail)
    },

    { path: '/balance', title: ({ id, detail }) => 'Балансы пользователей' },
    {
        path: '/balance/:id',
        title: ({ id, detail }) => (detail && detail.user.email) || id,
        detail: ({ id, state }) => state.balance && (state.balance.byId[id] || state.balance.detail)
    },

    { path: '/review', title: ({ id, detail }) => 'Отзывы' },
    {
        path: '/review/:id',
        title: ({ id, detail }) => (detail && detail.name) || id,
        detail: ({ id, state }) => state.review && (state.review.byId[id] || state.review.detail)
    },

    { path: '/message', title: ({ id, detail }) => 'Сообщения' },
    {
        path: '/message/:id',
        title: ({ id, detail }) => (detail && detail.subject) || id,
        detail: ({ id, state }) => state.message && (state.message.byId[id] || state.message.detail)
    },

    { path: '/transaction', title: ({ id, detail }) => 'Транзакции' },
];


class Header extends Component {
    itemRender = (item, params, routes, paths) => {
        const detail = item.detail ? item.detail({ id: item.match.params.id, state: this.props }) : null;
        const _params = Object.assign({}, item.match.params, { detail });

        const icon = item.icon;
        const title = item.title(_params);

        const icon_comp = icon && <Icon type={icon}/>;
        const title_comp = title && <span>{title}</span>;

        return <Link to={item.path}>{icon_comp}{title_comp}<span className="arrow"/></Link>;
    };

    render() {
        const { history } = this.props;

        const breadcrumbs = getBreadcrumbs({ routes, location: history.location });

        return (
            <Layout.Header className={styles.header}>
                <Breadcrumb className={styles.headerLeft} itemRender={this.itemRender} routes={breadcrumbs}/>

                <div className={styles.headerRight}>
                    <HeaderUserPanel/>
                </div>
            </Layout.Header>
        );
    }
}


function mapStateToProps(state) {
    return {
        geo_country: state.geo_country,
        site: state.site,
        domain: state.domain,
        template: state.template,
        profile: state.profile,
        balance: state.balance,
        user: state.user,
        review: state.review,
        message: state.message,
    };
}

export default connect(mapStateToProps)(Header);
