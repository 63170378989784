import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Rate } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";
import { ordering } from "utils";
import { TableList, ActionButton, OnOff } from 'components/common';
import { CitySelect, CityValue } from 'components/blocks/geo';
import { ProfileSelect, ProfileValue } from 'components/blocks/profile';


class ReviewList extends Component {
  load = (filter) => {
    const { actions, review } = this.props;
    let newFilter = Object.assign({}, filter);
    if (newFilter.activeFilter === undefined) {
      newFilter.activeFilter = review.filter.activeFilter
    }
    actions.reviewLoad(newFilter);
  };

  render() {
    const { history, actions, defaultFilter, activeFilter, topActions, review } = this.props;
    const params = review.filter.params;

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 59,
      },
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name',
        render: (val, item) => <Link to={`/review/${item.id}`}>{val}</Link>,
        width: 150,

        sorter: true,
        sortOrder: ordering('name', params.ordering),
      },
      {
        title: 'Анкета',
        dataIndex: 'profile',
        key: 'profile',
        render: (val, item) => <ProfileValue id={val}/>,
        width: 150,
      },
      /*{
        title: 'Город',
        dataIndex: 'city',
        key: 'city',
        render: (val, item) => <CityValue id={val}/>,
      },*/
      {
        title: 'Отзыв',
        dataIndex: 'text',
        key: 'text',
      },
      {
        title: 'Рейтинг',
        dataIndex: 'rate',
        key: 'rate',
        width: 100,
        render: (val, item) => <Rate style={{ fontSize: '10px' }} disabled defaultValue={val}/>,

        sorter: true,
        sortOrder: ordering('rate', params.ordering),
      },
      /*{
        title: 'Дата встречи',
        dataIndex: 'meeting_date',
        key: 'meeting_date',
        width: 100,
        render: (val, item) => <DateTimeText date={val} onlyDate={true}/>,

        sorter: true,
        sortOrder: ordering('meeting_date', params.ordering),
      },
      {
        title: 'Длит.',
        dataIndex: 'meeting_length',
        key: 'meeting_length',
        width: 80,
        render: (val, item) => `${val}ч`,
      },
      {
        title: 'Цена',
        dataIndex: 'price',
        key: 'price',
        width: 100,
        render: (val, item) => `${val}€`,
      },*/
      {
        title: 'Опубликовано',
        dataIndex: 'is_published',
        key: 'is_published',
        width: 120,
        render: (val, item) => (
          <OnOff
            object={item} param="is_published"
            onChange={data => actions.reviewUpdate({ data, preventLoadDetail: true })}
          />
        ),
      },

      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: 30,
        align: 'right',
        render: (val, item) => {
          return (
            <ActionButton
              actions={[
                {
                  key: 'edit',
                  icon: <Icon type="edit"/>,
                  title: 'Редактировать',
                  action: () => actions.reviewShowEditForm({ show: true, object: item })
                },
                {
                  key: 'delete',
                  icon: <Icon type="delete"/>,
                  title: 'Удалить',
                  action: () => actions.reviewDelete({ id: item.id }),
                  confirm: {
                    title: 'Уверены, что хотите удалить?',
                    okText: 'Удалить',
                    cancelText: 'Отмена'
                  }
                },
              ]}

            />
          )
        }
      }
    ];

    return (
      <TableList
        history={history}
        defaultFilter={defaultFilter}
        activeFilter={activeFilter}
        data={review}
        columns={columns}
        onLoadData={this.load}
        topActions={topActions}
        plural={['анкета', 'анкеты', 'анкет']}
        onRow={(item, rowIndex) => {
          return {
            onDoubleClick: event => {
              this.props.history.push(`/review/${item.id}`);
            },
          };
        }}

        filterFields={{
          list: [
            'city', 'profile', 'is_published'
          ],
          byName: {
            'city': {
              label: 'Город',
              type: 'list_int',
              component: CitySelect,
              valueComponent: CityValue,
            },
            'profile': {
              label: 'Анкета',
              type: 'list_int',
              component: ProfileSelect,
              valueComponent: ProfileValue,
            },
            'is_published': {
              label: 'Опубликовано',
              type: 'bool',
            },
          }
        }}
      />
    );
  }
}


function mapStateToProps(state) {
  return {
    review: state.review,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      actions.review,
    ), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewList);
