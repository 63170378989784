import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Table } from 'antd';

import { getObjectList, getUrlParams, getUrlSearch, dictIsEqual } from "utils";
import { TableTop, Filter } from 'components/common';


class TableList extends Component {
    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!dictIsEqual(this.props.activeFilter, prevProps.activeFilter)) {
            console.log('componentDidUpdate');
            this.loadData(true)
        }
    }

    loadData = (changedStatus = false) => {
        const { history, data, defaultFilter, activeFilter } = this.props;
        let query = '';
        if (!changedStatus) {
            query = data.filter.query;
            if (query === null) {
                if (!history.location.search) {
                    query = `?${getUrlSearch(defaultFilter)}`;
                } else {
                    query = history.location.search;
                }
            }
        }
        
        this.props.onLoadData({ query, activeFilter });
    };

    onChange = (pagination, filters, sorter) => {
        const { data, activeFilter } = this.props;
        const params = getUrlParams(data.filter.query || '');
    
        const order = o => o === 'descend' ? '-' : '';
        const ordering = sorter.field ? `${order(sorter.order)}${sorter.field}` : '';

        if (ordering) {
            params['ordering'] = ordering;
        } else {
            delete params['ordering'];
        }

        let offset = (pagination.current - 1) * pagination.pageSize;
        if (offset) {
            params['offset'] = offset;
        } else {
            delete params['offset'];
        }
        
        const query2 = `?${getUrlSearch(params)}`;
        this.props.onLoadData({ query: query2, activeFilter });
    };

    render() {
        const { history, data, columns, filterFields, onLoadData, topActions, ...props } = this.props;
        if (!props.dataSource) {
            props.dataSource = getObjectList(data);
        }

        const { loadStatus, pageSize, count, filter } = data;

        const offset = parseInt(filter.params.offset) || 0;
        const endIndex = Math.min(offset+pageSize, count);

        return (
            <div className="page-block-content">
                <TableTop onLoadData={onLoadData} data={data} filterFields={filterFields} topActions={topActions}/>

                <Table
                    columns={columns}
                    rowKey="id"
                    loading={loadStatus.isLoading}
                    pagination={{ pageSize: pageSize, total: count, current: offset / pageSize + 1 }}
                    onChange={this.onChange}
                    {...props}
                />
                <div className="table-stat">
                    Показано c {offset+1} по {endIndex} из {count}
                </div>

                {filterFields &&
                <Filter onLoadData={onLoadData} data={data} fields={filterFields}/>}
            </div>
        );
    }
}

export default withRouter(TableList);