import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "store";

import { ActionButton, DateTimeText } from 'components/common';
import { UserValue } from 'components/blocks/user';


class ReplyList extends Component {
  render() {
    const { actions, dataSource } = this.props;

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 59,
      },
      {
        title: 'Тема',
        dataIndex: 'subject',
        key: 'subject',
        render: (val, item) => <Link to={`/message/${item.id}`}>{val}</Link>,
      },
      {
        title: 'Сообщение',
        dataIndex: 'body',
        key: 'body',
      },
      {
        title: 'Отправитель',
        dataIndex: 'sender',
        key: 'sender',
        render: (val, item) => <UserValue id={val}/>,
      },
      {
        title: 'Получатель',
        dataIndex: 'recipient',
        key: 'recipient',
        render: (val, item) => <UserValue id={val}/>,
      },
      {
        title: 'Дата отправки',
        dataIndex: 'sent_at',
        key: 'sent_at',
        width: 100,
        render: (val, item) => <DateTimeText date={val}/>,
      },
      {
        title: 'Дата прочтения',
        dataIndex: 'read_at',
        key: 'read_at',
        width: 100,
        render: (val, item) => <DateTimeText date={val}/>,
      },

      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: 30,
        align: 'right',
        render: (val, item) => {
          return (
            <ActionButton
              actions={[
                {
                  key: 'edit',
                  icon: <Icon type="edit"/>,
                  title: 'Редактировать',
                  action: () => actions.messageShowEditForm({ show: true, object: item })
                },
                {
                  key: 'delete',
                  icon: <Icon type="delete"/>,
                  title: 'Удалить',
                  action: () => actions.messageDelete({ id: item.id }),
                  confirm: {
                    title: 'Уверены, что хотите удалить?',
                    okText: 'Удалить',
                    cancelText: 'Отмена'
                  }
                },
              ]}

            />
          )
        }
      }
    ];

    return (
      <Table
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
      />
    );
  }
}


function mapStateToProps(state) {
  return {
    message: state.message,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({},
      actions.message,
    ), dispatch),
    dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplyList);
