import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const DOMAIN_LOAD = createRequestTypes('DOMAIN_LOAD');
export const DOMAIN_DETAIL_LOAD = createRequestTypes('DOMAIN_DETAIL_LOAD');
export const DOMAIN_UPDATE = createRequestTypes('DOMAIN_UPDATE');
export const DOMAIN_DELETE = createRequestTypes('DOMAIN_DELETE');
export const DOMAIN_SHOW_EDIT_FORM = 'DOMAIN_SHOW_EDIT_FORM';
export const DOMAIN_MAKE_MAIN = createRequestTypes('DOMAIN_MAKE_MAIN');

export const domainShowEditForm = createAction(DOMAIN_SHOW_EDIT_FORM);

export const domainLoad = createAction(DOMAIN_LOAD.START);
export const domainLoadRequest = createAction(DOMAIN_LOAD.REQUEST);
export const domainLoadComplete = createAction(DOMAIN_LOAD.COMPLETE);
export const domainLoadError = createAction(DOMAIN_LOAD.ERROR);

export const domainDetailLoad = createAction(DOMAIN_DETAIL_LOAD.START);
export const domainDetailLoadRequest = createAction(DOMAIN_DETAIL_LOAD.REQUEST);
export const domainDetailLoadComplete = createAction(DOMAIN_DETAIL_LOAD.COMPLETE);
export const domainDetailLoadError = createAction(DOMAIN_DETAIL_LOAD.ERROR);

export const domainUpdate = createAction(DOMAIN_UPDATE.START);
export const domainUpdateRequest = createAction(DOMAIN_UPDATE.REQUEST);
export const domainUpdateComplete = createAction(DOMAIN_UPDATE.COMPLETE);
export const domainUpdateError = createAction(DOMAIN_UPDATE.ERROR);

export const domainDelete = createAction(DOMAIN_DELETE.START);
export const domainDeleteRequest = createAction(DOMAIN_DELETE.REQUEST);
export const domainDeleteComplete = createAction(DOMAIN_DELETE.COMPLETE);
export const domainDeleteError = createAction(DOMAIN_DELETE.ERROR);

export const domainMakeMain = createAction(DOMAIN_MAKE_MAIN.START);
export const domainMakeMainRequest = createAction(DOMAIN_MAKE_MAIN.REQUEST);
export const domainMakeMainComplete = createAction(DOMAIN_MAKE_MAIN.COMPLETE);
export const domainMakeMainError = createAction(DOMAIN_MAKE_MAIN.ERROR);
