import { createAction } from 'redux-actions';
import { createRequestTypes } from 'utils';

export const SITE_NAV_LOAD = createRequestTypes('SITE_NAV_LOAD');
export const SITE_NAV_DETAIL_LOAD = createRequestTypes('SITE_NAV_DETAIL_LOAD');
export const SITE_NAV_UPDATE = createRequestTypes('SITE_NAV_UPDATE');
export const SITE_NAV_DELETE = createRequestTypes('SITE_NAV_DELETE');
export const SITE_NAV_SHOW_EDIT_FORM = 'SITE_NAV_SHOW_EDIT_FORM';

export const siteNavShowEditForm = createAction(SITE_NAV_SHOW_EDIT_FORM);

export const siteNavLoad = createAction(SITE_NAV_LOAD.START);
export const siteNavLoadRequest = createAction(SITE_NAV_LOAD.REQUEST);
export const siteNavLoadComplete = createAction(SITE_NAV_LOAD.COMPLETE);
export const siteNavLoadError = createAction(SITE_NAV_LOAD.ERROR);

export const siteNavDetailLoad = createAction(SITE_NAV_DETAIL_LOAD.START);
export const siteNavDetailLoadRequest = createAction(SITE_NAV_DETAIL_LOAD.REQUEST);
export const siteNavDetailLoadComplete = createAction(SITE_NAV_DETAIL_LOAD.COMPLETE);
export const siteNavDetailLoadError = createAction(SITE_NAV_DETAIL_LOAD.ERROR);

export const siteNavUpdate = createAction(SITE_NAV_UPDATE.START);
export const siteNavUpdateRequest = createAction(SITE_NAV_UPDATE.REQUEST);
export const siteNavUpdateComplete = createAction(SITE_NAV_UPDATE.COMPLETE);
export const siteNavUpdateError = createAction(SITE_NAV_UPDATE.ERROR);

export const siteNavDelete = createAction(SITE_NAV_DELETE.START);
export const siteNavDeleteRequest = createAction(SITE_NAV_DELETE.REQUEST);
export const siteNavDeleteComplete = createAction(SITE_NAV_DELETE.COMPLETE);
export const siteNavDeleteError = createAction(SITE_NAV_DELETE.ERROR);
